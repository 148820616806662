// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// Components
import LoginNav from "../components/Navigation/LoginNav";
import SubmitSuccess from "../components/Success/SubmitSuccess";
import LoadingMessage from "../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { FormControl, Alert, InputLabel, OutlinedInput, Button, } from '@mui/material';

// Utils
import convertToFormData from "../utils/convertToFormData";

// Assets
import loginBackground from "../assets/login-background.webp";

// This component was copy pasted from the Login component
const ForgotPassword = () => {

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});

    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset the error with a new submit
        setError("");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }

        const formData = convertToFormData(formBody);

        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formBody.email)) {
            setError("Invalid email address format.");
        }
        else {
            setStatus("sending");

            axios.post("/auth/users/reset_password/", formData, config)
                .then(res => {
                    setStatus("success");

                    setTimeout(() => {
                        navigate("/login");
                    }, 2000)
                })
                .catch(err => {
                    setStatus("idle");
                    setError("Email not found!")
                })
        }
    }

    return (
        <Main>
            <LoginNav />
            {
                status === "sending"
                    ? <LoadingMessage message={"Sending password reset request."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Request received. An email will be sent shortly."} />
                        : (
                            <FormContainer>
                                <ResetPasswordForm
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    className="mx-3 mx-md-0 my-5 p-md-5"
                                >

                                    <FormTitle>Real-time Schematics and Monitoring</FormTitle>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="username">Email</InputLabel>
                                        <OutlinedInput
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                        />
                                    </FormControl>

                                    <p><StyledLink to="/login">Cancel</StyledLink></p>

                                    {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                    <ResetPasswordButton variant="contained" type="submit">
                                        Submit
                                    </ResetPasswordButton>

                                </ResetPasswordForm>
                            </FormContainer>
                        )}
        </Main>
    )
}

const ResetPasswordButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledLink = styled(Link)`
    color: var(--macrodyne-yellow);
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const ResetPasswordForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Main = styled.main`
    padding: 0;
    background: url(${loginBackground}) no-repeat center/cover;
    max-width: 100%;
`

export default ForgotPassword;