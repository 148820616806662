// Core
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

// MUI
import { Alert, Button } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";

const Error = ({ error }) => {
    const navigate = useNavigate();

    return (
        <Center>
            <StyledAlert severity="error">
                {error}
            </StyledAlert>
            <GoBackButton
                onClick={() => navigate(-1)}
            >
                Back
            </GoBackButton>
        </Center>
    )
}

const GoBackButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledAlert = muiStyle(Alert)({
    display: "flex",
    alignItems: "center",
    margin: "25px",
    fontSize: "22px",
})

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export default Error;