// Core Imports
import styled from "styled-components";

// MUI
import { Alert } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";

// Save successful component
const SubmitSuccess = ({message, info = "You will be redirected shortly."}) => {
    return (
        <Center>
            <StyledAlert severity="success">
                {message}
            </StyledAlert>

            <StyledAlert severity="info">
                {info}
            </StyledAlert>
        </Center>
    )
}

const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledAlert = muiStyle(Alert)({
    display: "flex",
    alignItems: "center",
    margin: "25px",
    fontSize: "22px",
})

export default SubmitSuccess