// React
import { useState, useEffect } from "react";

// React Router Dom
import { useNavigate } from "react-router-dom";

// Mui
import { styled as muiStyle } from "@mui/material/styles";
import { Edit, Delete, Forward, FileCopy, } from '@mui/icons-material/';
import { IconButton, TableCell, CircularProgress, Tooltip, } from '@mui/material/';

const DataTableActions = ({
    obj,
    viewUrl,
    editUrl,
    copyUrl,
    deleteUrl,
    copyLoading,
    openCopyDialogue,
    openDeleteDialogue,
    selectedCopyObject,
}) => {

    const [loading, setLoading] = useState("idle");

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedCopyObject && selectedCopyObject.id === obj.id) {
            switch (copyLoading) {
                case "idle":
                    setLoading("idle");
                    break;
    
                case "loading":
                    setLoading("loading");
                    break;
    
                case "success":
                    setLoading("idle");
                    break;

                default:
                    setLoading("idle");
                    break;
            }
        }
    }, [copyLoading, selectedCopyObject, obj.id])

    return (
        <TableCell>
            {viewUrl && (
                <Tooltip title={`View ${obj.name || obj.item_number}`} placement="top">
                    <ActionButtons onClick={() => navigate(`${viewUrl}/${obj.id}`)}>
                        <Forward className="hover-action-button" />
                    </ActionButtons>
                </Tooltip>
            )}
            {editUrl && (
                <Tooltip title={`Update ${obj.name || obj.item_number}`} placement="top">
                    <ActionButtons onClick={() => navigate(`${editUrl}/${obj.id}/update`)}>
                        <Edit className="hover-action-button" />
                    </ActionButtons>
                </Tooltip>
            )}
            {copyUrl && (
                <Tooltip title={`Copy ${obj.name || obj.item_number}`} placement="top">
                    <span>
                        <ActionButtons onClick={() => openCopyDialogue(obj)} disabled={loading !== "idle"}>
                            {
                                loading !== "idle"
                                    ? <StyledCircularProgress
                                        size={"1.5rem"}
                                    />
                                    : <FileCopy className="hover-action-button" />
                            }
                        </ActionButtons>
                    </span>
                </Tooltip>
            )}
            {deleteUrl && (
                <Tooltip title={`Archive ${obj.name || obj.item_number}`} placement="top">
                    <ActionButtons onClick={() => openDeleteDialogue(obj)}>
                        <Delete className="hover-action-button" />
                    </ActionButtons>
                </Tooltip>
            )}
        </TableCell>
    )
}

const ActionButtons = muiStyle(IconButton)({
    borderRadius: "5px",
    ".hover-action-button": {
        color: "var(--macrodyne-yellow)",
    },
    "&:hover": {
        backgroundColor: "var(--macrodyne-dark-blue)",

        ".hover-action-button": {
            color: "white",
        }
    },
})

const StyledCircularProgress = muiStyle(CircularProgress)({
    color: "var(--macrodyne-yellow)",

})

export default DataTableActions;