// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";

// Components
import DialogPopup from "../../components/DialogPopup";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../components/DataTable/DataTableDisplay";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useVerifyUserAccess from "../../hooks/useVerifyUserAccess";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";

// MUI
import { Edit, ExpandMore } from '@mui/icons-material/';
import { styled as muiStyle } from "@mui/material/styles";
import {
    Alert,
    Tooltip,
    Button,
    IconButton,
    Accordion,
    InputLabel,
    FormControl,
    OutlinedInput,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Prime React
import { TabView, TabPanel, } from 'primereact/tabview';

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";
import showAdminFeatures from "../../utils/showAdminFeatures";

const ViewComponent = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();

    // Initialize hooks
    const navigate = useNavigate();
    const { clientId, machineId, componentId } = useParams();

    // Verify user access
    useVerifyUserAccess(clientId);

    // Used by dialogs
    const [orderVisible, setOrderVisible] = useState(false);
    const [safetyVisible, setSafetyVisible] = useState(false);
    const [dialogLoading, setDialogLoading] = useState("idle");

    const [updateComponent, setUpdateComponent] = useState(false);

    // Get names for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Get the component
    const [component, loading, error] = useGet(`/api/components/${clientId}/${machineId}/${componentId}/`, updateComponent);

    // Used for the part order
    const [formBody, setFormBody] = useState({ quantity: 1 });

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const orderPart = () => {
        setDialogLoading("loading");

        // Send update request to server
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        // Sending an empty body so the request doesn't fail
        axios.post(`/api/components/order/${componentId}/`, formData, config)
            .then(res => {
                setDialogLoading("success");

                // Hide dialog and refresh page content after a short delay
                setTimeout(() => {
                    setOrderVisible(false);
                }, 2000)

                // Revert loading status after longer delay 
                // ↪ so it doesn't show up in the dialog while it closes (looks like a bug)
                setTimeout(() => {
                    setDialogLoading("idle");
                }, 2500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? (
                            <RedirectUser error={error} />
                        )
                        : component && (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    {
                                        showAdminFeatures()
                                            ? (
                                                <BreadcrumbsNav
                                                    links={[
                                                        "dashboard",
                                                        { name: clientName, value: clientId, url: "client" },
                                                        { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                                        component.item_number
                                                    ]}
                                                />
                                            )
                                            : (
                                                <BreadcrumbsNav
                                                    links={[
                                                        { name: clientName, value: clientId, url: "client" },
                                                        { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                                        component.item_number
                                                    ]}
                                                />
                                            )
                                    }

                                    <OrderPartButton
                                        type="button"
                                        variant="contained"
                                        onClick={() => setOrderVisible(true)}
                                    >
                                        Order Part
                                    </OrderPartButton>

                                </TopContainer>

                                <Row>
                                    {/* Component information */}
                                    <Col sm={12} lg={3}>
                                        <ComponentInfo>
                                            <h2>{component.item_number}</h2>

                                            {/* Part Name */}
                                            <Info>
                                                <span>Part Name:</span>
                                                <span>{component.part_name}</span>
                                            </Info>

                                            {/* Part Number */}
                                            {
                                                showAdminFeatures() && (
                                                    <Info>
                                                        <span>Part Number:</span>
                                                        <span>{component.part_number}</span>
                                                    </Info>
                                                )
                                            }

                                            {/* Stock Number */}
                                            <Info>
                                                <span>Stock Number:</span>
                                                <span>{component.stock_number}</span>
                                            </Info>

                                            {/* Electrical Address */}
                                            <Info>
                                                <span>Electrical Address:</span>
                                                <span>{component.electrical_address}</span>
                                            </Info>

                                            {/* Component Type */}
                                            <Info>
                                                <span>Type:</span>
                                                <span>{component.type}</span>
                                            </Info>

                                            <Subtitle>Description</Subtitle>

                                            {/* Description */}
                                            <Info>
                                                <span>{component.description ? component.description : "No description provided."}</span>
                                            </Info>

                                            <Subtitle>Component Application</Subtitle>

                                            {/* Component Application */}
                                            <Info>
                                                <span>{component.application ? component.application : "No application provided."}</span>
                                            </Info>

                                            {
                                                showAdminFeatures() && (
                                                    <Tooltip title={`Update ${component.item_number}`} placement="top">
                                                        <EditButton
                                                            onClick={() => navigate(`/client/${clientId}/machine/${machineId}/component/${component.id}/update`)}
                                                        >
                                                            <Edit />
                                                        </EditButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </ComponentInfo>

                                        {/* Safety notes dialog */}
                                        <SafetyButton onClick={() => setSafetyVisible(true)}>Safety Notes</SafetyButton>
                                        <DialogPopup
                                            header="Safety Notes"
                                            visible={safetyVisible}
                                            hide={() => setSafetyVisible(false)}
                                        >
                                            {component.notes ? component.notes : "No safety notes provided."}
                                        </DialogPopup>

                                        {/* Order part dialog */}
                                        <DialogPopup
                                            header="Order Part"
                                            visible={orderVisible}
                                            hide={() => setOrderVisible(false)}
                                            loading={dialogLoading}
                                            confirm={orderPart}
                                        >
                                            {
                                                dialogLoading === "loading"
                                                    ? (
                                                        <LoadingMessage message={`Ordering ${component.part_name} replacement.`} />
                                                    )
                                                    : dialogLoading === "success"
                                                        ? (
                                                            <SubmitSuccess
                                                                info={"This window will close automatically."}
                                                                message={`A replacement for ${component.part_name} has been requested!`}
                                                            />
                                                        )
                                                        : (
                                                            <Center>
                                                                <Alert sx={{ marginBottom: "25px", width: "fit-content" }} severity="warning">This will send an email to Macrodyne to place an order for a replacement part.</Alert>
                                                                <p>How many replacement parts do you need for {component.part_name}?</p>

                                                                <StyledNumberFormControl required variant="outlined">
                                                                    <InputLabel shrink htmlFor="quantity">Quantity</InputLabel>
                                                                    <OutlinedInput
                                                                        notched
                                                                        type="number"
                                                                        id="quantity"
                                                                        name="quantity"
                                                                        label="Quantity"
                                                                        onChange={handleChange}
                                                                        inputProps={{ min: 1, value: formBody.quantity }}
                                                                    />
                                                                </StyledNumberFormControl>

                                                                Additional comments?
                                                                <StyledFormControl variant="outlined">
                                                                    <InputLabel htmlFor="comments">Comments</InputLabel>
                                                                    <OutlinedInput
                                                                        rows={3}
                                                                        multiline
                                                                        type="text"
                                                                        id="comments"
                                                                        name="comments"
                                                                        label="Comments"
                                                                        onChange={handleChange}
                                                                    />
                                                                </StyledFormControl>
                                                            </Center>
                                                        )
                                            }
                                        </DialogPopup>

                                        <AccordionContainer className="margin-y-when-small">
                                            <StyledAccordion>
                                                <AccordionSummary expandIcon={<ExpandMore />}>
                                                    <AccordionTitle>Attachments</AccordionTitle>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        component.extra
                                                            ? (
                                                                <a href={component.extra} download={component.extra} target="_blank" rel="noreferrer">
                                                                    {component.extra.split("/")[component.extra.split("/").length - 1].split(".")[0]}
                                                                </a>
                                                            )
                                                            : (
                                                                <p>This component has no extra attachments.</p>
                                                            )
                                                    }
                                                </AccordionDetails>
                                            </StyledAccordion>
                                        </AccordionContainer>
                                    </Col>

                                    {/* Image / Video / Troubleshooting */}
                                    <Col sm={12} lg={9}>
                                        <TabView>

                                            <TabPanel header="Image">
                                                <ComponentImage alt="Component image" src={component.image} />
                                            </TabPanel>

                                            <TabPanel header="Video" disabled={!component.video}>
                                                <video controls width="100%" height="100%" >
                                                    <source src={component.video} type="video/mp4" />
                                                </video>
                                            </TabPanel>

                                            <TabPanel header="Troubleshooting">
                                                {
                                                    component.troubleshooting.length
                                                        ? (
                                                            <DataTableDisplay
                                                                data={component.troubleshooting}
                                                                viewUrl={`/client/${clientId}/machine/${machineId}/troubleshooting`}
                                                                editUrl={
                                                                    showAdminFeatures()
                                                                        ? `/client/${clientId}/machine/${machineId}/troubleshooting`
                                                                        : null
                                                                }
                                                                deleteUrl={
                                                                    showAdminFeatures()
                                                                        ? "/api/schematics/archive"
                                                                        : null
                                                                }
                                                                search
                                                                striped
                                                                refreshData={updateComponent}
                                                                setRefreshData={setUpdateComponent}
                                                            />
                                                        )
                                                        : (
                                                            <Alert severity="info">This component is not linked to any troubleshooting operations yet.</Alert>
                                                        )
                                                }
                                            </TabPanel>
                                        </TabView>
                                    </Col>
                                </Row>
                            </>
                        )
            }
        </main>
    )
}

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const AccordionTitle = styled.h3`
    margin-bottom: 0px;
`

const Subtitle = styled.h3`
    margin-top: 25px;
`

const ComponentImage = styled.img`
    width: 100%;
    margin-top: -11px;
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
`

// Template
const Container = styled.div`
    background-color: var(--macrodyne-light-grey);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
`

// Specific names
const ComponentInfo = styled(Container)``;
const AccordionContainer = styled(Container)`
    padding: 10px 25px;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const StyledAccordion = muiStyle(Accordion)({
    backgroundColor: "var(--macrodyne-light-grey)",
    boxShadow: "none",
    borderRadius: "5px",

    "div, p": {
        margin: "0",
        padding: "0",
    }
})

const EditButton = muiStyle(IconButton)({
    position: "absolute",
    top: "25px",
    right: "25px",
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Template
const StyledButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Specific names
const OrderPartButton = muiStyle(StyledButton)({})
const SafetyButton = muiStyle(StyledButton)({
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
})

const StyledNumberFormControl = muiStyle(FormControl)({
    backgroundColor: "var(--macrodyne-light-grey)",
    marginBottom: "25px",
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "25px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "75%",
})

export default ViewComponent