// Core Imports
import { useContext } from "react";
import GlobalStyles from "./styles/GlobalStyles";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Footer from "./components/Footer";
import Nav from "./components/Navigation/Nav";

// Library CSS
import "./styles/primereact-theme.css"; // Custom CSS theme for Prime React
import "primereact/resources/primereact.min.css"; // Base CSS theme for Prime React
import 'bootstrap/dist/css/bootstrap.min.css'; // Base CSS theme for React Bootstrap

// Context
import { UserContext } from "./context/UserContext";

// General Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import ExpiredUser from "./pages/ExpiredUser";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import RedirectUser from "./components/Errors/RedirectUser";

// Client Pages
import AddClient from "./pages/Client/AddClient";
import ViewClient from "./pages/Client/ViewClient";
import UpdateClient from "./pages/Client/UpdateClient";

// Machine Pages
import AddMachine from "./pages/Machine/AddMachine";
import ViewMachine from "./pages/Machine/ViewMachine";
import UpdateMachine from "./pages/Machine/UpdateMachine";

// Schematic Pages
import AddSchematic from "./pages/Schematic/AddSchematic";
import ViewSchematic from "./pages/Schematic/ViewSchematic";
import UpdateSchematic from "./pages/Schematic/UpdateSchematic";
import AnnotateSchematic from "./pages/Schematic/AnnotateSchematic";

// Component Pages
import AddComponent from "./pages/Component/AddComponent";
import ViewComponent from "./pages/Component/ViewComponent";
import UpdateComponent from "./pages/Component/UpdateComponent";

// Troubleshooting Pages
import AddTroubleshooting from "./pages/Troubleshooting/AddTroubleshooting";
import ViewTroubleshooting from "./pages/Troubleshooting/ViewTroubleshooting";
import UpdateTroubleshooting from "./pages/Troubleshooting/UpdateTroubleshooting";
import AnnotateTroubleshooting from "./pages/Troubleshooting/AnnotateTroubleshooting";

// Regular User Pages
import AddUser from "./pages/User/Regular/AddUser";
import ListUsers from "./pages/User/Regular/ListUsers";
import UpdateUser from "./pages/User/Regular/UpdateUser";

// Admin User Pages
import AdminAddUser from "./pages/User/Admin/AdminAddUser";
import AdminListUsers from "./pages/User/Admin/AdminListUsers";
import AdminUpdateUser from "./pages/User/Admin/AdminUpdateUser";

const App = () => {

    const { expiredUserCookie, redirectUser, user } = useContext(UserContext);

    return (
        <>
            <GlobalStyles user={user} />
            <Router>
                {/* 
                    Only displays the navbar if the user is logged in.
                    Login gets it's own navbar.
                */}
                {user && <Nav />}
                <Routes>
                    {
                        redirectUser
                            ? <Route path="*" element={<RedirectUser />} />
                            : expiredUserCookie
                                ? (
                                    <>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="*" element={<ExpiredUser />} />
                                    </>
                                )
                                : (
                                    <>
                                        {/* General Routes */}
                                        <Route path="/" element={<Home />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/profile" element={<Profile />} />
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                        <Route path="/password/reset/:uid/:token" element={<ResetPassword />} />

                                        {/* Client Routes */}
                                        <Route path="/client/add" element={<AddClient />} />
                                        <Route path="/client/:clientId" element={<ViewClient />} />
                                        <Route path="/client/:clientId/update" element={<UpdateClient />} />

                                        {/* Machine Routes */}
                                        <Route path="/client/:clientId/machine/add" element={<AddMachine />} />
                                        <Route path="/client/:clientId/machine/:machineId" element={<ViewMachine />} />
                                        <Route path="/client/:clientId/machine/:machineId/update" element={<UpdateMachine />} />

                                        {/* Schematic Routes */}
                                        <Route path="/client/:clientId/machine/:machineId/schematic/add" element={<AddSchematic />} />
                                        <Route path="/client/:clientId/machine/:machineId/schematic/:schematicId" element={<ViewSchematic />} />
                                        <Route path="/client/:clientId/machine/:machineId/schematic/:schematicId/update" element={<UpdateSchematic />} />
                                        <Route path="/client/:clientId/machine/:machineId/schematic/:schematicId/annotate" element={<AnnotateSchematic />} />

                                        {/* Component Routes */}
                                        <Route path="/client/:clientId/machine/:machineId/component/add" element={<AddComponent />} />
                                        <Route path="/client/:clientId/machine/:machineId/component/:componentId" element={<ViewComponent />} />
                                        <Route path="/client/:clientId/machine/:machineId/component/:componentId/update" element={<UpdateComponent />} />

                                        {/* Troubleshooting Routes */}
                                        <Route path="/client/:clientId/machine/:machineId/troubleshooting/add" element={<AddTroubleshooting />} />
                                        <Route path="/client/:clientId/machine/:machineId/troubleshooting/:schematicId" element={<ViewTroubleshooting />} />
                                        <Route path="/client/:clientId/machine/:machineId/troubleshooting/:schematicId/update" element={<UpdateTroubleshooting />} />
                                        <Route path="/client/:clientId/machine/:machineId/troubleshooting/:schematicId/annotate" element={<AnnotateTroubleshooting />} />

                                        {/* Regular User Routes */}
                                        <Route path="/client/:clientId/users" element={<ListUsers />} />
                                        <Route path="/client/:clientId/users/add" element={<AddUser />} />
                                        <Route path="/client/:clientId/users/:userId/update" element={<UpdateUser />} />

                                        {/* Admin User Routes */}
                                        <Route path="/users" element={<AdminListUsers />} />
                                        <Route path="/users/add" element={<AdminAddUser />} />
                                        <Route path="/users/:userId/update" element={<AdminUpdateUser />} />

                                        {/* 404 Route */}
                                        <Route path="*" element={<NotFound />} />
                                    </>
                                )
                    }
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;