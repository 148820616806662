// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// Components
import LoginNav from "../components/Navigation/LoginNav";
import SubmitSuccess from "../components/Success/SubmitSuccess";
import LoadingMessage from "../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, Alert, InputLabel, OutlinedInput, Button, InputAdornment, IconButton, } from '@mui/material';

// Utils
import convertToFormData from "../utils/convertToFormData";

// Assets
import loginBackground from "../assets/login-background.webp";

// This component was copy pasted from the Login component
const ResetPassword = () => {

    // Get URL Params
    const { uid, token } = useParams();

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({ uid, token });

    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset the error with a new submit
        setError("");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }

        const formData = convertToFormData(formBody);
        
        if (formBody.password === formBody.confirm_password) {
            setStatus("sending");

            axios.post("/auth/users/reset_password_confirm/", formData, config)
                .then(res => {
                    setStatus("success");

                    setTimeout(() => {
                        navigate("/login");
                    }, 2000)
                })
                .catch(err => {
                    setStatus("idle");
                    setError(err.response.data.new_password[0])
                })
        }
        else {
            setError("Passwords do not match!");
        }
    }

    return (
        <Main>
            <LoginNav />
            {
                status === "sending"
                    ? <LoadingMessage message={"Sending password reset request."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Request received. A confirmation email will be sent shortly."} />
                        : (
                            <FormContainer>
                                <ResetPasswordForm
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    className="mx-3 mx-md-0 my-5 p-md-5"
                                >

                                    <FormTitle>Real-time Schematics and Monitoring</FormTitle>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="new_password">New Password</InputLabel>
                                        <OutlinedInput
                                            id="new_password"
                                            name="new_password"
                                            label="New Password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="re_new_password">Confirm New Password</InputLabel>
                                        <OutlinedInput
                                            id="re_new_password"
                                            name="re_new_password"
                                            label="Confirm New Password"
                                            type={showRePassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowRePassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showRePassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                    <ResetPasswordButton variant="contained" type="submit">
                                        Reset Password
                                    </ResetPasswordButton>

                                </ResetPasswordForm>
                            </FormContainer>
                        )}
        </Main>
    )
}

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const ResetPasswordForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Main = styled.main`
    padding: 0;
    background: url(${loginBackground}) no-repeat center/cover;
    max-width: 100%;
`

const ResetPasswordButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default ResetPassword;