// Core Imports
import styled from "styled-components";

// Bootstrap
import ProgressBar from 'react-bootstrap/ProgressBar';

// Loading component
const PdfLoadingBar = ({ currentPage, numPages }) => {
    return (
        <Center>
            <PreviewTitle>Preparing PDF Preview</PreviewTitle>
            {numPages ? <p>Converting page {currentPage} of {numPages}</p> : <p>Loading pages...</p>}
            <StyledProgress
                now={
                    currentPage
                        ? Math.round(currentPage / numPages * 100)
                        : 0
                }
                label={
                    currentPage
                        ? `${Math.round(currentPage / numPages * 100)}%`
                        : 0
                }
            />
        </Center>
    )
}

const StyledProgress = styled(ProgressBar)`
    width: 100%;
    margin-top: 25px;
`

const PreviewTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export default PdfLoadingBar