// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Components
import DialogPopup from "../../components/DialogPopup";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../components/DataTable/DataTableDisplay";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useVerifyUserAccess from "../../hooks/useVerifyUserAccess";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";

// MUI
import { Edit } from '@mui/icons-material/';
import { styled as muiStyle } from "@mui/material/styles";
import { Alert, Tooltip, Button, IconButton, Switch } from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Prime React
import { TabView, TabPanel } from 'primereact/tabview';

// Utils
import getUserToken from "../../utils/getUserToken";
import showAdminFeatures from "../../utils/showAdminFeatures";

const ViewMachine = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();

    // Initialize hooks
    const navigate = useNavigate();
    const { clientId, machineId } = useParams();

    // Verify user access
    useVerifyUserAccess(clientId);

    // States for the tab
    // ↪ Checks for the last tab selected, if none found defaults to Schematics
    const [activeIndex, setActiveIndex] = useState(() => {
        const lastTabIndex = localStorage.getItem("lastTabIndex");

        return lastTabIndex ? JSON.parse(lastTabIndex) : 0;
    });
    const [selectedTab, setSelectedTab] = useState(() => {
        const lastTabIndex = localStorage.getItem("lastTabIndex");

        if (lastTabIndex) {
            switch (JSON.parse(lastTabIndex)) {
                case 0:
                    return "Schematic";
    
                case 1:
                    return "Component";
    
                case 2:
                    return "Troubleshooting";
    
                default:
                    return "Schematic";
            }
        }
        else {
            return "Schematic";
        }
    });

    // States for the switch
    const [checked, setChecked] = useState(false);
    const [updateMachine, setUpdateMachine] = useState(false);

    // States for the dialog
    const [visible, setVisible] = useState(false);
    const [dialogLoading, setDialogLoading] = useState("idle");

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);

    // Get the machine
    const [machine, loading, error] = useGet(`/api/machines/${clientId}/${machineId}/`, updateMachine);

    useEffect(() => {
        if (machine) {
            setChecked(machine.status);
        }
    }, [machine])

    // Updates the machine's status
    const updateMachineStatus = () => {
        setDialogLoading("loading");

        // Send update request to server
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        // Sending an empty body so the request doesn't fail
        axios.patch(`/api/machines/update/status/${machineId}/`, {}, config)
            .then(res => {
                setDialogLoading("success");

                // Hide dialog and refresh page content after a short delay
                setTimeout(() => {
                    setVisible(false);
                    setUpdateMachine(!updateMachine);
                }, 2000)

                // Revert loading status after longer delay 
                // ↪ so it doesn't show up in the dialog while it closes (looks like a bug)
                setTimeout(() => {
                    setDialogLoading("idle");
                }, 2500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    // Occurs when a tab changes
    // ↪ Dynamically sets the button
    const tabChangeHandler = (event) => {

        setActiveIndex(event.index);

        localStorage.setItem("lastTabIndex", JSON.stringify(event.index));

        switch (event.index) {
            case 0:
                setSelectedTab("Schematic");
                break;

            case 1:
                setSelectedTab("Component");
                break;

            case 2:
                setSelectedTab("Troubleshooting");
                break;

            default:
                break;
        }
    }

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? (
                            <RedirectUser error={error} />
                        )
                        : machine && (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    {
                                        showAdminFeatures()
                                            ? (
                                                <BreadcrumbsNav
                                                    links={[
                                                        "dashboard",
                                                        {
                                                            name: clientName,
                                                            value: clientId,
                                                            url: "client"
                                                        },
                                                        machine.name,
                                                    ]}
                                                />
                                            )
                                            : (
                                                <BreadcrumbsNav
                                                    links={[
                                                        {
                                                            name: clientName,
                                                            value: clientId,
                                                            url: "client"
                                                        },
                                                        machine.name,
                                                    ]}
                                                />
                                            )
                                    }
                                    {
                                        showAdminFeatures() && (
                                            <AddSchematicButton
                                                variant="contained"
                                                type="button"
                                                onClick={() => navigate(`/client/${clientId}/machine/${machineId}/${selectedTab.toLowerCase()}/add`)}
                                            >
                                                Add {selectedTab}
                                            </AddSchematicButton>
                                        )
                                    }
                                </TopContainer>
                                <Row>
                                    {/* Machine information */}
                                    <Col sm={12} lg={3}>
                                        <MachineInfo className="margin-y-when-small">
                                            <h2>{machine.name}</h2>

                                            <Info>
                                                <span>Machine Number:</span>
                                                <span>{machine.number}</span>
                                            </Info>

                                            <Info>
                                                <span>Installed On:</span>
                                                <span>{machine.installed_on}</span>
                                            </Info>

                                            <Subtitle>Description</Subtitle>

                                            <Info>
                                                <span>{machine.description ? machine.description : "No description provided."}</span>
                                            </Info>

                                            <Subtitle>Status</Subtitle>
                                            <Info>
                                                <Tooltip title={`Turn ${machine.name} ${machine.status ? "off" : "on"}`} placement="top">
                                                    <Switch
                                                        checked={checked}
                                                        onClick={() => setVisible(true)}
                                                    />
                                                </Tooltip>
                                            </Info>

                                            <DialogPopup
                                                visible={visible}
                                                hide={() => setVisible(false)}
                                                header={"Update Machine Status"}
                                                loading={dialogLoading}
                                                confirm={updateMachineStatus}
                                            >
                                                {
                                                    dialogLoading === "loading"
                                                        ? (
                                                            <LoadingMessage message={`Updating ${machine.name}'s status.`} />
                                                        )
                                                        : dialogLoading === "success"
                                                            ? (
                                                                <SubmitSuccess
                                                                    info={"This window will close automatically."}
                                                                    message={`${machine.name}'s status has been updated!`}
                                                                />
                                                            )
                                                            : (
                                                                <Center>
                                                                    <Alert sx={{ width: "fit-content", marginBottom: "25px" }} severity="warning">The machine's updated status will be recorded.</Alert>
                                                                    <p>Turn {machine.name} {machine.status ? "off" : "on"}?</p>
                                                                </Center>
                                                            )
                                                }
                                            </DialogPopup>

                                            {
                                                showAdminFeatures() && (
                                                    <Tooltip title={`Update ${machine.name}`} placement="top">
                                                        <EditButton onClick={() => navigate(`/client/${clientId}/machine/${machineId}/update`)}>
                                                            <Edit />
                                                        </EditButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </MachineInfo>

                                        {/* Machine picture */}
                                        <MachinePicture className="margin-y-when-small" src={machine.image} alt="Machine Picture" />
                                    </Col>

                                    {/* Schematics / Components / Troubleshooting */}
                                    <Col sm={12} lg={9}>
                                        <TabView activeIndex={activeIndex} onTabChange={tabChangeHandler}
                                        >

                                            {/* Schematics Panel */}
                                            <TabPanel header="Schematics">
                                                {
                                                    machine.schematics.length
                                                        ? (
                                                            <DataTableDisplay
                                                                data={machine.schematics}
                                                                viewUrl={`/client/${clientId}/machine/${machineId}/schematic`}
                                                                editUrl={
                                                                    showAdminFeatures()
                                                                        ? `/client/${clientId}/machine/${machineId}/schematic` :
                                                                        null
                                                                }
                                                                deleteUrl={
                                                                    showAdminFeatures()
                                                                        ? "/api/schematics/archive"
                                                                        : null
                                                                }
                                                                search
                                                                striped
                                                                refreshData={updateMachine}
                                                                setRefreshData={setUpdateMachine}
                                                            />
                                                        )
                                                        : (
                                                            <Alert severity="info">This machine has no schematics yet.</Alert>
                                                        )
                                                }
                                            </TabPanel>

                                            {/* Components Panel */}
                                            <TabPanel header="Components">
                                                {
                                                    machine.components.length
                                                        ? (
                                                            <DataTableDisplay
                                                                data={machine.components}
                                                                viewUrl={`/client/${clientId}/machine/${machineId}/component`}
                                                                editUrl={
                                                                    showAdminFeatures()
                                                                        ? `/client/${clientId}/machine/${machineId}/component`
                                                                        : null
                                                                }
                                                                deleteUrl={
                                                                    showAdminFeatures()
                                                                        ? "/api/components/archive"
                                                                        : null
                                                                }
                                                                search
                                                                striped
                                                                memorize
                                                                refreshData={updateMachine}
                                                                setRefreshData={setUpdateMachine}
                                                                removeKeys={["id", "image", "electrical_address"]}
                                                            />
                                                        )
                                                        : (
                                                            <Alert severity="info">This machine has no components yet.</Alert>
                                                        )
                                                }
                                            </TabPanel>

                                            {/* Troubleshooting Panel */}
                                            <TabPanel header="Troubleshooting">
                                                {
                                                    machine.troubleshootings.length
                                                        ? (
                                                            <DataTableDisplay
                                                                data={machine.troubleshootings}
                                                                viewUrl={`/client/${clientId}/machine/${machineId}/troubleshooting`}
                                                                editUrl={
                                                                    showAdminFeatures()
                                                                        ? `/client/${clientId}/machine/${machineId}/troubleshooting`
                                                                        : null
                                                                }
                                                                deleteUrl={
                                                                    showAdminFeatures()
                                                                        ? "/api/schematics/archive"
                                                                        : null
                                                                }
                                                                search
                                                                striped
                                                                refreshData={updateMachine}
                                                                setRefreshData={setUpdateMachine}
                                                            />
                                                        )
                                                        : (
                                                            <Alert severity="info">This machine has no troubleshooting operations yet.</Alert>
                                                        )
                                                }
                                            </TabPanel>

                                        </TabView>
                                    </Col>
                                </Row>
                            </>
                        )
            }
        </main>
    )
}

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const MachinePicture = styled.img`
    border-radius: 10px;
    width: 100%;
    height: 450px;
    margin-top: 25px;
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
`

const Subtitle = styled.h3`
    margin-top: 25px;
`

const MachineInfo = styled.div`
    background-color: var(--macrodyne-light-grey);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const EditButton = muiStyle(IconButton)({
    position: "absolute",
    top: "25px",
    right: "25px",
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Template
const AddSchematicButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default ViewMachine;