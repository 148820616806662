// Core Imports
import styled from "styled-components";
import { Link } from "react-router-dom";

// Material UI
import { Breadcrumbs } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { styled as muiStyle } from "@mui/material/styles";

// Utils
import generateRandomKey from "../utils/generateRandomKey";

// Custom Breadcrumb naviation component (uses MUI and dynamically maps the breadcrumbs)
// The home breadcrumb is always included by default
const BreadcrumbsNav = ({ links }) => {

    return (
        <Section>
            <Breadcrumbs>
                <BreadcrumbLink
                    to="/"
                    // Disables pointer events if no links are provided
                    style={{ pointerEvents: !links ? "none" : "" }}
                >
                    {/* Styles the icon if no other links were provided */}
                    <StyledHomeIcon className={links ? "" : "last"} />
                </BreadcrumbLink>
                {
                    links && links.map((link, index) => {
                        return (
                            link.url
                                ? (
                                    <BreadcrumbLink
                                        key={generateRandomKey()}
                                        to={`/${link.url}/${link.value}`}
                                        // Disables pointer events if it's the last link in the breadcrumb
                                        style={{ pointerEvents: index === links.length - 1 ? "none" : "" }}
                                    >
                                        {/* The last class styles the last link in the breadcrumb */}
                                        <span className={index === links.length - 1 ? "last" : ""}>
                                            {link.name && link.name.substring(0, 1).toUpperCase()}
                                            {link.name && link.name.substring(1)}
                                        </span>
                                    </BreadcrumbLink>
                                )
                                : (
                                    <BreadcrumbLink
                                        key={generateRandomKey()}
                                        to={`/${link}`}
                                        // Disables pointer events if it's the last link in the breadcrumb
                                        style={{ pointerEvents: index === links.length - 1 ? "none" : "" }}
                                    >
                                        {/* The last class styles the last link in the breadcrumb */}
                                        <span className={index === links.length - 1 ? "last" : ""}>
                                            {link.substring(0, 1).toUpperCase()}
                                            {link.substring(1)}
                                        </span>
                                    </BreadcrumbLink>
                                )
                        )
                    })
                }
            </Breadcrumbs>
        </Section>
    )
}

const StyledHomeIcon = muiStyle(HomeIcon)({
    color: "var(--macrodyne-dark-blue)",
    marginBottom: "0.3rem",
})

const BreadcrumbLink = styled(Link)`
    text-decoration: none;
    color: var(--macrodyne-dark-blue);

    .last {
        color: var(--macrodyne-light-blue);
    }
`

const Section = styled.section`
    margin-bottom: 0.5em;
`

export default BreadcrumbsNav;