// Core Imports
import styled from "styled-components";

// Assets
import simwellLogo from "../assets/simwell-logo.png";

const Footer = () => {
    return (
        <FooterContainer className="px-4">
            <span>Copyright &copy; 2023 Macrodyne Technologies Inc.</span>
            <div>
                {/* <a> tag intentionally kept on the same line so the space between "by" and the image appears on the page */}
                Powered by <a target="_blank" href="https://www.simwell.io/" >
                    <SimwellLogo src={simwellLogo} alt="Simwell Logo" />
                </a>
            </div>
        </FooterContainer>
    )
}

const SimwellLogo = styled.img`
    display: inline;
    width: 75px;
`

const FooterContainer = styled.footer`
    display: flex;
    justify-content: space-between;
    color: var(--macrodyne-grey);
    font-weight: 500;
    background-color: var(--macrodyne-light-grey);
    padding: 25px;
`

export default Footer;