// React
import { useState } from "react";

// React Router Dom
import { useParams } from "react-router-dom";

// Styled Components
import styled from "styled-components";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import {
    Alert,
    Select,
    Checkbox,
    MenuItem,
    FormGroup,
    InputLabel,
    FormControl,
    OutlinedInput,
    FormControlLabel,
} from '@mui/material';

// Joy-UI
import { Tooltip } from "@mui/joy";

// Components
import DialogPopup from "../../DialogPopup";
import SubmitSuccess from "../../Success/SubmitSuccess";
import LoadingMessage from "../../Loading/LoadingMessage";

// Custom Hooks
import useGet from "../../../hooks/useGet";

// Utils
import generateRandomKey from "../../../utils/generateRandomKey";

const CopyDialogue = ({ visible, loading, confirm, hide, header, selectedObject }) => {

    // Get a list of all companies
    const [clients] = useGet("/api/clients/regular/");

    const { clientId } = useParams();

    // Stores the user's selection
    const [formBody, setFormBody] = useState({
        schematics: true,
        components: true,
        annotations: true,
        troubleshooting: true,
        newClient: Number(clientId),
        newName: `${selectedObject.name} (Copy)`,
    });

    // Handles the changes
    const handleChange = (key, value) => {

        // Cannot copy annotations without components
        if (key === "components" && value === false) {
            setFormBody({
                ...formBody,
                [key]: value,
                annotations: false,
            })
        }
        // Cannot copy annotations without schematics / troubleshooting
        else if (
            (key === "schematics" || key === "troubleshooting") &&
            (!formBody.schematics || !formBody.troubleshooting) &&
            value === false
        ) {
            setFormBody({
                ...formBody,
                [key]: value,
                annotations: false,
            })
        }
        else {
            setFormBody({
                ...formBody,
                [key]: value,
            })
        }
    }

    const confirmHandler = () => {
        confirm(formBody);
    }

    return (
        <DialogPopup
            hide={hide}
            header={header}
            visible={visible}
            loading={loading}
            confirm={confirmHandler}
        >
            {
                loading === "loading"
                    ? (
                        <LoadingMessage message={`Copying ${selectedObject.name || selectedObject.item_number}.`} />
                    )
                    : loading === "success"
                        ? (
                            <SubmitSuccess
                                info={"This window will close automatically."}
                                message={`${selectedObject.name || selectedObject.item_number} has been copied.`}
                            />
                        )
                        : (
                            <Center>

                                {/* New Client */}
                                <Alert sx={{ marginBottom: "25px", width: "fit-content" }} severity="info">
                                    Into which client do you want to copy {selectedObject.name || selectedObject.item_number}?
                                </Alert>

                                <StyledFormControl required variant="outlined">
                                    <InputLabel htmlFor="newClient">New Client</InputLabel>
                                    <Select
                                        id="newClient"
                                        name="newClient"
                                        label="New Client"
                                        value={formBody.newClient}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    >
                                        {
                                            clients && clients.map(client => {
                                                return (
                                                    <MenuItem key={generateRandomKey()} value={client.id}>{client.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </StyledFormControl>

                                {/* New Name */}
                                <Alert sx={{ marginBottom: "25px", width: "fit-content" }} severity="info">
                                    What would you like to name the new copy of {selectedObject.name || selectedObject.item_number}?
                                </Alert>

                                <StyledFormControl required variant="outlined">
                                    <InputLabel htmlFor="newName">New Machine Name</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        id="newName"
                                        name="newName"
                                        label="New Machine Name"
                                        value={formBody.newName}
                                        onChange={(event) => handleChange(event.target.id, event.target.value)}
                                    />
                                </StyledFormControl>

                                {/* Checkboxes */}
                                <Alert sx={{ marginBottom: "25px", width: "fit-content" }} severity="info">
                                    What would you like to copy?
                                </Alert>

                                <StyledFormGroup>

                                    {/* Troubleshooting */}
                                    <FormControlLabel
                                        label="Troubleshooting"
                                        onChange={(event) => handleChange(event.target.id, event.target.checked)}
                                        control={(
                                            <Checkbox
                                                id="troubleshooting"
                                                checked={formBody.troubleshooting}
                                            />
                                        )}
                                    />

                                    {/* Schematics */}
                                    <FormControlLabel
                                        label="Schematics"
                                        onChange={(event) => handleChange(event.target.id, event.target.checked)}
                                        control={(
                                            <Checkbox
                                                id="schematics"
                                                checked={formBody.schematics}
                                            />
                                        )}
                                    />

                                    {/* Components */}
                                    <FormControlLabel
                                        label="Components"
                                        onChange={(event) => handleChange(event.target.id, event.target.checked)}
                                        control={(
                                            <Checkbox
                                                id="components"
                                                checked={formBody.components}
                                            />
                                        )}
                                    />

                                    {/* Annotations */}
                                    {/* ↪ with Joy-UI tooltip */}
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        color="warning"
                                        variant="soft"
                                        title={
                                            !formBody.components
                                                ? !formBody.schematics && !formBody.troubleshooting
                                                    ? "⚠️ Annotations cannot be copied if there are no components, schematics or troubleshooting operations."
                                                    : "⚠️ Annotations cannot be copied if there are no components."
                                                : "⚠️ Annotations cannot be copied if there are no schematics or troubleshooting operations."
                                        }
                                    >
                                        <FormControlLabel
                                            label="Annotations"
                                            onChange={(event) => handleChange(event.target.id, event.target.checked)}
                                            control={(
                                                <Checkbox
                                                    id="annotations"
                                                    checked={formBody.annotations}
                                                    disabled={!formBody.components || !formBody.schematics && !formBody.troubleshooting}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </StyledFormGroup>
                            </Center>
                        )
            }
        </DialogPopup>
    )
}

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const StyledFormGroup = muiStyle(FormGroup)({
    flexDirection: "row",
})

const StyledFormControl = muiStyle(FormControl)({
    width: "50%",
    marginBottom: "25px",
    backgroundColor: "var(--macrodyne-light-grey)",
})

export default CopyDialogue