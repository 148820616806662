// Core Imports
import styled from "styled-components";

// Assets
import logo from "../../assets/logo-final.png";
import whiteNav from "../../assets/white-nav.webp";

// Navbar but not really a navbar. Just the logo showing up in the login page.
const LoginNav = () => {
    return (
        <div>
            <img src={whiteNav} alt="Logo background" />
            <Logo src={logo} alt="Macrodyne Logo" />
        </div>
    )
}

const Logo = styled.img`
    width: 85%;
    max-width: 200px;
    height: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 2rem 1.5rem;
`

export default LoginNav;