// Core Imports
import { useContext, useEffect } from "react"

// Cookie Handling
import Cookies from "universal-cookie";

// Context
import { UserContext } from "../context/UserContext"

// Custom hook that validates the loggedin cookie.
const useValidateUserCookie = () => {

    // Declare cookie instance
    const cookies = new Cookies();

    const { user, setUser, setExpiredUserCookie, COOKIE_EXPIRATION } = useContext(UserContext);

    useEffect(() => {
        // Function to validate the cookie for every render:
        // If the cookie is still valid, refreshes it's duration.
        // If the cookie is expired, requires the user to login again.
        const storedCookie = cookies.get("user");

        // Mark the cookie as expired
        if (!storedCookie && user) {
            setExpiredUserCookie(true);
            setUser(null);
        }
        
        // Refresh the cookie
        else if (storedCookie && user) {
            cookies.set("user", storedCookie, {
                path: "/",
                maxAge: COOKIE_EXPIRATION,
            });
            setExpiredUserCookie(false);
        }
    })
}

export default useValidateUserCookie;