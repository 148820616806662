// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";

// Components
import DataTableActions from "./DataTableActions";
import CopyDialogue from "./Dialogues/CopyDialogue";
import ArchiveDialogue from "./Dialogues/ArchiveDialogue";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import {
    Search,
    Cancel,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from '@mui/icons-material/';
import {
    // Comopnent imports
    Select,
    Button,
    MenuItem,
    InputLabel,
    IconButton,
    FormControl,
    OutlinedInput,
    InputAdornment,

    // Table imports
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TableSortLabel,
    TableContainer,
} from '@mui/material/';

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";
import generateRandomKey from "../../utils/generateRandomKey";

const DataTableDisplay = ({
    // Settings
    data,
    search,
    striped,
    memorize,

    // Urls
    viewUrl,
    editUrl,
    copyUrl,
    deleteUrl,

    // Refresh
    refreshData,
    setRefreshData,

    // Default values
    rows = 10,
    removeKeys = ["id"],
    rowsPerPage = [10, 25, 100],
}) => {

    // The columns to display in the table, minus the ID column
    const [columns] = useState(() => {

        const obj = { ...data[0] };

        removeKeys.forEach((key) => {
            delete obj[key]
        })
        const columnNames = Object.keys(obj);

        if (viewUrl || editUrl || deleteUrl) {
            return [...columnNames, "actions"];
        }

        return columnNames;
    });

    // The user's search input
    const [userInput, setUserInput] = useState("");

    // The filtered data based on the user's search input
    const [filteredData, setFilteredData] = useState(data);

    // Used for displaying the data in the table and pagination
    const [numOfRows, setNumOfRows] = useState(rows);
    const [page, setPage] = useState(() => {
        if (memorize) {
            const storedPage = sessionStorage.getItem("page");

            // Check if the page exists
            // ↪ Also if it's within range for the data
            // ↪ If not, return 0 as default
            return storedPage
                ? JSON.parse(storedPage) > Math.ceil(filteredData.length / numOfRows) - 1
                    ? 0
                    : JSON.parse(storedPage)
                : 0
        }
        else {
            return 0;
        }
    });
    const [displayData, setDisplayData] = useState(() => {
        if (memorize) {
            const offset = (page * numOfRows) % filteredData.length;
            return filteredData.slice(offset, offset + numOfRows);
        }
        else {
            return filteredData.slice(0, numOfRows);
        }
    });

    // *************** //
    // Displaying Data //
    // *************** //

    // Name will be the default column
    const [order, setOrder] = useState(true);
    const [selectedColumn, setSelectedColumn] = useState("");

    // Sorts the displayed data
    const sortData = (order, column) => {
        // Ascending order
        if (order) {
            displayData.sort((a, b) => {
                if (typeof a[column] === "string" || typeof a[column] === "number") {
                    if (a[column].toLowerCase() < b[column].toLowerCase()) {
                        return -1;
                    }
                    if (a[column].toLowerCase() > b[column].toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                else if (typeof a[column] === "boolean") {
                    if (a[column] > b[column]) {
                        return -1;
                    }
                    if (a[column] < b[column]) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            })
        }
        // Descending order
        else {
            displayData.sort((a, b) => {
                if (typeof a[column] === "string" || typeof a[column] === "number") {
                    if (a[column].toLowerCase() > b[column].toLowerCase()) {
                        return -1;
                    }
                    if (a[column].toLowerCase() < b[column].toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                else if (typeof a[column] === "boolean") {
                    if (a[column] < b[column]) {
                        return -1;
                    }
                    if (a[column] > b[column]) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            })
        }
    }

    // Sorts the data
    const handleSort = (column) => {
        if (column === selectedColumn) {
            // Set the order
            setOrder(!order);

            // Sort the data
            sortData(!order, column);
        }
        else {
            // Set the order
            setOrder(true);

            // Set the selected column
            setSelectedColumn(column);

            // Sort the data
            sortData(true, column);
        }
    }

    // Changes the number of rows displayed in the table
    const rowsPerPageChange = (event) => {
        setPage(0);
        setNumOfRows(event.target.value);
        setDisplayData(filteredData.slice(0, event.target.value));
    }

    // Changes the page displayed
    const navigationHandler = (action, pageNumber) => {

        // inspired by https://www.npmjs.com/package/react-paginate
        let newPage;

        switch (action) {
            case "double-left":
                newPage = 0;
                break;

            case "left":
                newPage = page - 1;
                break;

            case "right":
                newPage = page + 1;
                break;

            case "double-right":
                newPage = Math.ceil(filteredData.length / numOfRows) - 1;
                break;

            case "page":
                newPage = pageNumber;
                break;

            default:
                break;
        }

        setPage(newPage);
        const offset = (newPage * numOfRows) % filteredData.length;
        setDisplayData(filteredData.slice(offset, offset + numOfRows));

        if (memorize) {
            sessionStorage.setItem("page", newPage);
        }
    }

    // Filters the data shown in the table based on user's input
    const filterTable = (event) => {
        const newData = data.filter((obj) => {

            const copyObj = { ...obj };

            delete copyObj.id;
            delete copyObj.image;

            // Allows for boolean filtering... kinda
            if (event.target.value.toLowerCase() === "on") {
                return Object.values(copyObj).join(" ").toLowerCase().includes("true") || Object.values(copyObj).join(" ").toLowerCase().includes(event.target.value.toLowerCase());
            }
            else if (event.target.value.toLowerCase() === "of") {
                return Object.values(copyObj).join(" ").toLowerCase().includes("false") || Object.values(copyObj).join(" ").toLowerCase().includes(event.target.value.toLowerCase());
            }
            else if (event.target.value.toLowerCase() === "off") {
                return Object.values(copyObj).join(" ").toLowerCase().includes("false") || Object.values(copyObj).join(" ").toLowerCase().includes(event.target.value.toLowerCase());
            }
            else {
                return Object.values(copyObj).join(" ").toLowerCase().includes(event.target.value.toLowerCase());
            }
        })

        const offset = (page * numOfRows) % newData.length;
        setFilteredData(newData);
        setDisplayData(newData.slice(offset, offset + numOfRows))
        setUserInput(event.target.value);
    }

    // ************** //
    // Archive dialog //
    // ************** //
    const [showArvchive, setShowArvchive] = useState(false);
    const [archiveLoading, setArchiveLoading] = useState("idle");
    const [selectedArchiveObject, setSelectedArchiveObject] = useState(null);

    // Opens the archive dialog
    const openDeleteDialogue = (obj) => {
        setShowArvchive(true);
        setSelectedArchiveObject(obj);
    }

    // Archives the selected entry in the table
    const archiveHandler = () => {
        setArchiveLoading("loading");

        // Send update request to server
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        // Sending an empty body so the request doesn't fail
        axios.put(`${deleteUrl}/${selectedArchiveObject.id}/`, {}, config)
            .then(res => {
                setArchiveLoading("success");

                // Hide dialog and refresh page content after a short delay
                setTimeout(() => {
                    setShowArvchive(false);
                    setRefreshData(!refreshData)
                }, 2000)

                // Revert loading status after longer delay 
                // ↪ so it doesn't show up in the dialog while it closes (looks like a bug)
                setTimeout(() => {
                    setArchiveLoading("idle");
                }, 2500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    // *********** //
    // Copy dialog //
    // *********** //
    const [showCopy, setShowCopy] = useState(false);
    const [copyLoading, setCopyLoading] = useState("idle");
    const [selectedCopyObject, setSelectedCopyObject] = useState(null);

    // Opens the archive dialog
    const openCopyDialogue = (obj) => {
        setShowCopy(true);
        setSelectedCopyObject(obj);
    }

    // Creates a copy of the selected machine
    const copyHandler = (formBody) => {
        setCopyLoading("loading");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.post(`${copyUrl}/${selectedCopyObject.id}/`, formData, config)
            .then(res => {

                // Revert loading status after a slightly longer delay 
                setTimeout(() => {
                    setCopyLoading("success");
                }, 1500)

                // Refresh page content after a short delay
                setTimeout(() => {
                    setRefreshData(!refreshData);
                    setCopyLoading("idle");
                }, 3000)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (

        <>
            {/* Archive Dialog */}
            {
                selectedArchiveObject && (
                    <ArchiveDialogue
                        visible={showArvchive}
                        loading={archiveLoading}
                        confirm={archiveHandler}
                        hide={() => setShowArvchive(false)}
                        selectedObject={selectedArchiveObject}
                        header={`Archive ${selectedArchiveObject.name || selectedArchiveObject.item_number}`}
                    />
                )
            }

            {/* Copy Dialog */}
            {
                selectedCopyObject && (
                    <CopyDialogue
                        visible={showCopy}
                        loading={copyLoading}
                        confirm={copyHandler}
                        hide={() => setShowCopy(false)}
                        selectedObject={selectedCopyObject}
                        header={`Copy ${selectedCopyObject.name || selectedCopyObject.item_number}`}
                    />
                )
            }

            <TableContainer>
                {/* Search */}
                {search && (
                    <FormControl variant="outlined" sx={{ width: "100%", margin: "5px 0px 25px" }}>
                        <InputLabel htmlFor="search">Keyword Search</InputLabel>
                        <OutlinedInput
                            id="search"
                            name="search"
                            label="Keyword Search"
                            type="text"
                            onChange={filterTable}
                            value={userInput}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="cancel"
                                        edge="start"
                                        onClick={() => {
                                            const fakeEvent = { target: { value: "" } };
                                            filterTable(fakeEvent);
                                        }}
                                    >
                                        <Cancel />
                                    </IconButton>
                                    <IconButton
                                        aria-label="search"
                                        edge="end"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )}

                {/* Table */}
                <Table>
                    {/* Column Heads */}
                    <TableHead sx={{ backgroundColor: "var(--macrodyne-light-grey)" }}>
                        <TableRow>
                            {columns && columns.map((column) => {
                                return (
                                    <TableCell key={generateRandomKey()} sx={{ fontWeight: "bold", fontSize: "16px" }}>
                                        {
                                            column !== "actions"
                                                ? (
                                                    <StyledTableSortLabel
                                                        // Ensures the arrow for the non selected columns is always pointing in ascending order
                                                        direction={
                                                            selectedColumn === column
                                                                ? order
                                                                    ? "asc"
                                                                    : "desc"
                                                                : "asc"
                                                        }
                                                        active={column === selectedColumn}
                                                        onClick={() => handleSort(column)}
                                                    >
                                                        {column.includes("_")
                                                            ? (
                                                                <>
                                                                    {column.split("_")[0].substring(0, 1).toUpperCase()}
                                                                    {column.split("_")[0].substring(1)}
                                                                    {" "}
                                                                    {column.split("_")[1].substring(0, 1).toUpperCase()}
                                                                    {column.split("_")[1].substring(1)}
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    {column.substring(0, 1).toUpperCase()}
                                                                    {column.substring(1)}
                                                                </>
                                                            )
                                                        }
                                                    </StyledTableSortLabel>
                                                )
                                                : (
                                                    column.includes("_")
                                                        ? (
                                                            <>
                                                                {column.split("_")[0].substring(0, 1).toUpperCase()}
                                                                {column.split("_")[0].substring(1)}
                                                                {" "}
                                                                {column.split("_")[1].substring(0, 1).toUpperCase()}
                                                                {column.split("_")[1].substring(1)}
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                {column.substring(0, 1).toUpperCase()}
                                                                {column.substring(1)}
                                                            </>
                                                        )
                                                )
                                        }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>

                    {/* Data */}
                    <TableBody>
                        {
                            displayData.length
                                ? displayData.map((obj, index) => {
                                    return (
                                        <TableRow key={generateRandomKey()} sx={{ backgroundColor: striped && index % 2 !== 0 ? "var(--macrodyne-light-grey)" : "" }}>
                                            {columns.map((column) => {

                                                return column !== "actions"
                                                    ? typeof obj[column] !== "boolean"
                                                        ? (
                                                            <TableCell key={generateRandomKey()}>
                                                                {obj[column]}
                                                            </TableCell>
                                                        )
                                                        : (
                                                            <TableCell key={generateRandomKey()}>
                                                                {obj[column] ? "On" : "Off"}
                                                            </TableCell>
                                                        )
                                                    : (
                                                        <DataTableActions
                                                            obj={obj}
                                                            viewUrl={viewUrl}
                                                            editUrl={editUrl}
                                                            copyUrl={copyUrl}
                                                            deleteUrl={deleteUrl}
                                                            copyLoading={copyLoading}
                                                            key={generateRandomKey()}
                                                            openCopyDialogue={openCopyDialogue}
                                                            openDeleteDialogue={openDeleteDialogue}
                                                            selectedCopyObject={selectedCopyObject}
                                                        />
                                                    )
                                            })}
                                        </TableRow>
                                    )
                                })
                                : (
                                    <TableRow>
                                        {columns.map((columnn, index) => {
                                            return (
                                                <TableCell key={generateRandomKey()}>
                                                    {index === 0 && "No results found"}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                        }
                    </TableBody>
                </Table>

                {/* Pagination */}
                <NavigationContainer className="column-when-small">

                    {/* Navigation */}
                    {
                        Math.ceil(filteredData.length / numOfRows) > 1 && (
                            <PageNavigation className="column-when-small">
                                {/* Left arrows */}
                                <LeftArrowsContainer>

                                    {/* Max Left */}
                                    <ArrowNavigation
                                        onClick={() => { navigationHandler("double-left") }}
                                        disabled={page === 0}
                                    >
                                        <KeyboardDoubleArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    {/* Single Left */}
                                    <ArrowNavigation
                                        onClick={() => { navigationHandler("left") }}
                                        disabled={page === 0}
                                    >
                                        <KeyboardArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </LeftArrowsContainer>

                                {/* Page Numbers */}
                                <PageNumberContainer>
                                    {
                                        Math.ceil(filteredData.length / numOfRows) <= 5
                                            ? Array.from(Array(Math.ceil(filteredData.length / numOfRows)).keys()).map(num => {
                                                return (
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={() => navigationHandler("page", num)}
                                                        value={num}
                                                        key={generateRandomKey()}
                                                        className={num === page ? "active" : ""}
                                                    >
                                                        {num + 1}
                                                    </NavigationButtons>
                                                )
                                            })
                                            : (
                                                <>
                                                    {/* ************* */}
                                                    {/* First 2 pages */}
                                                    {/* ************* */}

                                                    {/* First page */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={0}
                                                        key={generateRandomKey()}
                                                        className={page === 0 ? "active" : ""}
                                                    >
                                                        1
                                                    </NavigationButtons>

                                                    {/* Second page */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={1}
                                                        key={generateRandomKey()}
                                                        className={page === 1 ? "active" : ""}
                                                    >
                                                        2
                                                    </NavigationButtons>

                                                    {/* Manual page selection */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="page">Page</InputLabel>
                                                        <StyledSelect
                                                            id="page"
                                                            name="page"
                                                            label="Page"
                                                            value={page}
                                                            onChange={(event) => navigationHandler("page", event.target.value)}
                                                        >
                                                            {
                                                                Array.from(Array(Math.ceil(filteredData.length / numOfRows)).keys()).map((element, index) => {
                                                                    return (
                                                                        <MenuItem value={index}>{index + 1}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </StyledSelect>
                                                    </StyledFormControl>

                                                    {/* ************ */}
                                                    {/* Last 2 pages */}
                                                    {/* ************ */}

                                                    {/* Second to last page */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={Math.ceil(filteredData.length / numOfRows) - 2}
                                                        key={generateRandomKey()}
                                                        className={page === Math.ceil(filteredData.length / numOfRows) - 2 ? "active" : ""}
                                                    >
                                                        {Math.ceil(filteredData.length / numOfRows) - 1}
                                                    </NavigationButtons>

                                                    {/* Last page */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={Math.ceil(filteredData.length / numOfRows) - 1}
                                                        key={generateRandomKey()}
                                                        className={page === Math.ceil(filteredData.length / numOfRows) - 1 ? "active" : ""}
                                                    >
                                                        {Math.ceil(filteredData.length / numOfRows)}
                                                    </NavigationButtons>
                                                </>
                                            )
                                    }
                                </PageNumberContainer>

                                {/* Right arrows */}
                                <div>
                                    {/* Single Right */}
                                    <ArrowNavigation
                                        onClick={() => { navigationHandler("right") }}
                                        disabled={page === Math.ceil(filteredData.length / numOfRows) - 1}
                                    >
                                        <KeyboardArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    {/* Max Right */}
                                    <ArrowNavigation
                                        onClick={() => { navigationHandler("double-right") }}
                                        disabled={page === Math.ceil(filteredData.length / numOfRows) - 1}
                                    >
                                        <KeyboardDoubleArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </div>
                            </PageNavigation>
                        )}

                    {/* Rows per Page */}
                    <FormControl sx={{ width: "107px", position: "absolute", right: "0" }}>
                        <InputLabel htmlFor="rows-per-page">Rows per page</InputLabel>
                        <Select
                            name="rows-per-page"
                            id="rows-per-page"
                            label="Rows per page"
                            value={numOfRows}
                            onChange={rowsPerPageChange}
                        >
                            {rowsPerPage.map((rowPerPage) => {
                                return (
                                    <MenuItem
                                        key={generateRandomKey()}
                                        value={rowPerPage}
                                    >
                                        {rowPerPage}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                </NavigationContainer>

            </TableContainer>
        </>
    )
}

const LeftArrowsContainer = styled.div`
    margin-right: 5px;
`

const PageNumberContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const PageNavigation = styled.div`
    display: flex;
    align-items: center;
`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
    height: 100px;
    position: relative;
    top: 0;
    left: 0;

    .active { 
        color: white;
        background-color: var(--macrodyne-blue);
    }
`

const NavigationButtons = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    fontWeight: "bold",
    marginRight: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const ArrowNavigation = muiStyle(IconButton)({
    borderRadius: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledFormControl = muiStyle(FormControl)({
    backgroundColor: "var(--macrodyne-light-grey)",
    marginRight: "5px",
})

const StyledSelect = muiStyle(Select)({
    minWidth: "65px",
})

// Solution for arrow hover color found at:
// https://stackoverflow.com/questions/56682287/how-to-custom-color-text-and-icon-in-tablesorttext-component-of-material-ui
// ↪ I had to modify it a little
const StyledTableSortLabel = muiStyle(TableSortLabel)({

    // Sets the color of the text
    "&.MuiTableSortLabel-root": {
        color: "black",
    },

    // Sets the color of the text when hovered over
    "&.MuiTableSortLabel-root:hover": {
        color: "black",

        // Sets the color of the arrow when the text is hovered over
        "& .MuiSvgIcon-root": {
            color: "black !important",
            opacity: "1 !important",
        }
    },

    // Sets the color of the active sorted column
    "&.Mui-active": {
        color: "black",
    },

    // Sets the color of the arrow
    "& .MuiTableSortLabel-icon": {
        color: "black !important",
    },

    // Sets the color of the arrow when the arrow is hovered over
    "& .MuiSvgIcon-root:hover": {
        color: "black !important",
        opacity: "1 !important",
    }
})

export default DataTableDisplay