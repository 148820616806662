// Core Imports
import { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate, Link } from "react-router-dom";

// Components
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../components/DataTable/DataTableDisplay";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useVerifyUserAccess from "../../hooks/useVerifyUserAccess";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";

// MUI
import { Edit, FormatListBulleted, Apps } from '@mui/icons-material/';
import { styled as muiStyle } from "@mui/material/styles";
import { Alert, Button, IconButton, Tooltip } from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Prime React
import { TabView, TabPanel } from 'primereact/tabview';

// Utils
import generateRandomKey from "../../utils/generateRandomKey";
import showAdminFeatures from "../../utils/showAdminFeatures";

const ViewClient = () => {

    // Used to toggle the view to list, image view by default
    const [listView, setListView] = useState(false);

    const [updateClient, setUpdateClient] = useState(false);

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();

    // Initialize hooks
    const navigate = useNavigate();
    const { clientId } = useParams();

    // Verify user access
    useVerifyUserAccess(clientId);

    // Fetches all the clients
    const [client, loading, error] = useGet(`/api/clients/get/${clientId}/`, updateClient);

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? (
                            <RedirectUser error={error} />
                        )
                        : (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    {
                                        showAdminFeatures()
                                            ? (
                                                <BreadcrumbsNav links={["dashboard", client.name]} />
                                            )
                                            : (
                                                <BreadcrumbsNav links={[client.name]} />
                                            )
                                    }
                                    {
                                        showAdminFeatures() && (
                                            <AddMachineButton
                                                variant="contained"
                                                type="button"
                                                onClick={() => navigate(`/client/${client.id}/machine/add`)}
                                            >
                                                Add Machine
                                            </AddMachineButton>
                                        )
                                    }
                                </TopContainer>
                                <Row>
                                    {/* Client Information */}
                                    <Col sm={12} lg={3}>
                                        <ClientInfo extra={showAdminFeatures(true) ? "true" : "false"} className="margin-y-when-small">
                                            <h2>{client.name}</h2>

                                            <h3>Address</h3>
                                            <Info>
                                                <span>{client.address}</span>
                                                <span>{client.city}, {client.province}</span>
                                                <span>{client.postal_code}</span>
                                            </Info>

                                            <h3>Company Contact</h3>

                                            <Info>
                                                {typeof client.contact === "object"
                                                    ? (
                                                        <>
                                                            <span>{client.contact.name}</span>
                                                            <span>{client.contact.title}</span>
                                                            <span>
                                                                <a href={`mailto:${client.contact.email}`} target="_blank" rel="noreferrer">
                                                                    {client.contact.email}
                                                                </a>
                                                            </span>
                                                            <span>{client.contact.phone}</span>
                                                        </>
                                                    )
                                                    : <span>{client.contact}</span>
                                                }
                                            </Info>

                                            <h3>Macrodyne Contact</h3>

                                            <Info>
                                                {typeof client.macrodyne_contact === "object"
                                                    ? (
                                                        <>
                                                            <span>{client.macrodyne_contact.name}</span>
                                                            <span>{client.macrodyne_contact.title}</span>
                                                            <span>
                                                                <a href={`mailto:${client.macrodyne_contact.email}`} target="_blank" rel="noreferrer">
                                                                    {client.macrodyne_contact.email}
                                                                </a>
                                                            </span>
                                                            <span>{client.macrodyne_contact.phone}</span>
                                                        </>
                                                    )
                                                    : <span>{client.macrodyne_contact}</span>
                                                }
                                            </Info>

                                            {
                                                showAdminFeatures() && (
                                                    <>
                                                        <h3>Notes</h3>
                                                        <Info>
                                                            {
                                                                client.notes
                                                                    ? <p>{client.notes}</p>
                                                                    : <p>No notes provided.</p>
                                                            }
                                                        </Info>
                                                    </>
                                                )
                                            }
                                            {
                                                showAdminFeatures() && (
                                                    <Tooltip title={`Update ${client.name}`} placement="top">
                                                        <EditButton onClick={() => navigate(`/client/${clientId}/update`)}>
                                                            <Edit />
                                                        </EditButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                showAdminFeatures(true) && (
                                                    <Center>
                                                        <Tooltip title="Manage Users" placement="top">
                                                            <ManageUsersButton onClick={() => navigate(`/client/${clientId}/users`)}>
                                                                Manage Users
                                                            </ManageUsersButton>
                                                        </Tooltip>
                                                    </Center>
                                                )
                                            }
                                        </ClientInfo>
                                    </Col>

                                    {/* Machines */}
                                    <Col sm={12} lg={9}>
                                        <MachineInfo>
                                            <TabView>
                                                <TabPanel header="Machines">
                                                    {
                                                        client.machines.length > 0
                                                            ? (
                                                                listView
                                                                    // Table View
                                                                    ? (
                                                                        client.machines.length
                                                                            ? (
                                                                                <DataTableDisplay
                                                                                    data={client.machines}
                                                                                    removeKeys={["id", "image"]}
                                                                                    viewUrl={`/client/${clientId}/machine`}
                                                                                    editUrl={
                                                                                        showAdminFeatures()
                                                                                            ? `/client/${clientId}/machine`
                                                                                            : null
                                                                                    }
                                                                                    copyUrl={
                                                                                        showAdminFeatures()
                                                                                            ? "/api/machines/copy"
                                                                                            : null
                                                                                    }
                                                                                    deleteUrl={
                                                                                        showAdminFeatures()
                                                                                            ? "/api/machines/archive"
                                                                                            : null
                                                                                    }
                                                                                    search
                                                                                    striped
                                                                                    refreshData={updateClient}
                                                                                    setRefreshData={setUpdateClient}
                                                                                />
                                                                            )
                                                                            : <Alert severity="info">The client has no machines yet.</Alert>

                                                                    )
                                                                    // Grid View
                                                                    : (
                                                                        <Row>
                                                                            {client.machines.map((machine) => {
                                                                                return (
                                                                                    <Col
                                                                                        sm={6}
                                                                                        md={6}
                                                                                        lg={4}
                                                                                        key={generateRandomKey()}
                                                                                    >
                                                                                        <Link to={`/client/${clientId}/machine/${machine.id}`}>
                                                                                            <ImageTileContainer>
                                                                                                <ImageFilter status={machine.status ? "true" : "false"}>
                                                                                                    <ImageName>{machine.name} {!machine.status && "(Offline)"}</ImageName>
                                                                                                </ImageFilter>
                                                                                                <ImageTile src={machine.image} alt={machine.name} />
                                                                                            </ImageTileContainer>
                                                                                        </Link>
                                                                                    </Col>
                                                                                )
                                                                            })}
                                                                        </Row>
                                                                    )
                                                            )
                                                            : (
                                                                <p>This client has no machines yet.</p>
                                                            )
                                                    }
                                                </TabPanel>
                                            </TabView>
                                            <Tooltip title={listView ? "Grid View" : "Table View"} placement="top">
                                                <ToggleButton onClick={() => setListView(!listView)}>
                                                    {
                                                        listView
                                                            ? <Apps />
                                                            : <FormatListBulleted />
                                                    }
                                                </ToggleButton>
                                            </Tooltip>
                                        </MachineInfo>
                                    </Col>
                                </Row>
                            </>
                        )
            }
        </main>
    )
}

const ImageFilter = styled.div`
    width: 75%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    overflow-wrap: break-word;

    /* Adds background color + transparency without affecting the image name */
    /* Source: https://antoineggg.medium.com/css-remove-opacity-inheritance-from-parent-to-children-63ebc9a26a84 */
    &::before {
        content: "";
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        background-color: ${({ status }) => status === "true" ? "var(--macrodyne-dark-blue)" : "darkred"};
        
        width: 100%;
        height: 100%;
    }
`

const ImageName = styled.p`
    color: var(--macrodyne-yellow);
    font-weight: bold;
    font-size: 32px;
    position: relative;
    top: 0;
    left: 0;
    text-shadow: 1px 1px 2px var(--macrodyne-dark-blue);
    text-align: center;
    text-wrap: balance; /* Only works in certain browsers */
`

const ImageTile = styled.img`
    display: inline-block;
    width: 75%;
    height: 350px;
    border-radius: 10px;
    background-color: var(--macrodyne-light-grey);
    /* aspect-ratio: 4/5; */
    /* aspect-ratio: 4/3; */
`

const ImageTileContainer = styled.div`
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
    border-radius: 10px;
`

const MachineInfo = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    &:last-of-type {
        margin-bottom: 0px;
    }
`

const ClientInfo = styled.div`
    background-color: var(--macrodyne-light-grey);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    padding-bottom: ${({ extra }) => extra === "true" ? "100px" : "25px"};
    position: relative;
    top: 0;
    left: 0;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

// Custom MUI IconButton template
const SmallIconButton = muiStyle(IconButton)({
    position: "absolute",
    top: "25px",
    right: "25px",
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Specific IconButton names
const EditButton = muiStyle(SmallIconButton)({});

// Specific IconButton style
const ToggleButton = muiStyle(SmallIconButton)({
    top: "12.5px",
    right: "0px",
    "@media screen and (max-width: 480px)": {
        top: "0px",
        right: "0px",
    }
})

// Custom MUI Button template
const CustomButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Creating specific button names
const AddMachineButton = muiStyle(CustomButton)({})
const ManageUsersButton = muiStyle(CustomButton)({
    position: "absolute",
    bottom: "25px"
})

export default ViewClient