// Core Imports
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import Error from "../../../components/Errors/Error";
import BreadcrumbsNav from "../../../components/BreadcrumbNav";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../../components/DataTable/DataTableDisplay";

// MUI
import { Button, Alert } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";

// Prime React
import { TabView, TabPanel, } from 'primereact/tabview';

// Custom Hooks
import useGet from "../../../hooks/useGet";
import useRedirectToLogin from "../../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../../hooks/useVerifyUserPermissions";

// Utils
import showAdminFeatures from "../../../utils/showAdminFeatures";
import isMacrodyneManager from "../../../utils/isMacrodyneManager";

const ListAllUsers = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const navigate = useNavigate();

    const [updateUsers, setUpdateUsers] = useState(true);

    // Fetches all the users
    const [users, loading, error] = useGet("/api/users/", updateUsers);

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? <Error error={error} />
                        : (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    <BreadcrumbsNav links={["dashboard", "users"]} />

                                    {
                                        showAdminFeatures(true) && (
                                            <AddUserButton
                                                variant="contained"
                                                type="button"
                                                onClick={() => navigate("/users/add")}
                                            >
                                                Add User
                                            </AddUserButton>
                                        )
                                    }
                                </TopContainer>
                                <TabView>

                                    <TabPanel header="Client Users">
                                        {
                                            users.regular
                                                ? (
                                                    <DataTableDisplay
                                                        data={users.regular}
                                                        editUrl={"/users"}
                                                        search
                                                        striped
                                                        deleteUrl={"/api/users/archive"}
                                                        removeKeys={["id", "password", "company", "is_macrodyne", "first_name", "last_name", "is_manager"]}
                                                        refreshData={updateUsers}
                                                        setRefreshData={setUpdateUsers}
                                                    />
                                                )
                                                : <Alert severity="info">There are no registered users on the website yet.</Alert>
                                        }
                                    </TabPanel>

                                    {
                                        isMacrodyneManager() && (
                                            <TabPanel header="Macrodyne Users">
                                                {
                                                    users.macrodyne
                                                        ? (
                                                            <DataTableDisplay
                                                                data={users.macrodyne}
                                                                editUrl={"/users"}
                                                                search
                                                                striped
                                                                deleteUrl={"/api/users/archive"}
                                                                removeKeys={["id", "password", "company", "is_macrodyne", "first_name", "last_name", "is_manager"]}
                                                                refreshData={updateUsers}
                                                                setRefreshData={setUpdateUsers}
                                                            />
                                                        )
                                                        : <Alert severity="info">There are no registered macrodyne users on the website yet.</Alert>
                                                }
                                            </TabPanel>
                                        )
                                    }
                                </TabView>
                            </>
                        )
            }
        </main >
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const AddUserButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default ListAllUsers