// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import PreviewPdf from "../../components/PDF/PreviewPdf";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";

// MUI
import {
    Alert,
    Button,
    Select,
    Tooltip,
    MenuItem,
    IconButton,
    InputLabel,
    FormControl,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";
import { Clear, UploadFileOutlined } from "@mui/icons-material";

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

// A troubleshooting operation is just a schematic
// This component was copy pasted from AddSchematic.js
// ↪ The only difference is on line 129, in the post endpoint URL
const AddTroubleshooting = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});
    const [uploadError, setUploadError] = useState("");

    const navigate = useNavigate();
    const { clientId, machineId } = useParams();

    // Used for accessing the file upload input
    const inputRef = useRef(null);
    const uploadRef = useRef(null);
    const uploadButtonRef = useRef(null);

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Converts the uploaded file into a series of image to be displayed in the preview
    // const [convertedPdf, convertLoading, currentPage, numPages] = useConvertUploadedPdf(formBody);

    // Removes the PDF from the form
    const handleRemoveFile = () => {

        // Remove the current value of the file upload input
        uploadRef.current.firstChild.value = "";

        // Delete the information from the formBody
        const copiedFormBody = { ...formBody };
        delete copiedFormBody.schematic;
        setFormBody(copiedFormBody);
    }

    // Handles form changes
    const handleChange = (event) => {
        // Reset the error on change
        setUploadError("");

        // Check for a file + make sure the file is a pdf
        if (
            event.target.type === "file" &&
            // in case the user presses cancel
            event.target.files.length &&
            event.target.files[0].type.split("/")[1] === "pdf"
        ) {
            // Set the file in the small input if the big one was used
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(event.target.files[0]);
            uploadRef.current.firstChild.files = dataTransfer.files;

            setFormBody({
                ...formBody,
                [event.target.name]: event.target.files[0],
            });
        }
        else if (
            event.target.type === "file" &&
            // in case the user presses cancel
            event.target.files.length &&
            event.target.files[0].type.split("/")[1] !== "pdf"
        ) {
            setUploadError("Please upload a valid PDF file.");
            handleRemoveFile();
        }
        else {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
            });
        }

    }

    // Submits the form
    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.post(`/api/schematics/${machineId}/add/true`, formData, config)
            .then(res => {
                setStatus("success");

                setTimeout(() => {
                    navigate(`/client/${clientId}/machine/${machineId}/troubleshooting/${res.data.id}`);
                }, 1500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    // Handles drag and drop uploading
    const handleDragEnter = () => {
        uploadButtonRef.current.classList.add("dragover");
    }

    // Handles drag and drop uploading
    const handleDragLeave = () => {
        uploadButtonRef.current.classList.remove("dragover");
    }

    return (
        <main>
            {
                status === "sending"
                    ? <LoadingMessage message={"Adding troubleshooting operation."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Troubleshooting operation added successfully!"} />
                        : (
                            <>
                                <BreadcrumbsNav
                                    links={[
                                        "dashboard",
                                        { name: clientName, value: clientId, url: "client" },
                                        { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                        "add troubleshooting"
                                    ]}
                                />

                                <AddTroubleshootingForm onSubmit={handleSubmit} onChange={handleChange}>
                                    <Row>
                                        <FormTitle>Add Troubleshooting Operation</FormTitle>
                                        {/* Form - Schematic Information */}
                                        <Col sm={12} md={3}>

                                            {/* Operation */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="name">Operation</InputLabel>
                                                <OutlinedInput
                                                    id="name"
                                                    name="name"
                                                    label="Operation"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {uploadError && <StyledUploadErrorAlert severity="error">{uploadError}</StyledUploadErrorAlert>}

                                            {/* Schematic file */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel shrink htmlFor="schematic">File</InputLabel>
                                                <OutlinedInput
                                                    id="schematic"
                                                    name="schematic"
                                                    label="File"
                                                    type="file"
                                                    inputProps={{ accept: "application/pdf" }}
                                                    notched
                                                    ref={uploadRef}
                                                    endAdornment={
                                                        // Only shows the remove file button when the PDF file is fully converted
                                                        //  ↪ If the button is clicked while the PDF file is being converted, it will just show up when the conversion is done
                                                        formBody.schematic && (
                                                            <InputAdornment position="start">
                                                                <Tooltip title="Remove file" placement="top">
                                                                    <RemoveFileButton
                                                                        aria-label="remove file"
                                                                        edge="start"
                                                                        onClick={handleRemoveFile}
                                                                    >
                                                                        <Clear />
                                                                    </RemoveFileButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                />
                                            </StyledFormControl>

                                            {/* Schematic Type */}
                                            <StyledFormControl required variant="outlined" fullWidth>
                                                <InputLabel htmlFor="type">Type</InputLabel>
                                                <Select
                                                    name="type"
                                                    id="type"
                                                    label="Type"
                                                    value={formBody.type ? formBody.type : ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"Hydraulic"}>Hydraulic</MenuItem>
                                                    <MenuItem value={"Electrical"}>Electrical</MenuItem>
                                                </Select>
                                            </StyledFormControl>

                                            {/* Schematic notes */}
                                            <StyledFormControl variant="outlined">
                                                <InputLabel htmlFor="notes">Notes</InputLabel>
                                                <OutlinedInput
                                                    id="notes"
                                                    name="notes"
                                                    label="Notes"
                                                    type="text"
                                                    multiline
                                                    rows={11}
                                                />
                                            </StyledFormControl>

                                        </Col>

                                        {/* Schematic Preview */}
                                        <Col sm={12} md={9}>
                                            <Center>
                                                {
                                                    formBody.schematic
                                                        ? <PreviewPdf file={formBody.schematic} />
                                                        : (
                                                            <UploadImageContainer>
                                                                <UploadButton
                                                                    ref={uploadButtonRef}
                                                                    onDragEnter={handleDragEnter}
                                                                    onDragLeave={handleDragLeave}
                                                                    onDrop={handleDragLeave}
                                                                >
                                                                    {
                                                                        uploadError
                                                                            ? <StyledErrorAlert severity="error">{uploadError}</StyledErrorAlert>
                                                                            : !formBody.schematic && (
                                                                                <>
                                                                                    <StyledAlert severity="info">
                                                                                        Upload a PDF file to preview it.
                                                                                    </StyledAlert>
                                                                                    <UploadInstructions>
                                                                                        <p>Click to upload a PDF file</p>
                                                                                        <p>or drag & drop it</p>
                                                                                        <UploadFileOutlined sx={{ fontSize: 150 }} />
                                                                                    </UploadInstructions>
                                                                                </>
                                                                            )
                                                                    }

                                                                    <input ref={inputRef} type="file" name="schematic" accept="application/pdf" />
                                                                </UploadButton>
                                                            </UploadImageContainer>
                                                        )

                                                }
                                            </Center>
                                        </Col>
                                    </Row>

                                    <SubmitButton type="submit">Save</SubmitButton>

                                </AddTroubleshootingForm>
                            </>
                        )
            }
        </main>
    )
}

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const AddTroubleshootingForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

const UploadImageContainer = styled.div`
    background-color: #F4F7FA;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    margin-bottom: 18px;
    border: solid 1px #BCBEC0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: 0px;
    left: 0px;

    &:hover {
        border: solid 1px;
    }

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .dragover {
        opacity: 0.5;
    }
`

const UploadInstructions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const UploadButton = muiStyle(Button)({
    width: "100%",
    height: "100%",
})

const RemoveFileButton = muiStyle(IconButton)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const SubmitButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

const StyledUploadErrorAlert = muiStyle(Alert)({
    marginBottom: "25px",
})

const StyledAlert = muiStyle(Alert)({
    position: "absolute",
    left: "38%",
    top: "25px",
})

const StyledErrorAlert = muiStyle(StyledAlert)({
    top: "45%",
})

export default AddTroubleshooting;