// Core Imports
import styled from "styled-components";

// MUI
import { CircularProgress } from '@mui/material';

// Loading component
const LoadingSpinner = () => {
    return (
        <Center>
            <CircularProgress />
        </Center>
    )
}

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export default LoadingSpinner