import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

/* .openseadragon-container:first-of-type {
    display: none;
} */

.Mui-disabled {
    cursor: not-allowed !important;
}

/* Variables */
:root {
    
    /* Blue tones */
    --macrodyne-blue: #10529E;
    --macrodyne-dark-blue: #273C82;
    --macrodyne-light-blue: #A1A1C7;

    /* Yellow tones */
    --macrodyne-yellow: #FABE2F;

    /* Grey tones */
    --macrodyne-grey: #A8B2B1;
    --macrodyne-light-grey: #F4F7FA;
}

/* Annotations */
.a9s-outer {
    stroke: none !important;
}

.a9s-inner {
    /* !important required to overwrite default rules */
    stroke: var(--macrodyne-yellow) !important;
    stroke-width: 3px !important;
    fill: var(--macrodyne-yellow) !important;
    
    /* New rules don't require !important */
    opacity: 0.5;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: var(--macrodyne-dark-blue);
    margin-bottom: 25px;
}

h2 {
    font-size: 26px;
    padding-right: 25px;
}

h3 {
    font-size: 22px;
}

/* Media query to style certain elements when the screen is too small */
@media screen and (max-width: 480px) {

    /* Hides elements */
    .hide-when-small {
        display: none;
    }

    /* Changes the flex direction */
    .column-when-small {
        flex-direction: column;
    }

    /* Adjusts the vertical margin */
    .margin-y-when-small {
        margin-top: 5px;
        margin-bottom: 25px;
    }
}

/* Media query to style certain elements when the screen is medium */
@media screen and (max-width: 1024px) {

    /* Hides elements */
    .hide-when-small {
        display: none;
    }

    /* Adjusts the vertical margin */
    .margin-y-when-small {
        margin-top: 5px;
        margin-bottom: 25px;
    }

    /* Changes the flex direction */
    .column-when-small {
        flex-direction: column;
    }
}

header {
    /* Adapts based on user being logged in or not */
    max-height: ${({ user }) => user ? "7em" : "0em"};
}

footer {
    height: 4.5em;
}

main {
    /* Adapts based on user being logged in or not */
    min-height: calc(100vh - ${({ user }) => user ? "11.5em" : "4.5em"});
    padding: 25px;
    max-width: 1600px;
    margin: auto;
}

/* CSS Reset */

/* 0. Maxing out page height */
#root {
    min-height: 100vh;
}

/* 1. Use a more-intuitive box-sizing model. */
*, *::before, *::after {
    box-sizing: border-box;
}

/* 2. Remove default margin */
/* 3. Add accessible line-height */
* {
    margin: 0;
    line-height: calc(1em + 0.5rem);
}

/* 4. Improve text rendering */
body {
    -webkit-font-smoothing: antialiased;
}

/* 5. Improve media defaults */
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

/* 6. Remove built-in form typography styles */
input, button, textarea, select {
    font: inherit;
}

/* 7. Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

/* 8. Create a root stacking context */
#root, #__next {
    isolation: isolate;
}
`

export default GlobalStyles;