// Core Imports
import styled from "styled-components";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { UserContext } from "../../context/UserContext";

// MUI
import { Alert } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";
import { useEffect } from "react";

// Bootstrap
import ProgressBar from 'react-bootstrap/ProgressBar';

// RedirectUser component 
const RedirectUser = ({error}) => {

    const TIMER_INTERVAL = 50;
    const WAIT_TIME = 3000;

    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    const { setRedirectUser } = useContext(UserContext);

    useEffect(() => {

        // Fills up the progress bar
        const interval = setInterval(() => {
            setTimer((prev) => prev + TIMER_INTERVAL)
        }, TIMER_INTERVAL)

        // Redirects after X seconds + 1 second of delay for the animation to complete
        const timeout = setTimeout(() => {
            setRedirectUser(false);
            navigate("/")
        }, WAIT_TIME + 1000)

        return () => {
            // Both need to be erased so they don't persist
            clearTimeout(timeout);
            clearInterval(interval);
        }

    }, [])

    return (
        <main>
            <Center>
                <StyledAlert severity="error">
                    {error}
                </StyledAlert>
                <StyledAlert severity="warning">
                    You are being redirected to the home page.
                </StyledAlert>
                <StyledProgress now={timer / WAIT_TIME * 100} />
            </Center>
        </main>
    )
}

const StyledProgress = styled(ProgressBar)`
    width: 100%;
    margin-top: 25px;
`

const StyledAlert = muiStyle(Alert)({
    display: "flex",
    alignItems: "center",
    margin: "25px",
    fontSize: "22px",
})

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export default RedirectUser;