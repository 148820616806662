// This file exists solely for the purpose of importing all the buttons for the OpenSeaDragon viewer's navigation
// and providing them in a single object.

// In order for the button images to work during deployment, they must be individually defined for them to be bundled togeter in the app.

// Zoom in buttons
import zoomin_rest from "../assets/osd-images/zoomin_rest.png";
import zoomin_hover from "../assets/osd-images/zoomin_hover.png";
import zoomin_pressed from "../assets/osd-images/zoomin_pressed.png";
import zoomin_grouphover from "../assets/osd-images/zoomin_grouphover.png";

// Zoom out buttons
import zoomout_rest from "../assets/osd-images/zoomout_rest.png";
import zoomout_hover from "../assets/osd-images/zoomout_hover.png";
import zoomout_pressed from "../assets/osd-images/zoomout_pressed.png";
import zoomout_grouphover from "../assets/osd-images/zoomout_grouphover.png";

// Home buttons
import home_rest from "../assets/osd-images/home_rest.png";
import home_hover from "../assets/osd-images/home_hover.png";
import home_pressed from "../assets/osd-images/home_pressed.png";
import home_grouphover from "../assets/osd-images/home_grouphover.png";

// Full page buttons
import fullpage_rest from "../assets/osd-images/fullpage_rest.png";
import fullpage_hover from "../assets/osd-images/fullpage_hover.png";
import fullpage_pressed from "../assets/osd-images/fullpage_pressed.png";
import fullpage_grouphover from "../assets/osd-images/fullpage_grouphover.png";

// Previous page buttons
import previous_rest from "../assets/osd-images/previous_rest.png";
import previous_hover from "../assets/osd-images/previous_hover.png";
import previous_pressed from "../assets/osd-images/previous_pressed.png";
import previous_grouphover from "../assets/osd-images/previous_grouphover.png";

// Next page buttons
import next_rest from "../assets/osd-images/next_rest.png";
import next_hover from "../assets/osd-images/next_hover.png";
import next_pressed from "../assets/osd-images/next_pressed.png";
import next_grouphover from "../assets/osd-images/next_grouphover.png";

const osdButtons = {
    zoomin_rest,
    zoomin_hover,
    zoomin_pressed,
    zoomin_grouphover,

    zoomout_rest,
    zoomout_hover,
    zoomout_pressed,
    zoomout_grouphover,

    home_rest,
    home_hover,
    home_pressed,
    home_grouphover,

    fullpage_rest,
    fullpage_hover,
    fullpage_pressed,
    fullpage_grouphover,

    previous_rest,
    previous_hover,
    previous_pressed,
    previous_grouphover,

    next_rest,
    next_hover,
    next_pressed,
    next_grouphover,
}

export default osdButtons;