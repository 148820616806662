// Core Imports
import { useEffect, useContext } from "react";

// Cookie Handling
import CryptoJS from 'crypto-js';
import Cookies from "universal-cookie";

// Context
import { UserContext } from "../context/UserContext";

// Custom hook that checks the user's permissions for the current page
const useVerifyUserAccess = (clientId) => {

    // Declare cookie instance
    const cookies = new Cookies();

    // Get stored cookie
    const storedCookie = cookies.get("user");

    const { setRedirectUser } = useContext(UserContext);

    useEffect(() => {
        if (storedCookie) {

            const decryptedCookie = JSON.parse(CryptoJS.AES.decrypt(storedCookie, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));

            const hasAccess = decryptedCookie.company === Number(clientId);

            // // Superusers have access to the whole website
            const hasPermission = ["is_macrodyne", "is_superuser"].some((permission) => {
                return decryptedCookie[permission];
            })

            const redirect = hasPermission || hasAccess;

            setRedirectUser(!redirect);
        }
    })


}

export default useVerifyUserAccess