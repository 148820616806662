// Core Imports
import styled from "styled-components";
import { useState, useEffect } from "react";

// MUI
import {
    Select, 
    Button,
    MenuItem,
    IconButton,
    InputLabel,
    FormControl,
    OutlinedInput,
} from '@mui/material';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { styled as muiStyle } from "@mui/material/styles";

// Utils
import generateRandomKey from "../../utils/generateRandomKey";

// React-PDF
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewPdf = ({ file }) => {

    const [page, setPage] = useState(0);
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    // Handles page navigation
    const navigationHandler = (action, pageNumber) => {
        // inspired by https://www.npmjs.com/package/react-paginate
        let newPage;

        switch (action) {
            case "double-left":
                newPage = 0;
                break;

            case "left":
                newPage = page - 1;
                break;

            case "right":
                newPage = page + 1;
                break;

            case "double-right":
                newPage = numPages - 1;
                break;

            case "page":
                newPage = Number(pageNumber);
                break;

            case "default":
                break;
        }

        setPage(newPage);
    }

    // Resets the page if the uploaded document gets changed
    useEffect(() => {
        setPage(0);
    }, [file])

    return (
        <>
            <StyledDocument
                id="rony"
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <StyledPage
                    // Pdf viewer doesn't use 0 as a starting point
                    pageNumber={page + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                // customTextRenderer={false}
                />
            </StyledDocument>

            {/* Page Navigation */}
            {
                // Only shows the page navigation buttons if there's more than one page
                numPages > 1 && (
                    <>
                        <NavigationContainer className="column-when-small">

                            {/* Pagination & Navigation */}
                            <PageNavigation className="column-when-small">

                                {/* Left Arrows Navigation */}
                                <LeftArrowsContainer className="my-2 my-md-0">
                                    <ArrowNavigation
                                        onClick={() => navigationHandler("double-left")}
                                        disabled={page === 0}
                                    >
                                        <KeyboardDoubleArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    <ArrowNavigation
                                        onClick={() => navigationHandler("left")}
                                        disabled={page === 0}
                                    >
                                        <KeyboardArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </LeftArrowsContainer>

                                {/* Page Numbers Navigation */}
                                <PageNumberContainer>

                                    {/* 
                                        Shows all page buttons if the PDF document has 5 pages or less.

                                        If the document has more than 5 pages, only shows the first and last 2 pages and an input field to jump to a specific page. 
                                    */}
                                    {
                                        numPages <= 5
                                            ? Array.from(Array(numPages).keys()).map((element, index) => {
                                                return (
                                                    <NavigationButtons
                                                        value={index}
                                                        variant="contained"
                                                        key={generateRandomKey()}
                                                        className={index === page ? "active" : ""}
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                    >
                                                        {index + 1}
                                                    </NavigationButtons>
                                                )
                                            })
                                            : (
                                                <>
                                                    {/* First 2 pages */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={0}
                                                        key={generateRandomKey()}
                                                        className={page === 0 ? "active" : ""}
                                                    >
                                                        1
                                                    </NavigationButtons>
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={1}
                                                        key={generateRandomKey()}
                                                        className={page === 1 ? "active" : ""}
                                                    >
                                                        2
                                                    </NavigationButtons>

                                                    {/* Manual page selection */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="page">Page</InputLabel>
                                                        <StyledSelect
                                                            id="page"
                                                            name="page"
                                                            label="Page"
                                                            value={page}
                                                            onChange={(event) => navigationHandler("page", event.target.value)}
                                                        >
                                                            {
                                                                Array.from(Array(numPages).keys()).map((element, index) => {
                                                                    return (
                                                                        <MenuItem value={index}>{index + 1}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </StyledSelect>
                                                    </StyledFormControl>

                                                    {/* Last 2 pages */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={numPages - 2}
                                                        key={generateRandomKey()}
                                                        className={page === numPages - 2 ? "active" : ""}
                                                    >
                                                        {numPages - 1}
                                                    </NavigationButtons>
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={numPages - 1}
                                                        key={generateRandomKey()}
                                                        className={page === numPages - 1 ? "active" : ""}
                                                    >
                                                        {numPages}
                                                    </NavigationButtons>
                                                </>
                                            )
                                    }
                                </PageNumberContainer>

                                {/* Right Arrows Navigation */}
                                <div className="my-2 my-md-0">
                                    <ArrowNavigation
                                        onClick={() => navigationHandler("right")}
                                        disabled={page === numPages - 1}
                                    >
                                        <KeyboardArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    <ArrowNavigation
                                        onClick={() => navigationHandler("double-right")}
                                        disabled={page === numPages - 1}
                                    >
                                        <KeyboardDoubleArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </div>
                            </PageNavigation>

                        </NavigationContainer>
                    </>
                )
            }
        </>
    )
}

const LeftArrowsContainer = styled.div`
    margin-right: 5px;
`

const PageNumberContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const PageNavigation = styled.div`
    display: flex;
    align-items: center;
`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
    position: relative;
    top: 0;
    left: 0;

    .active { 
        color: white;
        background-color: var(--macrodyne-blue);
    }
`

const StyledPage = styled(Page)`
    height: 100%;

    display: flex;
    justify-content: center;

    .react-pdf__Page__canvas {
        height: 100% !important;
        width: auto !important;
    }
`

const StyledDocument = styled(Document)`
    margin: 0;
    width: 100%;
    height: 1000px;
    border: solid 3px black;

    @media screen and (max-width: 1024px) {
        height: 750px;
    }

    @media screen and (max-width: 780px) {
        height: 500px;
    }
`

const NavigationButtons = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    fontWeight: "bold",
    marginRight: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const ArrowNavigation = muiStyle(IconButton)({
    borderRadius: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledFormControl = muiStyle(FormControl)({
    backgroundColor: "var(--macrodyne-light-grey)",
    marginRight: "5px",
})

const StyledSelect = muiStyle(Select)({
    minWidth: "65px",
})

export default PreviewPdf;