// Core Imports
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// Components
import Error from "../../../components/Errors/Error";
import BreadcrumbsNav from "../../../components/BreadcrumbNav";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../../components/DataTable/DataTableDisplay";

// MUI
import { Alert, Button } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";

// Custom Hooks
import useGet from "../../../hooks/useGet";
import useRedirectToLogin from "../../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../../hooks/useVerifyUserPermissions";

// Utils
import showAdminFeatures from "../../../utils/showAdminFeatures";

const ListUsers = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions(true);

    const navigate = useNavigate();
    const { clientId } = useParams();

    const [updateUsers, setUpdateUsers] = useState(true);

    // Fetches all the users
    const [users, loading, error] = useGet(`/api/users/${clientId}/`, updateUsers);

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? <Error error={error} />
                        : (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    {
                                        showAdminFeatures()
                                            ? (
                                                <BreadcrumbsNav links={["dashboard", { name: clientName, value: clientId, url: "client" }, "users"]} />
                                            )
                                            : (
                                                <BreadcrumbsNav links={[{ name: clientName, value: clientId, url: "client" }, "users"]} />
                                            )
                                    }
                                    {
                                        showAdminFeatures(true) && (
                                            <AddUserButton
                                                variant="contained"
                                                type="button"
                                                onClick={() => navigate(`/client/${clientId}/users/add`)}
                                            >
                                                Add User
                                            </AddUserButton>
                                        )
                                    }
                                </TopContainer>
                                {
                                    users.length
                                        ? (
                                            <DataTableDisplay
                                                data={users}
                                                editUrl={`/client/${clientId}/users`}
                                                deleteUrl={"/api/users/archive"}
                                                search
                                                striped
                                                removeKeys={["id", "password", "first_name", "last_name", "is_manager"]}
                                                refreshData={updateUsers}
                                                setRefreshData={setUpdateUsers}
                                            />
                                        )
                                        : <Alert severity="info">There are no registered users for this client yet.</Alert>
                                }

                            </>
                        )
            }
        </main >
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const AddUserButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default ListUsers