// Core Imports
import axios from "axios";
import { useState, useEffect } from "react";

// Cookie Handling
import Cookies from "universal-cookie";

// Utilities
import getUserToken from "../utils/getUserToken";

// Custom hook that fetches data using a provided URL and axios
// Authenticates the user with the JWT
const useGet = (url, refreshData) => {

    // Declare cookie instance
    const cookies = new Cookies();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const storedCookie = cookies.get("user");
    
    // Fetches all the required data
    useEffect(() => {
        // Only fetches if the cookie is valid
        if (storedCookie) {
            setLoading(true);

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${getUserToken()}`,
                }
            }

            axios.get(url, config)
                .then(res => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    setError(() => {
                        return err.response.data.error ? err.response.data.error : err.message
                    });
                })
        }
    }, [storedCookie, url, refreshData])

    return [data, loading, error];
}

export default useGet;