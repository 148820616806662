// Core Imports
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext"

// Custom hook that checks if the user is not logged in or not.
// If not, the user gets redirected to the login page.
const useRedirectToLogin = () => {
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {

        if (!user) {
            navigate("/login");
        }

    }, [user, navigate])
}


export default useRedirectToLogin;