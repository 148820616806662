// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { InsertPhotoOutlined, HideImage } from "@mui/icons-material";
import { FormControl, Alert, InputLabel, OutlinedInput, Button, IconButton, Tooltip } from "@mui/material";

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

const AddMachine = () => {
    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});
    const [uploadError, setUploadError] = useState("");

    const navigate = useNavigate();
    const { clientId } = useParams();

    // Used for accessing the file upload input
    const uploadRef = useRef(null);
    const uploadButtonRef = useRef(null);

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);

    // Removes the image from the form
    const handleRemoveImage = () => {
        // Remove the current value of the file upload input
        uploadRef.current.value = "";

        // Delete the information from the formBody
        const copiedFormBody = { ...formBody };
        delete copiedFormBody.image;
        setFormBody(copiedFormBody)
    }

    // Handles form changes
    const handleChange = (event) => {

        // Reset the error on change
        setUploadError("");

        // Check for a file + make sure the file is an image
        if (
            event.target.type === "file" &&
            // in case the user presses cancel
            event.target.files.length &&
            event.target.files[0].type.split("/")[0] === "image"
        ) {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.files[0],
            });
        }
        else if (
            event.target.type === "file" &&
            // in case the user presses cancel
            event.target.files.length &&
            event.target.files[0].type.split("/")[0] !== "image"
        ) {
            setUploadError("Please upload a valid image file.");
            handleRemoveImage();
        }
        else {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value
            });
        }

    }

    // Submits the form
    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.post(`/api/machines/${clientId}/add/`, formData, config)
            .then(res => {
                setStatus("success");

                setTimeout(() => {
                    navigate(`/client/${clientId}/machine/${res.data.id}`);
                }, 1500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    // Handles drag and drop uploading
    const handleDragEnter = () => {
        uploadButtonRef.current.classList.add("dragover");
    }

    // Handles drag and drop uploading
    const handleDragLeave = () => {
        uploadButtonRef.current.classList.remove("dragover");
    }

    return (
        <main>
            {
                status === "sending"
                    ? <LoadingMessage message={"Adding machine."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Machine added successfully!"} />
                        : (
                            <>
                                <BreadcrumbsNav links={["dashboard", { name: clientName, value: clientId, url: "client" }, "add machine"]} />

                                <AddMachineForm onSubmit={handleSubmit} onChange={handleChange}>
                                    <FormTitle>Add Machine</FormTitle>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="name">Machine Name</InputLabel>
                                                <OutlinedInput
                                                    id="name"
                                                    name="name"
                                                    label="Machine Name"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="number">Machine Number</InputLabel>
                                                <OutlinedInput
                                                    id="number"
                                                    name="number"
                                                    label="Machine Number"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel shrink htmlFor="installed_on">Installation Date</InputLabel>
                                                <OutlinedInput
                                                    id="installed_on"
                                                    name="installed_on"
                                                    label="Installation Date"
                                                    type="date"
                                                    notched
                                                />
                                            </StyledFormControl>
                                        </Col>
                                    </Row>

                                    {/* End of required information section */}
                                    <Divider />

                                    {/* Beginning of optional information section */}
                                    <Row>
                                        <Col sm={12} lg={9}>
                                            <SmallFormTitle>Description</SmallFormTitle>
                                            <StyledFormControl variant="outlined">
                                                <InputLabel htmlFor="description">Description</InputLabel>
                                                <OutlinedInput
                                                    id="description"
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    multiline
                                                    rows={10}
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        {/* Image upload */}
                                        <Col sm={12} lg={3}>
                                            <TopOfImageSection>
                                                <SmallFormTitle>Image</SmallFormTitle>
                                                {/* Button to remove the image */}
                                                {
                                                    formBody.image && (
                                                        <Tooltip title="Remove image" placement="top">
                                                            <RemoveImage onClick={handleRemoveImage}>
                                                                <HideImage />
                                                            </RemoveImage>
                                                        </Tooltip>
                                                    )
                                                }
                                            </TopOfImageSection>
                                            <UploadImageContainer>
                                                {uploadError && <StyledAlert severity="error">{uploadError}</StyledAlert>}
                                                <UploadButton
                                                    ref={uploadButtonRef}
                                                    onDragEnter={handleDragEnter}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDragLeave}
                                                >
                                                    {
                                                        !uploadError && (
                                                            formBody.image
                                                                ? <ImagePreview src={URL.createObjectURL(formBody.image)} />
                                                                : (
                                                                    <UploadInstructions>
                                                                        <p>Click to upload an image</p>
                                                                        <p>or drag & drop it</p>
                                                                        <InsertPhotoOutlined sx={{ fontSize: 150 }} />
                                                                    </UploadInstructions>
                                                                )
                                                        )
                                                    }
                                                    <input ref={uploadRef} type="file" name="image" accept="image/*" />
                                                </UploadButton>
                                            </UploadImageContainer>
                                        </Col>
                                    </Row>

                                    <SubmitButton type="submit">Save</SubmitButton>

                                </AddMachineForm>
                            </>
                        )
            }
        </main>
    )
}

const UploadInstructions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ImagePreview = styled.div`
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: url(${props => props.src}) center/contain no-repeat;
`

const TopOfImageSection = styled.div`
    display: flex;
    justify-content: space-between;
`

const UploadImageContainer = styled.div`
    background-color: #F4F7FA;
    border-radius: 10px;
    height: 263px;
    border: solid 1px #BCBEC0;
    display: flex;
    justify-content: center;
    position: relative;
    top: 0px;
    left: 0px;

    &:hover {
        border: solid 1px;
    }

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .dragover {
        opacity: 0.5;
    }
`

const Divider = styled.div`
    background-color: var(--macrodyne-grey);
    height: 1px;
    margin: 25px 0px;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const SmallFormTitle = styled(FormTitle)`
    font-size: 17px;
`

const AddMachineForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

const RemoveImage = muiStyle(IconButton)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    height: "fit-content",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledAlert = muiStyle(Alert)({
    position: "absolute",
    top: "110px",
})

const UploadButton = muiStyle(Button)({
    width: "100%",
    textTransform: "none",
})

const SubmitButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

export default AddMachine;