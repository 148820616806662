// Cookie Handling
import CryptoJS from 'crypto-js';
import Cookies from "universal-cookie";

// Utility function that returns the user's authentication token
const getUserToken = () => {
    // Declare cookie instance
    const cookies = new Cookies();

    const storedCookie = cookies.get("user");
    const decryptedCookie = JSON.parse(CryptoJS.AES.decrypt(storedCookie, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));

    return decryptedCookie.token;
}

export default getUserToken;