// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { FormControl, InputLabel, OutlinedInput, Button, } from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

const UpdateClient = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});

    const { clientId } = useParams();
    const [client, loading, error] = useGet(`/api/clients/get/${clientId}/`);

    const navigate = useNavigate();

    // Sets the formBody manually to prefill the input values in the form
    useEffect(() => {
        if (client) {
            // Not a very clean solution
            let ind;
            let phone;
            let ext = "";

            let macrodyne_ind;
            let macrodyne_phone;
            let macrodyne_ext = "";

            const hasExention = client.contact.phone.split("ext.").length > 1;

            if (hasExention) {
                ind = client.contact.phone.split("-")[0];
                phone = client.contact.phone.substring(client.contact.phone.indexOf("-") + 1, client.contact.phone.indexOf("ext."));
                ext = client.contact.phone.split("ext.")[1];
            }
            else {
                ind = client.contact.phone.split("-")[0];
                phone = client.contact.phone.substring(client.contact.phone.indexOf("-") + 1);
            }

            const macrodyne_hasExention = client.macrodyne_contact.phone.split("ext.").length > 1;

            if (macrodyne_hasExention) {
                macrodyne_ind = client.macrodyne_contact.phone.split("-")[0];
                macrodyne_phone = client.macrodyne_contact.phone.substring(client.macrodyne_contact.phone.indexOf("-") + 1, client.macrodyne_contact.phone.indexOf("ext."));
                macrodyne_ext = client.macrodyne_contact.phone.split("ext.")[1];
            }
            else {
                macrodyne_ind = client.macrodyne_contact.phone.split("-")[0];
                macrodyne_phone = client.macrodyne_contact.phone.substring(client.macrodyne_contact.phone.indexOf("-") + 1);
            }

            setFormBody({
                ...formBody,
                client_id: client.id,
                client_name: client.name,
                address: client.address,
                city: client.city,
                province: client.province,
                postal_code: client.postal_code,
                country: client.country,
                contact_id: client.contact.id,
                contact_name: client.contact.name,
                title: client.contact.title,
                email: client.contact.email,
                ind: ind,
                phone: phone,
                ext: ext,
                macrodyne_contact_id: client.macrodyne_contact.id,
                macrodyne_contact_name: client.macrodyne_contact.name,
                macrodyne_title: client.macrodyne_contact.title,
                macrodyne_email: client.macrodyne_contact.email,
                macrodyne_ind: macrodyne_ind,
                macrodyne_phone: macrodyne_phone,
                macrodyne_ext: macrodyne_ext,
                notes: client.notes,
            })
        }
    }, [client])

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.put(`/api/clients/update/${clientId}/`, formData, config)
            .then(res => {
                setStatus("success");

                setTimeout(() => {
                    navigate(`/client/${res.data.id}`);
                }, 1500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? (
                            <RedirectUser error={error} />
                        )
                        : status === "sending"
                            ? <LoadingMessage message={"Updating client information"} />
                            : status === "success"
                                ? <SubmitSuccess message={"Client updated successfully!"} />
                                : client && (
                                    <>
                                        <BreadcrumbsNav links={["dashboard", { name: client.name, value: clientId, url: "client" }, "update"]} />

                                        <UpdateClientForm onSubmit={handleSubmit} onChange={handleChange}>
                                            <FormTitle>Update {client.name}</FormTitle>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="client_name">Client Name</InputLabel>
                                                <OutlinedInput
                                                    id="client_name"
                                                    name="client_name"
                                                    label="Client Name"
                                                    type="text"
                                                    value={formBody.client_name ? formBody.client_name : ""}
                                                />
                                            </StyledFormControl>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="address">Address</InputLabel>
                                                <OutlinedInput
                                                    id="address"
                                                    name="address"
                                                    label="Address"
                                                    type="text"
                                                    value={formBody.address ? formBody.address : ""}
                                                />
                                            </StyledFormControl>

                                            {/* Contains and styles the smaller inputs */}
                                            <Row>
                                                <Col sm={12} md={6} lg={3}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="city">City</InputLabel>
                                                        <OutlinedInput
                                                            id="city"
                                                            name="city"
                                                            label="City"
                                                            type="text"
                                                            value={formBody.city ? formBody.city : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={6} lg={3}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="province">Province / State</InputLabel>
                                                        <OutlinedInput
                                                            id="province"
                                                            name="province"
                                                            label="Province / State"
                                                            type="text"
                                                            value={formBody.province ? formBody.province : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={6} lg={3}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="postal_code">Postal Code / ZIP</InputLabel>
                                                        <OutlinedInput
                                                            id="postal_code"
                                                            name="postal_code"
                                                            label="Postal Code / ZIP"
                                                            type="text"
                                                            value={formBody.postal_code ? formBody.postal_code : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={6} lg={3}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="country">Country</InputLabel>
                                                        <OutlinedInput
                                                            id="country"
                                                            name="country"
                                                            label="Country"
                                                            type="text"
                                                            value={formBody.country ? formBody.country : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>
                                            </Row>

                                            {/* End of company information section */}
                                            <Divider />

                                            {/* Beginning of contact information section */}
                                            <Row>
                                                {/* Company contact */}
                                                <Col sm={12} lg={6}>
                                                    <SmallFormTitle>Company Contact</SmallFormTitle>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="contact_name">Contact Name</InputLabel>
                                                        <OutlinedInput
                                                            id="contact_name"
                                                            name="contact_name"
                                                            label="Contact Name"
                                                            type="text"
                                                            value={formBody.contact_name ? formBody.contact_name : ""}
                                                        />
                                                    </StyledFormControl>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="title">Title</InputLabel>
                                                        <OutlinedInput
                                                            id="title"
                                                            name="title"
                                                            label="Title"
                                                            type="text"
                                                            value={formBody.title ? formBody.title : ""}
                                                        />
                                                    </StyledFormControl>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="email">Email</InputLabel>
                                                        <OutlinedInput
                                                            id="email"
                                                            name="email"
                                                            label="Email"
                                                            type="email"
                                                            value={formBody.email ? formBody.email : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Groups and styles the phone number related inputs */}
                                                    <Row>
                                                        <Col sm={12} md={2}>
                                                            <StyledFormControl required variant="outlined">
                                                                <InputLabel htmlFor="ind">Ind</InputLabel>
                                                                <OutlinedInput
                                                                    id="ind"
                                                                    name="ind"
                                                                    label="Ind"
                                                                    type="text"
                                                                    value={formBody.ind ? formBody.ind : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>

                                                        <Col sm={12} md={6}>
                                                            <StyledFormControl required variant="outlined">
                                                                <InputLabel htmlFor="phone">Phone</InputLabel>
                                                                <OutlinedInput
                                                                    id="phone"
                                                                    name="phone"
                                                                    label="Phone"
                                                                    type="text"
                                                                    value={formBody.phone ? formBody.phone : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>

                                                        <Col sm={12} md={4}>
                                                            <StyledFormControl variant="outlined">
                                                                <InputLabel htmlFor="ext">Ext</InputLabel>
                                                                <OutlinedInput
                                                                    id="ext"
                                                                    name="ext"
                                                                    label="Ext"
                                                                    type="text"
                                                                    value={formBody.ext ? formBody.ext : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {/* Macrodyne contact */}
                                                <Col sm={12} lg={6}>
                                                    <SmallFormTitle>Macrodyne Contact</SmallFormTitle>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_contact_name">Contact Name</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_contact_name"
                                                            name="macrodyne_contact_name"
                                                            label="Contact Name"
                                                            type="text"
                                                            value={formBody.macrodyne_contact_name ? formBody.macrodyne_contact_name : ""}
                                                        />
                                                    </StyledFormControl>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_title">Title</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_title"
                                                            name="macrodyne_title"
                                                            label="Title"
                                                            type="text"
                                                            value={formBody.macrodyne_title ? formBody.macrodyne_title : ""}
                                                        />
                                                    </StyledFormControl>

                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_email">Email</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_email"
                                                            name="macrodyne_email"
                                                            label="Email"
                                                            type="email"
                                                            value={formBody.macrodyne_email ? formBody.macrodyne_email : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Groups and styles the phone number related inputs */}
                                                    <Row>
                                                        <Col sm={12} md={2}>
                                                            <StyledFormControl required variant="outlined">
                                                                <InputLabel htmlFor="macrodyne_ind">Ind</InputLabel>
                                                                <OutlinedInput
                                                                    id="macrodyne_ind"
                                                                    name="macrodyne_ind"
                                                                    label="Ind"
                                                                    type="text"
                                                                    value={formBody.macrodyne_ind ? formBody.macrodyne_ind : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>

                                                        <Col sm={12} md={6}>
                                                            <StyledFormControl required variant="outlined">
                                                                <InputLabel htmlFor="macrodyne_phone">Phone</InputLabel>
                                                                <OutlinedInput
                                                                    id="macrodyne_phone"
                                                                    name="macrodyne_phone"
                                                                    label="Phone"
                                                                    type="text"
                                                                    value={formBody.macrodyne_phone ? formBody.macrodyne_phone : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>

                                                        <Col sm={12} md={4}>
                                                            <StyledFormControl variant="outlined">
                                                                <InputLabel htmlFor="macrodyne_ext">Ext</InputLabel>
                                                                <OutlinedInput
                                                                    id="macrodyne_ext"
                                                                    name="macrodyne_ext"
                                                                    label="Ext"
                                                                    type="text"
                                                                    value={formBody.macrodyne_ext ? formBody.macrodyne_ext : ""}
                                                                />
                                                            </StyledFormControl>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            {/* End of contact information section */}
                                            <Divider />

                                            <Row>
                                                {/* Notes */}
                                                <Col sm={12}>
                                                    <SmallFormTitle>Notes</SmallFormTitle>
                                                    <StyledFormControl variant="outlined">
                                                        <InputLabel htmlFor="notes">Notes</InputLabel>
                                                        <OutlinedInput
                                                            id="notes"
                                                            name="notes"
                                                            label="Notes"
                                                            type="text"
                                                            multiline
                                                            rows={11}
                                                            value={formBody.notes ? formBody.notes : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>
                                            </Row>

                                            <SubmitButton type="submit">Save</SubmitButton>

                                        </UpdateClientForm>
                                    </>
                                )
            }
        </main>
    )
}

const SubmitButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

const Divider = styled.div`
    background-color: var(--macrodyne-grey);
    height: 1px;
    margin: 25px 0px;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const SmallFormTitle = styled(FormTitle)`
    font-size: 17px;
`

const UpdateClientForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

export default UpdateClient;