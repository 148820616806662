// Styled Components
import styled from "styled-components";

// Mui
import { Alert } from "@mui/material";

// Components
import DialogPopup from "../../DialogPopup";
import SubmitSuccess from "../../Success/SubmitSuccess";
import LoadingMessage from "../../Loading/LoadingMessage";

const ArchiveDialogue = ({ visible, loading, confirm, hide, header, selectedObject }) => {
    return (
        <DialogPopup
            hide={hide}
            header={header}
            visible={visible}
            loading={loading}
            confirm={confirm}
        >
            {
                loading === "loading"
                    ? (
                        <LoadingMessage message={`Archiving ${selectedObject.name || selectedObject.item_number}.`} />
                    )
                    : loading === "success"
                        ? (
                            <SubmitSuccess
                                info={"This window will close automatically."}
                                message={`${selectedObject.name || selectedObject.item_number} has been archived.`}
                            />
                        )
                        : (
                            <Center>
                                <Alert sx={{ marginBottom: "25px", width: "fit-content" }} severity="warning">
                                    Archiving this object will no longer make it appear in this list.
                                </Alert>
                                <p>
                                    Are you sure you want to archive {selectedObject.name || selectedObject.item_number}?
                                </p>
                            </Center>
                        )
            }
        </DialogPopup>
    )
}

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export default ArchiveDialogue