// Core Imports
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// OpenSeaDragon
import OpenSeaDragon from "openseadragon";

// Annotorious-OpenSeaDragon plugin
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';

// Importing the HoverTooltip plugin is necessary even though it's never used.
// I believe the events and functions that manage the tooltip come from the plugin.
import HoverTooltip from "@recogito/annotorious-hover-tooltip";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import {
    Select,
    Button,
    MenuItem,
    InputLabel,
    IconButton,
    FormControl,
} from '@mui/material/';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from '@mui/icons-material/';

// Utils
import generateRandomKey from "../../utils/generateRandomKey";

// Constants
import osdButtons from "../../constants/osdButtons";

// Renders the PDF document into an OpenSeaDragon viewer with 
// an Annotorious viewer layered on top of it, using the Annotorious-OpenSeaDragon plugin
const RenderPdf = ({
    clientId,
    machineId,
    convertedPdf,
    viewMode = false,
    selectedComponent,
    annotationsList = [],
    setAnnotationsList,

    createdAnnotations,
    setCreatedAnnotations,

    updatedAnnotations,
    setUpdatedAnnotations,

    deletedAnnotations,
    setDeletedAnnotations,
}) => {

    const [page, setPage] = useState(0);
    const [anno, setAnno] = useState(null);
    const [viewer, setViewer] = useState(null);

    const navigate = useNavigate();

    // Handles page navigation
    const navigationHandler = (action, pageNumber) => {
        // inspired by https://www.npmjs.com/package/react-paginate
        let newPage;

        switch (action) {
            case "double-left":
                newPage = 0;
                break;

            case "left":
                newPage = page - 1;
                break;

            case "right":
                newPage = page + 1;
                break;

            case "double-right":
                newPage = convertedPdf.length - 1;
                break;

            case "page":
                newPage = Number(pageNumber);
                break;

            case "default":
                break;
        }

        setPage(newPage);
        viewer.goToPage(newPage);
    }

    const createAnnotation = (annotation) => {
        // Keys that can be deleted from the annotation without affecting it
        // ↪ The id key is necessary for annotorious to work.
        // ↪ Since it can't be deleted, it will be used for our model instead.

        delete annotation.target.source; // Contains the image info. Too big to store
        delete annotation["@context"]; // Not sure what this is

        annotation.page = page;
        annotation.componentId = selectedComponent.id;
        annotation.component = selectedComponent;

        hideTooltip(annotation);

        const newAnnotations = [...annotationsList, annotation];
        setAnnotationsList(newAnnotations);
        setCreatedAnnotations([...createdAnnotations, annotation]);
    };

    const updateAnnotation = (annotation, previous) => {

        const newAnnotations = annotationsList.map(val => {
            if (val.id === annotation.id) {
                // deleting the same keys
                delete annotation.target.source;
                delete annotation["@context"];
                return annotation
            }
            return val
        })
        setAnnotationsList(newAnnotations)
        setUpdatedAnnotations([...updatedAnnotations, annotation]);

        // Prevents the tooltip from getting stuck on the page permanently
        hideTooltip(annotation);
    };

    const deleteAnnotation = (annotation) => {
        const newAnnotations = annotationsList.filter(val => val.id !== annotation.id)
        setAnnotationsList(newAnnotations);

        const created = createdAnnotations.find(val => val.id === annotation.id);
        const updated = updatedAnnotations.find(val => val.id === annotation.id);

        if (created) {
            const newCreated = createdAnnotations.filter(val => val.id !== annotation.id);
            setCreatedAnnotations(newCreated);
        }

        if (created && updated) {
            const newUpdated = updatedAnnotations.filter(val => val.id !== annotation.id);
            setUpdatedAnnotations(newUpdated);
        }
        else if (updated) {
            const newUpdated = updatedAnnotations.filter(val => val.id !== annotation.id);
            setUpdatedAnnotations(newUpdated);
            setDeletedAnnotations([...deletedAnnotations, annotation]);
        }

        if (!created && !updated) {
            setDeletedAnnotations([...deletedAnnotations, annotation]);
        }

        // Prevents the tooltip from getting stuck on the page permanently
        hideTooltip(annotation);
    };

    const selectAnnotation = (annotation, shape) => {
        // Prevents the tooltip from getting stuck on the page permanently
        hideTooltip(annotation, shape);
    }

    const clickAnnotation = (annotation, shape) => {
        navigate(`/client/${clientId}/machine/${machineId}/component/${annotation.component.id}`);
    }

    // Hovertooltip section
    // Plugin not adapted for React, code ripped directly from repo and refactored
    // Repo: https://github.com/annotorious/annotorious-v2-plugins
    // Code: https://github.com/annotorious/annotorious-v2-plugins/blob/main/plugins/annotorious-hover-tooltip/src/index.js
    let tooltip = null;

    const onMouseMove = (event) => {
        tooltip.style.top = `${event.offsetY - 12.5}px`;
        tooltip.style.left = `${event.offsetX + 12.5}px`;
    }

    const showTooltip = (annotation, shape) => {

        tooltip = document.createElement('div');
        tooltip.setAttribute('class', 'a9s-hover-tooltip');

        tooltip.style.display = "flex";
        tooltip.style.flexDirection = "column";
        tooltip.style.width = "350px";
        tooltip.style.alignItems = "center";

        const img = document.createElement("img");
        img.src = annotation.component.image;
        img.alt = annotation.component.item_number

        tooltip.innerText = annotation.component.item_number;
        tooltip.appendChild(img);

        anno._element.appendChild(tooltip);
        shape.addEventListener('mousemove', onMouseMove);
    }

    const hideTooltip = (annotation, shape) => {

        /*
            Shape is the annotation that is drawn in the viewer.
            Its value bugs out consistently. Also when updating and deleting an annotation, no shape can be provided.
            Instead, check for all existing annotations and remove the event listener if the current annotation
            is still in the viewer. It's the only consistent way of removing the event listener without errors.

            Calling this function for every update / delete and not just on on mouse leave
            is the only consistent way of preventing the tooltip from glitching and being
            permanently displayed in the viewer.
        */
        const liveAnnotations = Array.from(document.getElementsByClassName("a9s-annotation"));

        // Original solution for hiding the tooltip
        // const appended = liveAnnotations.find((liveAnnotation, index) => {
        //     return liveAnnotation.dataset.id === annotation.id;
        // })

        // if (appended) {
        //     appended.removeEventListener('mousemove', onMouseMove);
        // }

        // New solution for hiding the tooltip
        // ↪ Bug found where tooltip gets stuck on screen if creating an annotation over another
        liveAnnotations.forEach(liveAnnotation => {
            liveAnnotation.removeEventListener("mousemove", onMouseMove);
        })

        if (tooltip) {
            anno._element.removeChild(tooltip);
            tooltip = null;
        }
    }

    const mouseEnterAnnotation = (annotation, shape) => {
        // Necessary to hide on mouseEnter because if an annotation is clicked, it loses the "annotation-hover" class
        // Losing the class causes the tooltip to be glued to the annotation.
        hideTooltip(annotation, shape);
        showTooltip(annotation, shape);
    }

    const mouseLeaveAnnotation = (annotation, shape) => {
        hideTooltip(annotation, shape);
    }

    // create the OSD & Annotorious viewers / destroy them on exit
    useEffect(() => {
        // Just in case 
        //  ↪ viewer and anno shouldn't exist, but as a precaution it's checked for and destroyed
        viewer && viewer.destroy();
        anno && anno.destroy();
        setAnno(null)
        setViewer(null);

        const initViewer = OpenSeaDragon({
            id: "openSeaDragon",
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            autoHideControls: false,
            sequenceMode: true,

            // Settings for buttons to work in deployment:
            // ↪ prefixUrl must be set to nothing
            // ↪ navImages must be manually defined
            prefixUrl: "",
            navImages: {
                zoomIn: {
                    REST: osdButtons.zoomin_rest,
                    HOVER: osdButtons.zoomin_hover,
                    DOWN: osdButtons.zoomin_pressed,
                    GROUP: osdButtons.zoomin_grouphover,
                },
                zoomOut: {
                    REST: osdButtons.zoomout_rest,
                    HOVER: osdButtons.zoomout_hover,
                    DOWN: osdButtons.zoomout_pressed,
                    GROUP: osdButtons.zoomout_grouphover,
                },
                home: {
                    REST: osdButtons.home_rest,
                    HOVER: osdButtons.home_hover,
                    DOWN: osdButtons.home_pressed,
                    GROUP: osdButtons.home_grouphover,
                },
                fullpage: {
                    REST: osdButtons.fullpage_rest,
                    HOVER: osdButtons.fullpage_hover,
                    DOWN: osdButtons.fullpage_pressed,
                    GROUP: osdButtons.fullpage_grouphover,
                },
                previous: {
                    REST: osdButtons.previous_rest,
                    HOVER: osdButtons.previous_hover,
                    DOWN: osdButtons.previous_pressed,
                    GROUP: osdButtons.previous_grouphover,
                },
                next: {
                    REST: osdButtons.next_rest,
                    HOVER: osdButtons.next_hover,
                    DOWN: osdButtons.next_pressed,
                    GROUP: osdButtons.next_grouphover,
                },
            },

            // Diables zoom on click without disabling the zoom buttons
            gestureSettingsMouse: { clickToZoom: false },
        })

        setViewer(initViewer);

        const config = { readOnly: viewMode, disableEditor: true, allowEmpty: true, disableSelect: viewMode, }
        const annoInstance = Annotorious(initViewer, config);

        setAnno(annoInstance);

        // Destroy both viewers on exit, just in case.
        return () => {
            viewer && viewer.destroy();
            anno && anno.destroy();
        };
    }, []);

    // Loads the images into OSD
    useEffect(() => {
        // Creating the OSD viewer is very slow
        //  ↪ Loading the image needs to wait until the OSD viewer is fully created
        if (viewer) {
            viewer.open(convertedPdf);
        }
    }, [convertedPdf, viewer]);

    // Loads existing annotations
    useEffect(() => {
        // Creating the annotation viewer is very slow
        //  ↪ Loading the annotations needs to wait until the annotation viewer is fully created
        if (anno) {

            // Check for annotations
            if (annotationsList.length) {

                // Filter for annotations of the current page only
                const currentPageAnnotations = annotationsList.filter((annotation) => {
                    return annotation.page === page;
                });
                anno.setAnnotations(currentPageAnnotations);

            }
            else {
                // if no annotations for current image, set to empty or it will show up on every image
                anno.setAnnotations([]);
            }
        }
    }, [convertedPdf, anno, page])

    // Event subscription and unsubscription handled here to avoid closure
    useEffect(() => {

        // Handles page naviation event
        if (viewer) {
            viewer.addHandler('page', (event) => {
                setPage(event.page);
            });
        }

        // Only subscribe to the annotation events if not in view mode
        if (anno && !viewMode) {
            anno.on('createAnnotation', createAnnotation);

            anno.on('updateAnnotation', updateAnnotation);

            anno.on('deleteAnnotation', deleteAnnotation);

            anno.on('selectAnnotation', selectAnnotation);
        }
        // Only subscribe to click event if in view mdoe
        // ↪ click event is used to redirect the user to the component page
        else if (anno && viewMode) {
            anno.on('clickAnnotation', clickAnnotation);
        }

        // Tooltip events are always required
        if (anno) {
            anno.on('mouseEnterAnnotation', mouseEnterAnnotation);

            anno.on('mouseLeaveAnnotation', mouseLeaveAnnotation);
        }

        return () => {

            if (viewer) {
                viewer.removeHandler('page', (event) => {
                    setPage(event.page);
                });
            }

            if (anno && !viewMode && selectedComponent) {
                anno.off('createAnnotation', createAnnotation);

                anno.off('updateAnnotation', updateAnnotation);

                anno.off('deleteAnnotation', deleteAnnotation);

                anno.off('selectAnnotation', selectAnnotation);
            }
            else if (anno && viewMode) {
                anno.off('clickAnnotation', clickAnnotation);
            }

            if (anno) {
                anno.off('mouseEnterAnnotation', mouseEnterAnnotation);

                anno.off('mouseLeaveAnnotation', mouseLeaveAnnotation);
            }
        }
    }, [convertedPdf, anno, annotationsList, page, viewMode, selectedComponent, createdAnnotations, updatedAnnotations, deletedAnnotations])

    return (
        <>
            {/* 
                The id must match the one provided to the OpenSeaDragon viewer instance.
                The height CSS property is required.
                The div can either have inline style or styles added with a class and the "!important" property
                    ↪ styled components automatically apply their styles through a class
            */}
            <OsdContainer id="openSeaDragon" />

            {/* Page Navigation */}
            {
                // Only shows the page navigation buttons if there's more than one page
                convertedPdf.length > 1 && (
                    <>
                        <NavigationContainer className="column-when-small">

                            {/* Pagination & Navigation */}
                            <PageNavigation className="column-when-small">

                                {/* Left Arrows Navigation */}
                                <LeftArrowsContainer className="my-2 my-md-0">
                                    <ArrowNavigation
                                        onClick={() => navigationHandler("double-left")}
                                        disabled={page === 0}
                                    >
                                        <KeyboardDoubleArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    <ArrowNavigation
                                        onClick={() => navigationHandler("left")}
                                        disabled={page === 0}
                                    >
                                        <KeyboardArrowLeft sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </LeftArrowsContainer>

                                {/* Page Numbers Navigation */}
                                <PageNumberContainer>

                                    {/* 
                                        Shows all page buttons if the PDF document has 5 pages or less.
                                        If the document has more than 5 pages, only shows the first and last 2 pages
                                        and an input field to jump to a specific page. 
                                    */}
                                    {
                                        convertedPdf.length <= 5
                                            ? convertedPdf.map((element, index) => {
                                                return (
                                                    <NavigationButtons
                                                        value={index}
                                                        variant="contained"
                                                        key={generateRandomKey()}
                                                        className={index === page ? "active" : ""}
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                    >
                                                        {index + 1}
                                                    </NavigationButtons>
                                                )
                                            })
                                            : (
                                                <>
                                                    {/* First 2 pages */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={0}
                                                        key={generateRandomKey()}
                                                        className={page === 0 ? "active" : ""}
                                                    >
                                                        1
                                                    </NavigationButtons>
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={1}
                                                        key={generateRandomKey()}
                                                        className={page === 1 ? "active" : ""}
                                                    >
                                                        2
                                                    </NavigationButtons>

                                                    {/* Manual page selection */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="page">Page</InputLabel>
                                                        <StyledSelect
                                                            id="page"
                                                            name="page"
                                                            label="Page"
                                                            value={page}
                                                            onChange={(event) => navigationHandler("page", event.target.value)}
                                                        >
                                                            {
                                                                convertedPdf.map((element, index) => {
                                                                    return (
                                                                        <MenuItem value={index}>{index + 1}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </StyledSelect>
                                                    </StyledFormControl>

                                                    {/* Last 2 pages */}
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={convertedPdf.length - 2}
                                                        key={generateRandomKey()}
                                                        className={page === convertedPdf.length - 2 ? "active" : ""}
                                                    >
                                                        {convertedPdf.length - 1}
                                                    </NavigationButtons>
                                                    <NavigationButtons
                                                        variant="contained"
                                                        onClick={(event) => navigationHandler("page", event.target.value)}
                                                        value={convertedPdf.length - 1}
                                                        key={generateRandomKey()}
                                                        className={page === convertedPdf.length - 1 ? "active" : ""}
                                                    >
                                                        {convertedPdf.length}
                                                    </NavigationButtons>
                                                </>
                                            )
                                    }
                                </PageNumberContainer>

                                {/* Right Arrows Navigation */}
                                <div className="my-2 my-md-0">
                                    <ArrowNavigation
                                        onClick={() => navigationHandler("right")}
                                        disabled={page === convertedPdf.length - 1}
                                    >
                                        <KeyboardArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>

                                    <ArrowNavigation
                                        onClick={() => navigationHandler("double-right")}
                                        disabled={page === convertedPdf.length - 1}
                                    >
                                        <KeyboardDoubleArrowRight sx={{ fontSize: "32px" }} />
                                    </ArrowNavigation>
                                </div>
                            </PageNavigation>

                        </NavigationContainer>
                    </>
                )
            }
        </>
    );
}

const OsdContainer = styled.div`
    margin: 0 !important;
    width: 100% !important;
    height: 1000px !important;
    border: solid 3px black !important;

    @media screen and (max-width: 1024px) {
        height: 750px !important;
    }

    @media screen and (max-width: 780px) {
        height: 500px !important;
    }
`

const LeftArrowsContainer = styled.div`
    margin-right: 5px;
`

const PageNumberContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const PageNavigation = styled.div`
    display: flex;
    align-items: center;
`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
    position: relative;
    top: 0;
    left: 0;

    .active { 
        color: white;
        background-color: var(--macrodyne-blue);
    }
`

const NavigationButtons = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    fontWeight: "bold",
    marginRight: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const ArrowNavigation = muiStyle(IconButton)({
    borderRadius: "5px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledFormControl = muiStyle(FormControl)({
    backgroundColor: "var(--macrodyne-light-grey)",
    marginRight: "5px",
})

const StyledSelect = muiStyle(Select)({
    minWidth: "65px",
})

export default RenderPdf;