// Core Imports
import { createContext, useState } from "react";

// Cookie Handling
import CryptoJS from 'crypto-js';
import Cookies from "universal-cookie";

// Expiration time set to an hour
const COOKIE_EXPIRATION = 3600;

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {

    // Declare cookie instance
    const cookies = new Cookies();

    // User is set to the cookie if it exists, null if not
    const [user, setUser] = useState(() => {
        const storedCookie = cookies.get("user");

        if (storedCookie) {
            const decryptedCookie = JSON.parse(CryptoJS.AES.decrypt(storedCookie, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));
            return decryptedCookie
                ? {
                    name: decryptedCookie.name,
                    first_name: decryptedCookie.first_name,
                    last_name: decryptedCookie.last_name,
                    email: decryptedCookie.email,
                    position: decryptedCookie.position,
                    id: decryptedCookie.id
                }
                : null;
        }
        else {
            return null
        }
    });

    // Used to notify the user that their login session has expired
    const [expiredUserCookie, setExpiredUserCookie] = useState(false);

    // Used to redirect the user off the current page
    const [redirectUser, setRedirectUser] = useState(false);

    // Function to login the user by setting the global state and the cookie
    const login = (data) => {
        setExpiredUserCookie(false);

        // more keys to come?
        setUser({
            name: data.name,
            email: data.email,
            position: data.position,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
        });

        // Encrypting the data
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPTION_KEY).toString();

        // Storing the data as a cookie
        cookies.set("user", encryptedData, {
            path: "/",
            maxAge: COOKIE_EXPIRATION,
        });
    }

    // Function to logout the user by nulling the global state and removing the cookie
    const logout = () => {
        setUser(null);
        cookies.remove("user");
    }

    return (
        <UserContext.Provider value={{
            user,
            setUser,
            login,
            logout,
            expiredUserCookie,
            setExpiredUserCookie,
            redirectUser,
            setRedirectUser,
            COOKIE_EXPIRATION,
        }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;