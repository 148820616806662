// Core Imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Error from "../../components/Errors/Error";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DataTableDisplay from "../../components/DataTable/DataTableDisplay";

// MUI
import { Button, Alert } from '@mui/material';
import { styled as muiStyle } from "@mui/material/styles";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

const ListClients = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [updateClients, setUpdateClients] = useState(true);

    // Fetches all the clients
    const [clients, loading, error] = useGet("/api/clients/regular/", updateClients);

    const navigate = useNavigate();

    return (
        <section>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? <Error error={error} />
                        : (
                            <>
                                <AddClientButton
                                    variant="contained"
                                    type="button"
                                    onClick={() => navigate("/client/add")}
                                >
                                    Add Client
                                </AddClientButton>
                                {
                                    clients.length
                                        ? (
                                            <DataTableDisplay
                                                data={clients}
                                                viewUrl={"/client"}
                                                editUrl={"/client"}
                                                deleteUrl={"/api/clients/archive"}
                                                search
                                                striped
                                                refreshData={updateClients}
                                                setRefreshData={setUpdateClients}
                                            />
                                        )
                                        : <Alert severity="info">There are no registered clients on the website yet.</Alert>
                                }
                            </>
                        )
            }
        </section >
    )
}

const AddClientButton = muiStyle(Button)({
    position: "absolute",
    top: "17px",
    right: "25px",
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default ListClients