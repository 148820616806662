// Core Imports
import styled from "styled-components";

// Prime React
import { Dialog } from 'primereact/dialog';

// MUI
import { Button } from '@mui/material/';
import { Check, Cancel } from '@mui/icons-material/';
import { styled as muiStyle } from "@mui/material/styles";

const DialogPopup = ({ children, visible, hide, header, confirm, loading }) => {

    // Dialog footer
    // ↪ Prime react requires it this way... Not ideal.
    const footerContent = () => {

        return (
            loading === "idle" && (
                <DialogFooter >

                    {/* Cancel */}
                    <DialogButton startIcon={<Cancel />} onClick={hide}>Cancel</DialogButton>

                    {/* Confirm */}
                    <DialogButton startIcon={<Check />} onClick={confirm}>Confirm</DialogButton>

                </DialogFooter >
            )
        )
    };

    return (
        <Dialog
            onHide={hide}
            header={header}
            visible={visible}
            footer={footerContent}
            style={{ width: "100%", maxWidth: "800px", height: "70vh" }}
        >
            {children}
        </Dialog>
    )
}

const DialogFooter = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 0px 25px;
`
const DialogButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default DialogPopup;