// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// Components
import BreadcrumbsNav from "../../../components/BreadcrumbNav";
import SubmitSuccess from "../../../components/Success/SubmitSuccess";
import LoadingMessage from "../../../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Alert,
    Button,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
    FormControl,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';

// Custom Hooks
import useGet from "../../../hooks/useGet";
import useRedirectToLogin from "../../../hooks/useRedirectToLogin";
import useVerifyUserAccess from "../../../hooks/useVerifyUserAccess";
import useValidateUserCookie from "../../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../../utils/getUserToken";
import convertToFormData from "../../../utils/convertToFormData";
import showAdminFeatures from "../../../utils/showAdminFeatures";

const AddUser = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions(true);

    const navigate = useNavigate();
    const { clientId } = useParams();

    // Verify user access
    useVerifyUserAccess(clientId);

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");

    // New user is not manager by default
    const [formBody, setFormBody] = useState({ is_manager: false });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value,
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        // Quick password check.
        if (formBody.password.length < 8) {
            setError("Password is too short. Please use a minimum of 8 characters.")
        }
        else if (formBody.password !== formBody.confirm_password) {
            setError("Passwords do not match!");
        }
        else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formBody.username)) {
            setError("Invalid email address format.");
        }
        else {
            setStatus("sending");
            
            // Company cannot be modified, passing it manually to the converter.
            const formData = convertToFormData({ ...formBody, company: clientId });

            axios.post("/api/users/add/", formData, config)
                .then(res => {
                    setStatus("success");

                    setTimeout(() => {
                        navigate(`/client/${clientId}/users`);
                    }, 1500)
                })
                .catch(err => {
                    setStatus("idle");
                    setError(err.response.data.error);

                    // Remove the passwords from the form for safety
                    const formCopy = { ...formBody };
                    delete formCopy.password;
                    delete formCopy.confirm_password;
                    setFormBody({ ...formCopy });
                })
        }
    }

    return (
        <main>
            {
                status === "sending"
                    ? <LoadingMessage message={"Adding user."} />
                    : status === "success"
                        ? <SubmitSuccess message={"User added successfully!"} />
                        : (
                            <>
                                {/* Breadcrumbs */}
                                {
                                    showAdminFeatures()
                                        ? (
                                            <BreadcrumbsNav
                                                links={[
                                                    "dashboard",
                                                    { name: clientName, value: clientId, url: "client" },
                                                    { name: "users", value: "users", url: `client/${clientId}` },
                                                    "add user"
                                                ]}
                                            />
                                        )
                                        : (
                                            <BreadcrumbsNav
                                                links={[
                                                    { name: clientName, value: clientId, url: "client" },
                                                    { name: "users", value: "users", url: `client/${clientId}` },
                                                    "add user"
                                                ]}
                                            />
                                        )
                                }

                                <FormContainer>
                                    <AddUserForm
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}
                                        className="mx-3 mx-md-0 my-5 p-md-5"
                                    >

                                        <h2>Add a user</h2>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="first_name">First Name</InputLabel>
                                            <OutlinedInput
                                                id="first_name"
                                                name="first_name"
                                                label="First Name"
                                                type="text"
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="last_name">Last Name</InputLabel>
                                            <OutlinedInput
                                                id="last_name"
                                                name="last_name"
                                                label="Last Name"
                                                type="text"
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            {/* 
                                                Django only accepts the username and password for validation. 
                                                The username was changed to match the email in the DB.
                                                The label shows `email` but the information must be sent under `username` or validation will fail.
                                            */}
                                            <InputLabel htmlFor="username">Email</InputLabel>
                                            <OutlinedInput
                                                id="username"
                                                name="username"
                                                label="Email"
                                                type="email"
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="position">Position</InputLabel>
                                            <OutlinedInput
                                                id="position"
                                                name="position"
                                                label="Position"
                                                type="text"
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="password">Password</InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                name="password"
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword((show) => !show)}
                                                            edge="start"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="confirm_password"
                                                name="confirm_password"
                                                label="Confirm Password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowConfirmPassword((show) => !show)}
                                                            edge="start"
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        <FormControl required variant="outlined" fullWidth sx={{ marginBottom: "20px" }}>
                                            <InputLabel htmlFor="is_manager">Permissions</InputLabel>
                                            <Select
                                                name="is_manager"
                                                id="is_manager"
                                                label="Permissions"
                                                value={formBody.is_manager}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={false}>Standard</MenuItem>
                                                <MenuItem value={true}>Manager</MenuItem>
                                            </Select>
                                        </FormControl>

                                        {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                        <AddUserButton variant="contained" type="submit">
                                            Add User
                                        </AddUserButton>

                                    </AddUserForm>
                                </FormContainer>
                            </>
                        )
            }
        </main>
    )
}

const AddUserForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    border: solid var(--macrodyne-grey) 1px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const AddUserButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default AddUser;