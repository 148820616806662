// Core Imports
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Components
import RenderPdf from "../../components/PDF/RenderPdf";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import PdfLoadingBar from "../../components/Loading/PdfLoadingBar";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useVerifyUserAccess from "../../hooks/useVerifyUserAccess";
import useConvertExistingPdf from "../../hooks/useConvertExistingPdf";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Tooltip, Button, IconButton } from '@mui/material';
import { Edit, DownloadForOffline } from '@mui/icons-material/';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Utils
import showAdminFeatures from "../../utils/showAdminFeatures";

// Assets
import osdButtons from "../../constants/osdButtons";

const ViewSchematic = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();

    // Initialize hooks
    const navigate = useNavigate();
    const { clientId, machineId, schematicId } = useParams();

    // Verify user access
    useVerifyUserAccess(clientId);

    // Get names for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Get the schematic
    const [schematic, loading, error] = useGet(`/api/schematics/${clientId}/${machineId}/${schematicId}/`);

    // Will hold the list of annotations of the schematic
    const [annotationsList, setAnnotationsList] = useState([]);

    // Sets the annotations of the schematic
    useEffect(() => {
        if (schematic) {
            setAnnotationsList(schematic.annotations);
        }
    }, [schematic])

    // Converts the PDF file into an array of images to be displayed
    const [convertedPdf, convertLoading, currentPage, numPages] = useConvertExistingPdf(schematic);

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? (
                            <RedirectUser error={error} />
                        )
                        : schematic && (
                            <>
                                <TopContainer>
                                    {/* Breadcrumbs */}
                                    {
                                        showAdminFeatures()
                                            ? (
                                                <BreadcrumbsNav
                                                    links={[
                                                        "dashboard",
                                                        { name: clientName, value: clientId, url: "client" },
                                                        { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                                        schematic.name
                                                    ]}
                                                />
                                            )
                                            : (
                                                <BreadcrumbsNav
                                                    links={[
                                                        { name: clientName, value: clientId, url: "client" },
                                                        { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                                        schematic.name
                                                    ]}
                                                />
                                            )
                                    }
                                    {
                                        showAdminFeatures() && (
                                            <UpdateAnnotationsButton
                                                variant="contained"
                                                type="button"
                                                onClick={() => navigate(`/client/${clientId}/machine/${machineId}/schematic/${schematic.id}/annotate`)}
                                            >
                                                Update Annotations
                                            </UpdateAnnotationsButton>
                                        )
                                    }
                                </TopContainer>
                                <Row>
                                    {/* Schematic information */}
                                    <Col sm={12} lg={3}>
                                        <SchematicInfo>
                                            <h2>{schematic.name}</h2>

                                            <Info>
                                                <span>Last Updated:</span>
                                                <span>{schematic.updated}</span>
                                            </Info>
                                            <Info>
                                                <span>By:</span>
                                                <span>{schematic.username}</span>
                                            </Info>

                                            <h3>Notes</h3>

                                            <Info>
                                                {
                                                    schematic.notes
                                                        ? <p>{schematic.notes}</p>
                                                        : <p>No notes provided.</p>
                                                }
                                            </Info>

                                            {/* Edit Button */}
                                            {
                                                showAdminFeatures() && (
                                                    <Tooltip title={`Update ${schematic.name}`} placement="top">
                                                        <EditButton
                                                            onClick={() => navigate(`/client/${clientId}/machine/${machineId}/schematic/${schematic.id}/update`)}
                                                        >
                                                            <Edit />
                                                        </EditButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </SchematicInfo>
                                        <a href={schematic.file} download={schematic.file} target="_blank">
                                            <DownloadButton>
                                                Download PDF <DownloadForOffline />
                                            </DownloadButton>
                                        </a>

                                        {/* Schematic Instructions */}
                                        <SchematicInstructions>
                                            <h2>Instructions</h2>
                                            <ul>
                                                <Li><Bold>Zoom</Bold>: to zoom in and out, use the scroll wheel on your mouse or the zoom in and zoom out buttons <img src={osdButtons.zoomin_rest} alt="zoomin" /> / <img src={osdButtons.zoomout_rest} alt="zoomout" />.</Li>
                                                <Li><Bold>Reset Zoom</Bold>: to reset the zoom, click the home button <img src={osdButtons.home_grouphover} alt="home" />.</Li>
                                                <Li><Bold>Fullcreen</Bold>: to go into fullscreen mode, click the fullscreen button <img src={osdButtons.fullpage_rest} alt="rest" />. Press the button again, or the <Bold>ESC</Bold> button on your keyboard to exit fullscreen mode.</Li>
                                                <Li><Bold>Pan</Bold>: to pan the view, click and hold the mouse button in the viewer, then drag your mouse.</Li>
                                            </ul>
                                        </SchematicInstructions>
                                    </Col>

                                    {/* PDF Preview */}
                                    <Col sm={12} lg={9}>
                                        {
                                            convertLoading
                                                ? (
                                                    <PdfLoadingBar currentPage={currentPage} numPages={numPages} />
                                                )
                                                : convertedPdf && (
                                                    <RenderPdf
                                                        viewMode
                                                        clientId={clientId}
                                                        machineId={machineId}
                                                        convertedPdf={convertedPdf}
                                                        annotationsList={annotationsList}
                                                        setAnnotationsList={setAnnotationsList}
                                                    />
                                                )
                                        }
                                    </Col>
                                </Row>
                            </>
                        )
            }
        </main>
    )
}

const Info = styled.div`
    display: flex;
    justify-content: space-between;

    &:first-of-type {
        margin-top: 25px;
    }
`

const SchematicInfo = styled.div`
    background-color: var(--macrodyne-light-grey);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;

    h3 {
        margin-top: 25px;
    }
`

const SchematicInstructions = styled(SchematicInfo)`
    padding: 10px 25px;
    img {
        display: inline;
    }
`

const Li = styled.li`
    margin-bottom: 10px;
`

const Bold = styled.span`
    font-weight: bold;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const EditButton = muiStyle(IconButton)({
    position: "absolute",
    top: "25px",
    right: "25px",
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    padding: "5px",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Template
const StyledButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Specific names
const UpdateAnnotationsButton = muiStyle(StyledButton)({})
const DownloadButton = muiStyle(StyledButton)({
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
})

export default ViewSchematic;