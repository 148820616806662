// Core Imports
import styled from "styled-components";
import { Link } from "react-router-dom";

// MUI
import { Alert } from "@mui/material";
import { styled as muiStyle } from "@mui/material/styles";

const NotFound = () => {
    return (
        <AlertMain>
            <StyledAlert severity="warning">
                Page not found! Go back <Link to="/">home</Link>.
            </StyledAlert>
        </AlertMain>
    )
}

const StyledAlert = muiStyle(Alert)({
    display: "flex", 
    justifyContent: 
    "center", alignItems: 
    "center", 
    fontSize: "22px"
})

const AlertMain = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
`

export default NotFound;