// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import LoadingMessage from "../../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import {
    HideImage,
    VideoCall,
    Backspace,
    UploadFile,
    VideocamOff,
    InsertPhoto,
} from '@mui/icons-material';
import {
    Alert,
    Button,
    Select,
    Tooltip,
    MenuItem,
    IconButton,
    InputLabel,
    FormControl,
    OutlinedInput,
} from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";
import RedirectUser from "../../components/Errors/RedirectUser";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const UpdateComponent = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});

    // The attachment uploads
    const [imageError, setImageError] = useState("");
    const [videoError, setVideoError] = useState("");
    const [extraError, setExtraError] = useState("");

    // Used for accessing the attachment uploads
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const extraRef = useRef(null);

    const navigate = useNavigate();
    const { clientId, machineId, componentId } = useParams();

    // Get names for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Get information to pre-load form
    const [component, loading, error] = useGet(`/api/components/${clientId}/${machineId}/${componentId}/`);

    // Fetch the component
    useEffect(() => {
        if (component) {
            const componentCopy = { ...component };
            delete componentCopy.machine;
            delete componentCopy.id;

            let imageFile;
            let videoFile;
            let extraFile;

            // Pre-set the existing files into the formBody
            // Source: https://pqina.nl/blog/set-value-to-file-input/

            // Image
            if (component.image) {
                const imageName = component.image.split("/")[component.image.split("/").length - 1]

                imageFile = new File([component.image], imageName, {
                    type: "image/*",
                });

                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(imageFile);
                imageRef.current.files = dataTransfer.files;
            }

            // Video
            if (component.video) {
                const videoName = component.video.split("/")[component.video.split("/").length - 1]

                videoFile = new File([component.video], videoName, {
                    type: "video/*",
                });

                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(videoFile);
                videoRef.current.files = dataTransfer.files;
            }

            // Extra
            if (component.extra) {
                const extraName = component.extra.split("/")[component.extra.split("/").length - 1]

                extraFile = new File([component.extra], extraName, {
                    type: "application/pdf",
                });

                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(extraFile);
                extraRef.current.files = dataTransfer.files;
            }

            setFormBody({
                ...formBody,
                ...componentCopy,
                image: imageFile,
                video: videoFile,
                extra: extraFile,
                imageUrl: componentCopy.image,
                videoUrl: componentCopy.video,
                extraUrl: componentCopy.extra,
            })
        }
    }, [component])

    // Removes the attachment from the form
    const handleRemoveAttachment = (attachment) => {

        // Remove the current value of the file upload input
        switch (attachment) {
            case "image":
                imageRef.current.value = "";
                break;

            case "video":
                videoRef.current.value = "";
                break;

            case "extra":
                extraRef.current.value = "";
                break;
        }

        // Delete the information from the formBody
        const copiedFormBody = { ...formBody };
        delete copiedFormBody[attachment];
        delete copiedFormBody[`${attachment}Url`];
        setFormBody(copiedFormBody)
    }

    // Handles form changes
    const handleChange = (event) => {

        // Check the length of the files in case the user pressed cancel
        if (event.target.type === "file" && event.target.files.length) {
            switch (event.target.name) {
                case "image":
                    // Reset the error on change
                    setImageError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[0] === "image") {
                        const copiedFormBody = { ...formBody };
                        delete copiedFormBody.imageUrl;

                        setFormBody({
                            ...copiedFormBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setImageError("Please upload a valid image file.");
                        handleRemoveAttachment("image");
                    }
                    break;

                case "video":
                    // Reset the error on change
                    setVideoError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[0] === "video"
                        && event.target.files[0].type.split("/")[1] === "mp4"
                    ) {
                        const copiedFormBody = { ...formBody };
                        delete copiedFormBody.videoUrl;

                        setFormBody({
                            ...copiedFormBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setVideoError("Please upload a valid video file.");
                        handleRemoveAttachment("video");
                    }
                    break;

                case "extra":
                    // Reset the error on change
                    setExtraError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[1] === "pdf") {
                        const copiedFormBody = { ...formBody };
                        delete copiedFormBody.extraUrl;

                        setFormBody({
                            ...copiedFormBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setExtraError("Please upload a valid PDF file.");
                        handleRemoveAttachment("video");
                    }

                    break;
            }
        }
        else if (event.target.type !== "file") {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
            });
        }
    }

    // Submits the form
    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.put(`/api/components/update/${component.id}/`, formData, config)
            .then(res => {
                setStatus("success");

                setTimeout(() => {
                    navigate(`/client/${clientId}/machine/${machineId}/component/${res.data.id}`);
                }, 1500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                loading
                    ? <LoadingSpinner />
                    : error
                        ? <RedirectUser error={error} />
                        : status === "sending"
                            ? <LoadingMessage message={"Updating component information"} />
                            : status === "success"
                                ? <SubmitSuccess message={"Component updated successfully!"} />
                                : component && (
                                    <>
                                        <BreadcrumbsNav links={[
                                            "dashboard",
                                            { name: clientName, value: clientId, url: "client" },
                                            { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                            { name: component.item_number, value: component.id, url: `client/${clientId}/machine/${machineId}/component` },
                                            "update component",
                                        ]} />

                                        <UpdateComponentForm onSubmit={handleSubmit} onChange={handleChange}>
                                            <Row>
                                                <FormTitle>Update Component</FormTitle>

                                                {/* Left side of the form */}
                                                <Col sm={12} lg={6}>

                                                    {/* Item Number */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="item_number">Item Number</InputLabel>
                                                        <OutlinedInput
                                                            id="item_number"
                                                            name="item_number"
                                                            label="Item Number"
                                                            type="text"
                                                            value={formBody.item_number ? formBody.item_number : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Stock Number */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="stock_number">Stock Number</InputLabel>
                                                        <OutlinedInput
                                                            id="stock_number"
                                                            name="stock_number"
                                                            label="Stock Number"
                                                            type="text"
                                                            value={formBody.stock_number ? formBody.stock_number : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Part Name */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="part_name">Part Name</InputLabel>
                                                        <OutlinedInput
                                                            id="part_name"
                                                            name="part_name"
                                                            label="Part Name"
                                                            type="text"
                                                            value={formBody.part_name ? formBody.part_name : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Part Number */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="part_number">Part Number</InputLabel>
                                                        <OutlinedInput
                                                            id="part_number"
                                                            name="part_number"
                                                            label="Part Number"
                                                            type="text"
                                                            value={formBody.part_number ? formBody.part_number : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Electrical Address */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="electrical_address">Electrical Address</InputLabel>
                                                        <OutlinedInput
                                                            id="electrical_address"
                                                            name="electrical_address"
                                                            label="Electrical Address"
                                                            type="text"
                                                            value={formBody.electrical_address ? formBody.electrical_address : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Component Application */}
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="application">Component Application</InputLabel>
                                                        <OutlinedInput
                                                            id="application"
                                                            name="application"
                                                            label="Component Application"
                                                            type="text"
                                                            value={formBody.application ? formBody.application : ""}
                                                        />
                                                    </StyledFormControl>

                                                    {/* Component Type */}
                                                    <StyledFormControl required variant="outlined" fullWidth>
                                                        <InputLabel htmlFor="type">Type</InputLabel>
                                                        <Select
                                                            id="type"
                                                            name="type"
                                                            label="Type"
                                                            onChange={handleChange}
                                                            value={formBody.type ? formBody.type : ""}
                                                        >
                                                            <MenuItem value={"Hydraulic"}>Hydraulic</MenuItem>
                                                            <MenuItem value={"Electrical"}>Electrical</MenuItem>
                                                            <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
                                                        </Select>
                                                    </StyledFormControl>

                                                    {/* Description */}
                                                    <StyledFormControl variant="outlined">
                                                        <InputLabel htmlFor="description">Description</InputLabel>
                                                        <OutlinedInput
                                                            id="description"
                                                            name="description"
                                                            label="Description"
                                                            type="text"
                                                            multiline
                                                            rows={5}
                                                            value={formBody.description ? formBody.description : ""}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                {/* Right side of the form */}
                                                <Col sm={12} lg={6}>

                                                    {/* Safety Notes */}
                                                    <SmallFormTitle>Safety Notes</SmallFormTitle>
                                                    <StyledFormControl variant="outlined">
                                                        <InputLabel htmlFor="notes">Notes</InputLabel>
                                                        <OutlinedInput
                                                            id="notes"
                                                            name="notes"
                                                            label="Notes"
                                                            type="text"
                                                            multiline
                                                            rows={8}
                                                            value={formBody.notes ? formBody.notes : ""}
                                                        />
                                                    </StyledFormControl>

                                                    <Divider />

                                                    {/* Uploads */}
                                                    <SmallFormTitle>Attachments</SmallFormTitle>

                                                    {/* Image Upload */}
                                                    <UploadContainer>
                                                        {imageError && <StyledAlert severity="error">{imageError}</StyledAlert>}

                                                        <UploadedFile>
                                                            <UploadFormControl variant="outlined" sx={{ width: formBody.image ? "85%" : "90%" }}>
                                                                <InputLabel htmlFor="image">Image</InputLabel>
                                                                <StyledOutlinedInput
                                                                    id="image"
                                                                    name="image"
                                                                    label="Image"
                                                                    type="text"
                                                                    disabled
                                                                    value={
                                                                        formBody.imageUrl
                                                                            ? formBody.imageUrl.split("/")[formBody.imageUrl.split("/").length - 1]
                                                                            : formBody.image
                                                                                ? formBody.image.name
                                                                                : ""
                                                                    }
                                                                />
                                                            </UploadFormControl>

                                                            <Tooltip title="Upload image" placement="top">
                                                                <UploadAttachment component="label">
                                                                    <InsertPhoto />
                                                                    <input type="file" ref={imageRef} name="image" accept="image/*" />
                                                                </UploadAttachment>
                                                            </Tooltip>

                                                            {
                                                                formBody.image && (
                                                                    <Tooltip title="Remove image" placement="top">
                                                                        <RemoveAttachment onClick={() => handleRemoveAttachment("image")}>
                                                                            <HideImage />
                                                                        </RemoveAttachment>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </UploadedFile>
                                                    </UploadContainer>

                                                    {/* Video Upload */}
                                                    <UploadContainer>
                                                        {videoError && <StyledAlert severity="error">{videoError}</StyledAlert>}

                                                        <UploadedFile>
                                                            <UploadFormControl variant="outlined" sx={{ width: formBody.video ? "85%" : "90%" }}>
                                                                <InputLabel htmlFor="video">Video (mp4)</InputLabel>
                                                                <StyledOutlinedInput
                                                                    id="video"
                                                                    name="video"
                                                                    label="Video (mp4)"
                                                                    type="text"
                                                                    disabled
                                                                    value={
                                                                        formBody.videoUrl
                                                                            ? formBody.videoUrl.split("/")[formBody.videoUrl.split("/").length - 1]
                                                                            : formBody.video
                                                                                ? formBody.video.name
                                                                                : ""
                                                                    }
                                                                />
                                                            </UploadFormControl>

                                                            <Tooltip title="Upload video" placement="top">
                                                                <UploadAttachment component="label">
                                                                    <VideoCall />
                                                                    <input type="file" ref={videoRef} name="video" accept="video/*" />
                                                                </UploadAttachment>
                                                            </Tooltip>

                                                            {
                                                                formBody.video && (
                                                                    <Tooltip title="Remove video" placement="top">
                                                                        <RemoveAttachment onClick={() => handleRemoveAttachment("video")}>
                                                                            <VideocamOff />
                                                                        </RemoveAttachment>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </UploadedFile>
                                                    </UploadContainer>

                                                    {/* Extra File Upload */}
                                                    <UploadContainer>
                                                        {extraError && <StyledAlert severity="error">{extraError}</StyledAlert>}

                                                        <UploadedFile>
                                                            <UploadFormControl variant="outlined" sx={{ width: formBody.extra ? "85%" : "90%" }}>
                                                                <InputLabel htmlFor="extra">Extra File (PDF)</InputLabel>
                                                                <StyledOutlinedInput
                                                                    id="extra"
                                                                    name="extra"
                                                                    label="Extra File (PDF)"
                                                                    type="text"
                                                                    disabled
                                                                    value={
                                                                        formBody.extraUrl
                                                                            ? formBody.extraUrl.split("/")[formBody.extraUrl.split("/").length - 1]
                                                                            : formBody.extra
                                                                                ? formBody.extra.name
                                                                                : ""
                                                                    }
                                                                />
                                                            </UploadFormControl>

                                                            <Tooltip title="Upload extra file" placement="top">
                                                                <UploadAttachment component="label">
                                                                    <UploadFile />
                                                                    <input type="file" ref={extraRef} name="extra" accept="application/pdf" />
                                                                </UploadAttachment>
                                                            </Tooltip>

                                                            {
                                                                formBody.extra && (
                                                                    <Tooltip title="Remove extra file" placement="top">
                                                                        <RemoveAttachment onClick={() => handleRemoveAttachment("extra")}>
                                                                            <Backspace />
                                                                        </RemoveAttachment>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </UploadedFile>
                                                    </UploadContainer>
                                                </Col>
                                            </Row>

                                            <SubmitButton type="submit">Save</SubmitButton>

                                        </UpdateComponentForm>
                                    </>
                                )
            }
        </main >
    )
}

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 0px;
`

const UploadedFile = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const SmallFormTitle = styled(FormTitle)`
    font-size: 17px;
`

const UpdateComponentForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

const Divider = styled.div`
    background-color: var(--macrodyne-grey);
    height: 1px;
    margin: 25px 0px;
`

const StyledAlert = muiStyle(Alert)({
    marginBottom: "10px",
})

const SubmitButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

const UploadFormControl = muiStyle(StyledFormControl)({
    marginBottom: "0px",
})

// Template
const StyledIconButton = muiStyle(IconButton)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    height: "fit-content",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Specific Names
const UploadAttachment = muiStyle(StyledIconButton)({
    "input": {
        display: "none",
    }
})
const RemoveAttachment = muiStyle(StyledIconButton)({})

const StyledOutlinedInput = muiStyle(OutlinedInput)({
    "input.Mui-disabled": {
        "WebkitTextFillColor": "rgba(0, 0, 0, 0.87)"
    }
})

export default UpdateComponent;