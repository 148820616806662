// Core Imports
import axios from "axios";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";

// Components
import LoginNav from "../components/Navigation/LoginNav";
import SubmitSuccess from "../components/Success/SubmitSuccess";
import LoadingMessage from "../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, Alert, InputLabel, OutlinedInput, Button, InputAdornment, IconButton, } from '@mui/material';

// Context
import { UserContext } from "../context/UserContext";

// Utils
import convertToFormData from "../utils/convertToFormData";

// Assets
import loginBackground from "../assets/login-background.webp";

const Login = () => {

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const { user, login } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {

        // If the user is already logged in, don't let them access the login page
        if (user) {
            navigate("/");
        }
    }, [user, navigate])

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset the error with a new submit
        setError("");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }

        const formData = convertToFormData(formBody);

        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formBody.username)) {
            setError("Invalid email address format.");
        }
        else {
            setStatus("sending");
            axios.post("/api/users/login/", formData, config)
                .then(res => {
                    setStatus("success");

                    setTimeout(() => {
                        login(res.data);
                    }, 1500)
                })
                .catch(err => {
                    setStatus("idle")
                    setError("Email or password incorrect!")
                })
        }
    }

    return (
        <Main>
            <LoginNav />

            {
                status === "sending"
                    ? <LoadingMessage message="Logging you in." />
                    : status === "success"
                        ? <SubmitSuccess message="Login successful!" />
                        : (
                            <FormContainer>
                                <LoginForm
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    className="mx-3 mx-md-0 my-5 p-md-5"
                                >

                                    <FormTitle>Real-time Schematics and Monitoring</FormTitle>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        {/* 
                                            Django only accepts the username and password for validation. 
                                            The username was changed to match the email in the DB.
                                            The label shows `email` but the information must be sent under `username` or validation will fail.
                                        */}
                                        <InputLabel htmlFor="username">Email</InputLabel>
                                        <OutlinedInput
                                            id="username"
                                            name="username"
                                            label="Email"
                                            type="email"
                                        />
                                    </FormControl>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <p><StyledLink to="/forgot-password">Forgot Password?</StyledLink></p>

                                    {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                    <LoginButton variant="contained" type="submit">
                                        Login
                                    </LoginButton>

                                </LoginForm>
                            </FormContainer>
                        )}
        </Main>
    )
}

const StyledLink = styled(Link)`
    color: var(--macrodyne-yellow);
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const LoginForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Main = styled.main`
    padding: 0;
    background: url(${loginBackground}) no-repeat center/cover;
    max-width: 100%;
`

const LoginButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

export default Login;