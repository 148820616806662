// Utility function that takes in the body of a form and converts it to FormData API
// This is especially useful for uploading images and documents to the backend
// 
// Cannot be made into a custom hook because it gets called in the form submission event handler
// not the top level of a component

const convertToFormData = (formBody) => {
    const formData = new FormData();
    
    Object.keys(formBody).forEach(key => {

        // File objects need to be checked first
        if(formBody[key] instanceof File) {
            formData.append(key, formBody[key]);
        }
        // Check for objects or arrays
        else if (typeof formBody[key] === "object") {
            formData.append(key, JSON.stringify(formBody[key]));
        }
        // Everything else
        else {
            formData.append(key, formBody[key]);
        }
    })

    return formData;
}

export default convertToFormData;