// Core Imports
import styled from "styled-components";
import { Link } from "react-router-dom";

// MUI
import { Alert } from "@mui/material";
import { styled as muiStyle } from "@mui/material/styles";

const ExpiredUser = () => {
    return (
        <AlertMain>
            <StyledAlert severity="warning">
                Your session has expired! Please <Link to="/login">login</Link> again.
            </StyledAlert>
        </AlertMain>
    )
}

const StyledAlert = muiStyle(Alert)({
    display: "flex", 
    justifyContent: 
    "center", alignItems: 
    "center", 
    fontSize: "22px"
})

const AlertMain = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
`

export default ExpiredUser