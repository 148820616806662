// Core Imports
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import LoadingSpinner from "../components/Loading/LoadingSpinner";

// Custom Hooks
import useRedirectToLogin from "../hooks/useRedirectToLogin";
import useValidateUserCookie from "../hooks/useValidateUserCookie";

// Cookie Handling
import CryptoJS from 'crypto-js';
import Cookies from "universal-cookie";

// The home component will just serve as a redirection "hub" for now
const Home = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();

    const navigate = useNavigate();

    // Declare cookie instance
    const cookies = new Cookies();

    useEffect(() => {

        const storedCookie = cookies.get("user");
        
        if (storedCookie) {
            const decryptedCookie = JSON.parse(CryptoJS.AES.decrypt(storedCookie, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));
            
            if(decryptedCookie.is_macrodyne || decryptedCookie.is_superuser) {
                navigate("/dashboard")
            }
            else {
                navigate(`/client/${decryptedCookie.company}`)
            }
        }
    }, [navigate])

    return (
        <main>
            <LoadingSpinner />
        </main>
    )
}

export default Home;