// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

// Components
import RenderPdf from "../../components/PDF/RenderPdf";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import RedirectUser from "../../components/Errors/RedirectUser";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";
import PdfLoadingBar from "../../components/Loading/PdfLoadingBar";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useConvertExistingPdf from "../../hooks/useConvertExistingPdf";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// MUI
import { ExpandMore } from "@mui/icons-material/";
import { styled as muiStyle } from "@mui/material/styles";
import { Tooltip, Button, Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Prime React
import { Dropdown } from 'primereact/dropdown';

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

// Assets
import osdButtons from "../../constants/osdButtons";

const AnnotateSchematic = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    // Initialize hooks
    const navigate = useNavigate();
    const { clientId, machineId, schematicId } = useParams();

    // Get client name for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Get the schematic and components
    const [components, componentsLoading, componentsError] = useGet(`/api/components/${clientId}/${machineId}/`);
    const [schematic, schematicLoading, schematicError] = useGet(`/api/schematics/${clientId}/${machineId}/${schematicId}/`);

    // Holds the list of annotations of the schematic for the frontend
    const [annotationsList, setAnnotationsList] = useState([]);

    const [status, setStatus] = useState("idle");

    // Holds all the operations on annotations
    // ↪ Will be useful for speeding up processing in the backend
    const [createdAnnotations, setCreatedAnnotations] = useState([]);
    const [updatedAnnotations, setUpdatedAnnotations] = useState([]);
    const [deletedAnnotations, setDeletedAnnotations] = useState([]);

    // Used for creating annotations
    const [selectedComponent, setSelectedComponent] = useState(null);

    // Sets the annotations of the schematic
    useEffect(() => {
        if (schematic) {
            setAnnotationsList(schematic.annotations);
        }

        if (components) {
            setSelectedComponent(components[0]);
        }

    }, [schematic, components])

    // Converts the PDF file into an array of images to be displayed
    const [convertedPdf, convertLoading, currentPage, numPages] = useConvertExistingPdf(schematic);

    // Saves the annotation by sending the information to the server
    const saveChanges = (leave) => {

        if (leave) {
            setStatus("sending");
        }

        const config = {
            headers: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData({ annotationsList, createdAnnotations, updatedAnnotations, deletedAnnotations });

        axios.put(`/api/schematics/${schematic.id}/annotate/`, formData, config)
            .then(res => {
                if (leave) {
                    setStatus("success");

                    setTimeout(() => {
                        navigate(`/client/${clientId}/machine/${machineId}/schematic/${res.data.id}`);
                    }, 1500)
                }
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                (schematicLoading || componentsLoading)
                    ? (
                        <LoadingSpinner />
                    )
                    : (schematicError || componentsError)
                        ? (
                            <RedirectUser error={schematicError || componentsError} />
                        )
                        : status === "sending"
                            ? <LoadingMessage message={"Updating annotations."} />
                            : status === "success"
                                ? <SubmitSuccess message={"Annotations updated successfully!"} />
                                : schematic && (
                                    <>
                                        <TopContainer>
                                            {/* Breadcrumbs */}
                                            <BreadcrumbsNav
                                                links={[
                                                    "dashboard",
                                                    { name: clientName, value: clientId, url: "client" },
                                                    { name: machineName, value: machineId, url: `client/${clientId}/machine` },
                                                    { name: schematic.name, value: schematic.id, url: `client/${clientId}/machine/${machineId}/schematic` },
                                                    "annotation",
                                                ]}
                                            />
                                            <ButtonsContainer className="column-when-small">
                                                {/* Save Button */}
                                                <Tooltip title="Save Changes" placement="top">
                                                    <SaveAnnotationsButton
                                                        variant="contained"
                                                        type="button"
                                                        onClick={() => saveChanges(false)}
                                                    >
                                                        Save & Continue
                                                    </SaveAnnotationsButton>
                                                </Tooltip>
                                                <Tooltip title="Save Changes" placement="top">
                                                    <SaveAnnotationsButton
                                                        variant="contained"
                                                        type="button"
                                                        onClick={() => saveChanges(true)}
                                                    >
                                                        Save & Exit
                                                    </SaveAnnotationsButton>
                                                </Tooltip>
                                            </ButtonsContainer>
                                        </TopContainer>
                                        <Row>
                                            {/* Schematics information */}
                                            <Col sm={12} lg={3}>

                                                {/* Instructions Accordion */}
                                                <SchematicInstructions className="margin-y-when-small">
                                                    <StyledAccordion>
                                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                                            <Title>Instructions</Title>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul>
                                                                <Li><Bold>Zoom</Bold>: to zoom in and out, use the scroll wheel on your mouse or the zoom in and zoom out buttons <img src={osdButtons.zoomin_rest} /> / <img src={osdButtons.zoomout_rest} />.</Li>
                                                                <Li><Bold>Reset Zoom</Bold>: to reset the zoom, click the home button <img src={osdButtons.home_grouphover} />.</Li>
                                                                <Li><Bold>Fullcreen</Bold>: to go into fullscreen mode, click the fullscreen button <img src={osdButtons.fullpage_rest} />. Press the button again, or the <Bold>ESC</Bold> button on your keyboard to exit fullscreen mode.</Li>
                                                                <Li><Bold>Pan</Bold>: to pan the view, click and hold the mouse button in the viewer, then drag your mouse.</Li>
                                                                <Li><Bold>Annotate</Bold>: to create an annotation, select a component from the list below then hold down the <Bold>SHIFT</Bold> key on your keyboard. While holding the key down, click and hold your mouse button and drag the mouse in the viewer. You can resize the annotation by clicking the black dots in the corner, or change it's position by dragging it in the viewer. Once you are satisfied, click anywhere in the viewer to de-select the annotation, which will complete the creation.</Li>
                                                                <Li><Bold>Update (position)</Bold>: to update an annotation's position, click the annotation to select it then click and hold the mouse button in the annotation to drag it to it's new position. Once the annotation is in place, click anywhere in the viewer to de-select the annotation, which will trigger the update.</Li>
                                                                <Li><Bold>Update (component)</Bold>: to update the component in an annotation, you need to delete and re-create it after selecting a new component in the list below.</Li>
                                                                <Li><Bold>Delete</Bold>: to delete an annotation, select an annotation by clicking on it then press the <Bold>DELETE</Bold> key on your keyboard.</Li>
                                                                <Li><Bold>Discard</Bold>: to discard your changes, press the back arrow in your browser, or select any of the paths available in the breadcrumb navigation tool at the top of the page.
                                                                    <ul>
                                                                        <Li>This will only discard the annotations that have not been saved.</Li>
                                                                    </ul>
                                                                </Li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </SchematicInstructions>

                                                {/* Components */}
                                                <Components className="margin-y-when-small">

                                                    <Title>Select a Component</Title>
                                                    {
                                                        components.length > 0
                                                            ? (
                                                                <Dropdown
                                                                    placeholder="Select a Component"
                                                                    options={components}
                                                                    value={selectedComponent}
                                                                    onChange={(event) => setSelectedComponent(event.target.value)}

                                                                    // Necessary for search
                                                                    filter
                                                                    optionLabel="item_number"

                                                                    // Necessary for information display
                                                                    itemTemplate={(val) => <div>{val.item_number}</div>}
                                                                    valueTemplate={(val, props) => <div>{val ? val.item_number : props.placeholder}</div>}
                                                                />
                                                            )
                                                            : (
                                                                <Nothing>
                                                                    <p>This machine has no components yet.</p>
                                                                    <p>To add a new component, click <Link to={`/client/${clientId}/machine/${machineId}/component/add`}>here</Link></p>
                                                                </Nothing>
                                                            )
                                                    }
                                                </Components>

                                            </Col>

                                            {/* PDF Preview */}
                                            <Col sm={12} lg={9}>
                                                {
                                                    convertLoading
                                                        ? (
                                                            <PdfLoadingBar currentPage={currentPage} numPages={numPages} />
                                                        )
                                                        : selectedComponent
                                                            ? convertedPdf && (
                                                                <RenderPdf
                                                                    convertedPdf={convertedPdf}
                                                                    selectedComponent={selectedComponent}

                                                                    annotationsList={annotationsList}
                                                                    setAnnotationsList={setAnnotationsList}

                                                                    createdAnnotations={createdAnnotations}
                                                                    setCreatedAnnotations={setCreatedAnnotations}

                                                                    updatedAnnotations={updatedAnnotations}
                                                                    setUpdatedAnnotations={setUpdatedAnnotations}

                                                                    deletedAnnotations={deletedAnnotations}
                                                                    setDeletedAnnotations={setDeletedAnnotations}
                                                                />
                                                            )
                                                            : (
                                                                <Center>
                                                                    <Alert severity="info">
                                                                        Select a component to begin annotating.
                                                                    </Alert>
                                                                </Center>
                                                            )
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                )
            }
        </main>
    )
}

const Title = styled.h3`
    margin-bottom: 0px;
`

const Nothing = styled.div`
    margin-top: 25px;
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 28%;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const Li = styled.li`
    margin-bottom: 10px;
`

const Bold = styled.span`
    font-weight: bold;
`

// Template
const InformationContainer = styled.div`
    background-color: var(--macrodyne-light-grey);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
`
// Specific names
const SchematicInstructions = styled(InformationContainer)`
    padding: 10px 25px;
`
const Components = styled(InformationContainer)`
    margin-top: 25px;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const StyledAccordion = muiStyle(Accordion)({
    backgroundColor: "var(--macrodyne-light-grey)",
    boxShadow: "none",

    "div": {
        margin: "0",
        padding: "0",
    },

    "img": {
        display: "inline",
    },
})

const SaveAnnotationsButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "15px 50px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
    "@media screen and (max-width: 480px)": {
        padding: "5px 30px",
        "&:first-of-type": {
            marginBottom: "25px",
        }
    },
    "@media screen and (max-width: 1024px)": {
        "&:first-of-type": {
            marginBottom: "25px",
        }
    }
})

export default AnnotateSchematic;