// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

// Components
import BreadcrumbsNav from "../../../components/BreadcrumbNav";
import RedirectUser from "../../../components/Errors/RedirectUser";
import SubmitSuccess from "../../../components/Success/SubmitSuccess";
import LoadingMessage from "../../../components/Loading/LoadingMessage";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Alert,
    Button,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
    FormControl,
    ListSubheader,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';

// Custom Hooks
import useGet from "../../../hooks/useGet";
import useRedirectToLogin from "../../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../../utils/getUserToken";
import convertToFormData from "../../../utils/convertToFormData";
import generateRandomKey from "../../../utils/generateRandomKey";
import isMacrodyneManager from "../../../utils/isMacrodyneManager";

const AdminUpdateUser = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const { userId } = useParams();
    const navigate = useNavigate();

    const { user, login } = useContext(UserContext);

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");
    const [macrodyne, setMacrodyne] = useState(null);
    const [optionClients, setOptionClients] = useState(null);

    // New user is not manager and not macrodyne by default
    const [formBody, setFormBody] = useState({ is_manager: false, is_macrodyne: false });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [clients] = useGet("/api/clients/admin/");
    const [fetchedUser, userLoading, userError] = useGet(`/api/users/get/${userId}/`);

    useEffect(() => {
        if (clients) {
            const adminCompany = clients.find((client) => {
                return client.name.toLowerCase() === "macrodyne";
            })

            if (adminCompany) {
                setMacrodyne(adminCompany);

                const filteredClients = clients.filter((client) => {
                    return client.name.toLowerCase() !== adminCompany.name.toLowerCase();
                })

                setOptionClients(filteredClients);
            }

        }

        if (fetchedUser) {

            setFormBody({
                name: fetchedUser.name,
                first_name: fetchedUser.first_name,
                last_name: fetchedUser.last_name,
                username: fetchedUser.email,
                is_manager: fetchedUser.is_manager,
                company: fetchedUser.company,
                is_macrodyne: fetchedUser.is_macrodyne,
                position: fetchedUser.position,
            })
        }
    }, [clients, fetchedUser])

    const handleChange = (event) => {
        if (event.target.name === "company" && event.target.value === macrodyne.id) {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
                is_macrodyne: true,
            });
        }
        else if (event.target.name === "company" && event.target.value !== macrodyne.id) {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
                is_macrodyne: false,
            });
        }
        else {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        // Quick password check.
        if (formBody.password && formBody.password.length < 8) {
            setError("Password is too short. Please use a minimum of 8 characters.")
        }
        else if (formBody.password && formBody.password !== formBody.confirm_password) {
            setError("Passwords do not match!");
        }
        else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formBody.username)) {
            setError("Invalid email address format.");
        }
        else {
            setStatus("sending");
            
            const formData = convertToFormData(formBody);

            axios.put(`/api/users/update/${userId}/`, formData, config)
                .then(res => {
                    setStatus("success");

                    if (user.id === res.data.id) {
                        login(res.data)
                    }

                    setTimeout(() => {
                        navigate("/users");
                    }, 1500)
                })
                .catch(err => {
                    setStatus("idle");
                    setError(err.response.data.error);

                    // Remove the passwords from the form for safety
                    const formCopy = { ...formBody };
                    delete formCopy.password;
                    delete formCopy.confirm_password;
                    setFormBody({ ...formCopy });
                })
        }
    }

    return (
        <main>
            {
                userLoading
                    ? <LoadingSpinner />
                    : userError
                        ? <RedirectUser error={userError} />
                        : status === "sending"
                            ? <LoadingMessage message={"Updating user information."} />
                            : status === "success"
                                ? <SubmitSuccess message={"User updated successfully!"} />
                                : (
                                    <>
                                        {/* Breadcrumbs */}
                                        <BreadcrumbsNav links={["dashboard", "users", "update user",]} />

                                        <FormContainer>
                                            <AdminUpdateUserForm
                                                onSubmit={handleSubmit}
                                                onChange={handleChange}
                                                className="mx-3 mx-md-0 my-5 p-md-5"
                                            >

                                                <h2>Update user</h2>
                                                <StyledAlert severity="warning">
                                                    Leave the password fields empty if you do not want to update the user's password.
                                                </StyledAlert>

                                                <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    <InputLabel htmlFor="first_name">First Name</InputLabel>
                                                    <OutlinedInput
                                                        id="first_name"
                                                        name="first_name"
                                                        label="First Name"
                                                        type="text"
                                                        value={formBody.first_name ? formBody.first_name : ""}
                                                    />
                                                </FormControl>

                                                <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    <InputLabel htmlFor="last_name">Last Name</InputLabel>
                                                    <OutlinedInput
                                                        id="last_name"
                                                        name="last_name"
                                                        label="Last Name"
                                                        type="text"
                                                        value={formBody.last_name ? formBody.last_name : ""}
                                                    />
                                                </FormControl>

                                                <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    {/* 
                                                Django only accepts the username and password for validation. 
                                                The username was changed to match the email in the DB.
                                                The label shows `email` but the information must be sent under `username` or validation will fail.
                                            */}
                                                    <InputLabel htmlFor="username">Email</InputLabel>
                                                    <OutlinedInput
                                                        id="username"
                                                        name="username"
                                                        label="Email"
                                                        type="email"
                                                        value={formBody.username ? formBody.username : ""}
                                                    />
                                                </FormControl>

                                                <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    <InputLabel htmlFor="position">Position</InputLabel>
                                                    <OutlinedInput
                                                        id="position"
                                                        name="position"
                                                        label="Position"
                                                        type="text"
                                                        value={formBody.position ? formBody.position : ""}
                                                    />
                                                </FormControl>

                                                <FormControl variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    <InputLabel htmlFor="password">Password</InputLabel>
                                                    <OutlinedInput
                                                        id="password"
                                                        name="password"
                                                        label="Password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <InputAdornment position="start">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowPassword((show) => !show)}
                                                                    edge="start"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>

                                                <FormControl variant="outlined" sx={{ marginBottom: "20px" }}>
                                                    <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
                                                    <OutlinedInput
                                                        id="confirm_password"
                                                        name="confirm_password"
                                                        label="Confirm Password"
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <InputAdornment position="start">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                                                    edge="start"
                                                                >
                                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>

                                                <FormControl required variant="outlined" fullWidth>
                                                    <InputLabel htmlFor="company">Client</InputLabel>
                                                    <Select
                                                        name="company"
                                                        id="company"
                                                        label="Client"
                                                        value={formBody.company ? formBody.company : ""}
                                                        onChange={handleChange}
                                                    >

                                                        {/* Not an ideal structure, but it avoids MUI error spam in the console 🤦 */}
                                                        {
                                                            isMacrodyneManager() && (
                                                                macrodyne && <StyledListSubHeader>Admin</StyledListSubHeader>
                                                            )
                                                        }
                                                        {
                                                            isMacrodyneManager() && (
                                                                macrodyne && <MenuItem value={macrodyne.id}>{macrodyne.name}</MenuItem>
                                                            )
                                                        }

                                                        <StyledListSubHeader>Customer</StyledListSubHeader>
                                                        {
                                                            optionClients
                                                                ? optionClients.map(client => {
                                                                    return (
                                                                        <MenuItem key={generateRandomKey()} value={client.id}>{client.name}</MenuItem>
                                                                    )
                                                                })
                                                                : clients && clients.map(client => {
                                                                    return (
                                                                        <MenuItem key={generateRandomKey()} value={client.id}>{client.name}</MenuItem>
                                                                    )
                                                                })
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl required variant="outlined" fullWidth sx={{ margin: "20px 0px" }}>
                                                    <InputLabel htmlFor="is_manager">Permissions</InputLabel>
                                                    <Select
                                                        name="is_manager"
                                                        id="is_manager"
                                                        label="Permissions"
                                                        value={formBody.is_manager}
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={false}>Standard</MenuItem>
                                                        <MenuItem value={true}>Manager</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                                <AdminUpdateUserButton variant="contained" type="submit">
                                                    Update User
                                                </AdminUpdateUserButton>

                                            </AdminUpdateUserForm>
                                        </FormContainer>
                                    </>
                                )
            }
        </main>
    )
}

const AdminUpdateUserForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    border: solid var(--macrodyne-grey) 1px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const AdminUpdateUserButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledListSubHeader = muiStyle(ListSubheader)({
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    backgroundColor: "var(--macrodyne-light-grey)",
})

const StyledAlert = muiStyle(Alert)({
    marginBottom: "25px",
})

export default AdminUpdateUser;