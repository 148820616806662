// Core Imports
import axios from "axios";
import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import LoadingMessage from "../../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import {
    Backspace,
    HideImage,
    VideoCall,
    UploadFile,
    InsertPhoto,
    VideocamOff,
} from '@mui/icons-material';
import {
    Alert,
    Button,
    Select,
    Tooltip,
    MenuItem,
    InputLabel,
    IconButton,
    FormControl,
    OutlinedInput,
} from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useGet from "../../hooks/useGet";
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

const AddComponent = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});

    // The attachment uploads
    const [imageError, setImageError] = useState("");
    const [videoError, setVideoError] = useState("");
    const [extraError, setExtraError] = useState("");

    // Used for accessing the attachment uploads
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const extraRef = useRef(null);

    const navigate = useNavigate();
    const { clientId, machineId } = useParams();

    // Get names for breadcrumb
    const [clientName] = useGet(`/api/clients/name/${clientId}/`);
    const [machineName] = useGet(`/api/machines/name/${machineId}/`);

    // Removes the attachment from the form
    const handleRemoveAttachment = (attachment) => {

        // Remove the current value of the file upload input
        switch (attachment) {
            case "image":
                imageRef.current.value = "";
                break;

            case "video":
                videoRef.current.value = "";
                break;

            case "extra":
                extraRef.current.value = "";
                break;
        }

        // Delete the information from the formBody
        const copiedFormBody = { ...formBody };
        delete copiedFormBody[attachment];
        setFormBody(copiedFormBody)
    }

    // Handles form changes
    const handleChange = (event) => {

        // Check the length of the files in case the user pressed cancel
        if (event.target.type === "file" && event.target.files.length) {
            switch (event.target.name) {
                case "image":
                    // Reset the error on change
                    setImageError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[0] === "image") {
                        setFormBody({
                            ...formBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setImageError("Please upload a valid image file.");
                        handleRemoveAttachment("image");
                    }
                    break;

                case "video":
                    // Reset the error on change
                    setVideoError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[0] === "video"
                        && event.target.files[0].type.split("/")[1] === "mp4"
                    ) {
                        setFormBody({
                            ...formBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setVideoError("Please upload a valid video file.");
                        handleRemoveAttachment("video");
                    }
                    break;

                case "extra":
                    // Reset the error on change
                    setExtraError("");

                    // Check the file
                    if (event.target.files[0].type.split("/")[1] === "pdf") {
                        setFormBody({
                            ...formBody,
                            [event.target.name]: event.target.files[0],
                        });
                    }
                    else {
                        setExtraError("Please upload a valid PDF file.");
                        handleRemoveAttachment("video");
                    }

                    break;
            }
        }
        else if (event.target.type !== "file") {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value,
            });
        }
    }

    // Submits the form
    const handleSubmit = (event) => {
        event.preventDefault();

        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.post(`/api/components/${machineId}/add/`, formData, config)
            .then(res => {
                setStatus("success");

                // Detect if the user wants to leave or stay
                if (event.nativeEvent.submitter.id === "exit") {
                    setTimeout(() => {
                        navigate(`/client/${clientId}/machine/${machineId}`);
                    }, 1500)
                }
                else {
                    setTimeout(() => {
                        setFormBody({});
                        setStatus("idle");
                    }, 1500)
                }

            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                status === "sending"
                    ? <LoadingMessage message={"Adding component."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Component added successfully!"} />
                        : (
                            <>
                                <BreadcrumbsNav links={["dashboard", { name: clientName, value: clientId, url: "client" }, { name: machineName, value: machineId, url: `client/${clientId}/machine` }, "add component"]} />

                                <AddComponentForm onSubmit={handleSubmit} onChange={handleChange}>
                                    <Row>
                                        <FormTitle>Add Component</FormTitle>

                                        {/* Left side of the form */}
                                        <Col sm={12} lg={6}>

                                            {/* Item Number */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="item_number">Item Number</InputLabel>
                                                <OutlinedInput
                                                    id="item_number"
                                                    name="item_number"
                                                    label="Item Number"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Stock Number */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="stock_number">Stock Number</InputLabel>
                                                <OutlinedInput
                                                    id="stock_number"
                                                    name="stock_number"
                                                    label="Stock Number"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Part Name */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="part_name">Part Name</InputLabel>
                                                <OutlinedInput
                                                    id="part_name"
                                                    name="part_name"
                                                    label="Part Name"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Part Number */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="part_number">Part Number</InputLabel>
                                                <OutlinedInput
                                                    id="part_number"
                                                    name="part_number"
                                                    label="Part Number"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Electrical Address */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="electrical_address">Electrical Address</InputLabel>
                                                <OutlinedInput
                                                    id="electrical_address"
                                                    name="electrical_address"
                                                    label="Electrical Address"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Component Application */}
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="application">Component Application</InputLabel>
                                                <OutlinedInput
                                                    id="application"
                                                    name="application"
                                                    label="Component Application"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            {/* Component Type */}
                                            <StyledFormControl required variant="outlined" fullWidth>
                                                <InputLabel htmlFor="type">Type</InputLabel>
                                                <Select
                                                    name="type"
                                                    id="type"
                                                    label="Type"
                                                    onChange={handleChange}
                                                    value={formBody.type ? formBody.type : ""}
                                                >
                                                    <MenuItem value={"Hydraulic"}>Hydraulic</MenuItem>
                                                    <MenuItem value={"Electrical"}>Electrical</MenuItem>
                                                    <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
                                                </Select>
                                            </StyledFormControl>

                                            {/* Description */}
                                            <StyledFormControl variant="outlined">
                                                <InputLabel htmlFor="description">Description</InputLabel>
                                                <OutlinedInput
                                                    id="description"
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    multiline
                                                    rows={5}
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        {/* Right side of the form */}
                                        <Col sm={12} lg={6}>

                                            {/* Safety Notes */}
                                            <SmallFormTitle>Safety Notes</SmallFormTitle>
                                            <StyledFormControl variant="outlined">
                                                <InputLabel htmlFor="notes">Notes</InputLabel>
                                                <OutlinedInput
                                                    id="notes"
                                                    name="notes"
                                                    label="Notes"
                                                    type="text"
                                                    multiline
                                                    rows={8}
                                                />
                                            </StyledFormControl>

                                            <Divider />

                                            {/* Uploads */}
                                            <SmallFormTitle>Attachments</SmallFormTitle>

                                            {/* Image Upload */}
                                            <UploadContainer>
                                                {imageError && <StyledAlert severity="error">{imageError}</StyledAlert>}

                                                <UploadedFile>
                                                    <UploadFormControl variant="outlined" sx={{ width: formBody.image ? "85%" : "90%" }}>
                                                        <InputLabel htmlFor="image">Image</InputLabel>
                                                        <StyledOutlinedInput
                                                            id="image"
                                                            name="image"
                                                            label="Image"
                                                            type="text"
                                                            value={formBody.image ? formBody.image.name : ""}
                                                            disabled
                                                        />
                                                    </UploadFormControl>

                                                    <Tooltip title="Upload image" placement="top">
                                                        <UploadAttachment component="label">
                                                            <InsertPhoto />
                                                            <input type="file" ref={imageRef} name="image" accept="image/*" />
                                                        </UploadAttachment>
                                                    </Tooltip>

                                                    {
                                                        formBody.image && (
                                                            <Tooltip title="Remove image" placement="top">
                                                                <RemoveAttachment onClick={() => handleRemoveAttachment("image")}>
                                                                    <HideImage />
                                                                </RemoveAttachment>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </UploadedFile>
                                            </UploadContainer>

                                            {/* Video Upload */}
                                            <UploadContainer>
                                                {videoError && <StyledAlert severity="error">{videoError}</StyledAlert>}

                                                <UploadedFile>
                                                    <UploadFormControl variant="outlined" sx={{ width: formBody.video ? "85%" : "90%" }}>
                                                        <InputLabel htmlFor="video">Video (mp4)</InputLabel>
                                                        <StyledOutlinedInput
                                                            disabled
                                                            id="video"
                                                            type="text"
                                                            name="video"
                                                            label="Video (mp4)"
                                                            value={formBody.video ? formBody.video.name : ""}
                                                        />
                                                    </UploadFormControl>

                                                    <Tooltip title="Upload video" placement="top">
                                                        <UploadAttachment component="label">
                                                            <VideoCall />
                                                            <input type="file" ref={videoRef} name="video" accept="video/*" />
                                                        </UploadAttachment>
                                                    </Tooltip>

                                                    {
                                                        formBody.video && (
                                                            <Tooltip title="Remove video" placement="top">
                                                                <RemoveAttachment onClick={() => handleRemoveAttachment("video")}>
                                                                    <VideocamOff />
                                                                </RemoveAttachment>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </UploadedFile>
                                            </UploadContainer>

                                            {/* Extra Upload */}
                                            <UploadContainer>
                                                {extraError && <StyledAlert severity="error">{extraError}</StyledAlert>}

                                                <UploadedFile>
                                                    <UploadFormControl variant="outlined" sx={{ width: formBody.extra ? "85%" : "90%" }}>
                                                        <InputLabel htmlFor="extra">Extra File (PDF)</InputLabel>
                                                        <StyledOutlinedInput
                                                            id="extra"
                                                            name="extra"
                                                            label="Extra File (PDF)"
                                                            type="text"
                                                            value={formBody.extra ? formBody.extra.name : ""}
                                                            disabled
                                                        />
                                                    </UploadFormControl>

                                                    <Tooltip title="Upload extra file" placement="top">
                                                        <UploadAttachment component="label">
                                                            <UploadFile />
                                                            <input type="file" ref={extraRef} name="extra" accept="application/pdf" />
                                                        </UploadAttachment>
                                                    </Tooltip>

                                                    {
                                                        formBody.extra && (
                                                            <Tooltip title="Remove extra file" placement="top">
                                                                <RemoveAttachment onClick={() => handleRemoveAttachment("extra")}>
                                                                    <Backspace />
                                                                </RemoveAttachment>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </UploadedFile>
                                            </UploadContainer>
                                        </Col>
                                    </Row>

                                    <SaveExitButton type="submit" id="exit">Save & Exit</SaveExitButton>

                                    <SaveContinueButton type="submit" id="stay">Save & Add New Component</SaveContinueButton>

                                </AddComponentForm>
                            </>
                        )
            }
        </main >
    )
}

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 0px;
`

const UploadedFile = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const SmallFormTitle = styled(FormTitle)`
    font-size: 17px;
`

const AddComponentForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

const Divider = styled.div`
    background-color: var(--macrodyne-grey);
    height: 1px;
    margin: 25px 0px;
`

const StyledAlert = muiStyle(Alert)({
    marginBottom: "10px",
})

// Template
const SubmitButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

// Specific Names
const SaveExitButton = muiStyle(SubmitButton)({
    right: "350px"
});
const SaveContinueButton = muiStyle(SubmitButton)({});

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

const UploadFormControl = muiStyle(StyledFormControl)({
    marginBottom: "0px",
})

// Template
const StyledIconButton = muiStyle(IconButton)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    borderRadius: "5px",
    width: "fit-content",
    height: "fit-content",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

// Specific Names
const UploadAttachment = muiStyle(StyledIconButton)({
    "input": {
        display: "none",
    }
})
const RemoveAttachment = muiStyle(StyledIconButton)({})

const StyledOutlinedInput = muiStyle(OutlinedInput)({
    "input.Mui-disabled": {
        "WebkitTextFillColor": "rgba(0, 0, 0, 0.87)"
    }
})

export default AddComponent;