// Cookie Handling
import CryptoJS from 'crypto-js';
import Cookies from "universal-cookie";

// Utility function that hides or shows admin features on the page.
// ↪ Not made into a hook so it can't be stored into state and modified.
const showAdminFeatures = (manager = false) => {

    const permissions = manager ? ["is_macrodyne", "is_superuser", "is_manager"] : ["is_macrodyne", "is_superuser"];

    // Declare cookie instance
    const cookies = new Cookies();

    const storedCookie = cookies.get("user");

    if (storedCookie) {

        const decryptedCookie = JSON.parse(CryptoJS.AES.decrypt(storedCookie, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));

        // Superusers have access to the whole website
        const hasPermission = permissions.some((permission) => {
            return decryptedCookie[permission];
        })

        return hasPermission;
    }
}

export default showAdminFeatures