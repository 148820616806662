// Core Imports
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import BreadcrumbsNav from "../../components/BreadcrumbNav";
import SubmitSuccess from "../../components/Success/SubmitSuccess";
import LoadingMessage from "../../components/Loading/LoadingMessage";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { FormControl, InputLabel, OutlinedInput, Button, } from '@mui/material';

// Bootstrap
import { Row, Col } from "react-bootstrap";

// Custom Hooks
import useRedirectToLogin from "../../hooks/useRedirectToLogin";
import useValidateUserCookie from "../../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../../hooks/useVerifyUserPermissions";

// Utils
import getUserToken from "../../utils/getUserToken";
import convertToFormData from "../../utils/convertToFormData";

const AddClient = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({
        macrodyne_contact_name: "Macrodyne",
        macrodyne_title: "Service Department",
        macrodyne_email: "service@macrodynepress.com",
        macrodyne_ind: "905",
        macrodyne_phone: "669-2253",
        macrodyne_ext: "2"
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormBody({
            ...formBody,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setStatus("sending");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
            }
        }

        const formData = convertToFormData(formBody);

        axios.post("/api/clients/add/", formData, config)
            .then(res => {
                setStatus("success");

                setTimeout(() => {
                    navigate(`/client/${res.data.id}`);
                }, 1500)
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    return (
        <main>
            {
                status === "sending"
                    ? <LoadingMessage message={"Adding new client"} />
                    : status === "success"
                        ? <SubmitSuccess message={"Client added succesfully!"} />
                        : (
                            <>
                                <BreadcrumbsNav links={["dashboard", "add client"]} />

                                <AddClientForm onSubmit={handleSubmit} onChange={handleChange}>
                                    <FormTitle>Add Client</FormTitle>

                                    {/* Name */}
                                    <StyledFormControl required variant="outlined">
                                        <InputLabel htmlFor="client_name">Client Name</InputLabel>
                                        <OutlinedInput
                                            id="client_name"
                                            name="client_name"
                                            label="Client Name"
                                            type="text"
                                        />
                                    </StyledFormControl>

                                    {/* Address */}
                                    <StyledFormControl required variant="outlined">
                                        <InputLabel htmlFor="address">Address</InputLabel>
                                        <OutlinedInput
                                            id="address"
                                            name="address"
                                            label="Address"
                                            type="text"
                                        />
                                    </StyledFormControl>

                                    {/* Contains and styles the smaller inputs */}
                                    <Row>

                                        {/* City */}
                                        <Col sm={12} md={6} lg={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="city">City</InputLabel>
                                                <OutlinedInput
                                                    id="city"
                                                    name="city"
                                                    label="City"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        {/* Province */}
                                        <Col sm={12} md={6} lg={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="province">Province / State</InputLabel>
                                                <OutlinedInput
                                                    id="province"
                                                    name="province"
                                                    label="Province / State"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        {/* Postal code */}
                                        <Col sm={12} md={6} lg={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="postal_code">Postal Code / ZIP</InputLabel>
                                                <OutlinedInput
                                                    id="postal_code"
                                                    name="postal_code"
                                                    label="Postal Code / ZIP"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>

                                        {/* Country */}
                                        <Col sm={12} md={6} lg={3}>
                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="country">Country</InputLabel>
                                                <OutlinedInput
                                                    id="country"
                                                    name="country"
                                                    label="Country"
                                                    type="text"
                                                />
                                            </StyledFormControl>
                                        </Col>
                                    </Row>

                                    {/* End of company information section */}
                                    <Divider />

                                    {/* Beginning of contact information section */}
                                    <Row>
                                        {/* Company contact */}
                                        <Col sm={12} lg={6}>
                                            <SmallFormTitle>Company Contact</SmallFormTitle>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="contact_name">Contact Name</InputLabel>
                                                <OutlinedInput
                                                    id="contact_name"
                                                    name="contact_name"
                                                    label="Contact Name"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="title">Title</InputLabel>
                                                <OutlinedInput
                                                    id="title"
                                                    name="title"
                                                    label="Title"
                                                    type="text"
                                                />
                                            </StyledFormControl>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                <OutlinedInput
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                />
                                            </StyledFormControl>

                                            {/* Groups and styles the phone number related inputs */}
                                            <Row>
                                                <Col sm={12} md={2}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="ind">Ind</InputLabel>
                                                        <OutlinedInput
                                                            id="ind"
                                                            name="ind"
                                                            label="Ind"
                                                            type="text"
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="phone">Phone</InputLabel>
                                                        <OutlinedInput
                                                            id="phone"
                                                            name="phone"
                                                            label="Phone"
                                                            type="text"
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={4}>
                                                    <StyledFormControl variant="outlined">
                                                        <InputLabel htmlFor="ext">Ext</InputLabel>
                                                        <OutlinedInput
                                                            id="ext"
                                                            name="ext"
                                                            label="Ext"
                                                            type="text"
                                                        />
                                                    </StyledFormControl>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {/* Macrodyne contact */}
                                        <Col sm={12} lg={6}>
                                            <SmallFormTitle>Macrodyne Contact</SmallFormTitle>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="macrodyne_contact_name">Contact Name</InputLabel>
                                                <OutlinedInput
                                                    id="macrodyne_contact_name"
                                                    name="macrodyne_contact_name"
                                                    label="Contact Name"
                                                    type="text"
                                                    value={formBody.macrodyne_contact_name}
                                                />
                                            </StyledFormControl>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="macrodyne_title">Title</InputLabel>
                                                <OutlinedInput
                                                    id="macrodyne_title"
                                                    name="macrodyne_title"
                                                    label="Title"
                                                    type="text"
                                                    value={formBody.macrodyne_title}
                                                />
                                            </StyledFormControl>

                                            <StyledFormControl required variant="outlined">
                                                <InputLabel htmlFor="macrodyne_email">Email</InputLabel>
                                                <OutlinedInput
                                                    id="macrodyne_email"
                                                    name="macrodyne_email"
                                                    label="Email"
                                                    type="email"
                                                    value={formBody.macrodyne_email}
                                                />
                                            </StyledFormControl>

                                            {/* Groups and styles the phone number related inputs */}
                                            <Row>
                                                <Col sm={12} md={2}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_ind">Ind</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_ind"
                                                            name="macrodyne_ind"
                                                            label="Ind"
                                                            type="text"
                                                            value={formBody.macrodyne_ind}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <StyledFormControl required variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_phone">Phone</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_phone"
                                                            name="macrodyne_phone"
                                                            label="Phone"
                                                            type="text"
                                                            value={formBody.macrodyne_phone}
                                                        />
                                                    </StyledFormControl>
                                                </Col>

                                                <Col sm={12} md={4}>
                                                    <StyledFormControl variant="outlined">
                                                        <InputLabel htmlFor="macrodyne_ext">Ext</InputLabel>
                                                        <OutlinedInput
                                                            id="macrodyne_ext"
                                                            name="macrodyne_ext"
                                                            label="Ext"
                                                            type="text"
                                                            value={formBody.macrodyne_ext}
                                                        />
                                                    </StyledFormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {/* End of contact information section */}
                                    <Divider />

                                    <Row>
                                        {/* Client Notes */}
                                        <Col sm={12}>
                                            <SmallFormTitle>Notes</SmallFormTitle>
                                            <StyledFormControl variant="outlined">
                                                <InputLabel htmlFor="notes">Notes</InputLabel>
                                                <OutlinedInput
                                                    id="notes"
                                                    name="notes"
                                                    label="Notes"
                                                    type="text"
                                                    multiline
                                                    rows={11}
                                                />
                                            </StyledFormControl>
                                        </Col>
                                    </Row>

                                    <SubmitButton type="submit">Save</SubmitButton>
                                    {/* <CancelButton type="button">Cancel</CancelButton> */}

                                </AddClientForm>
                            </>
                        )
            }
        </main>
    )
}

const Divider = styled.div`
    background-color: var(--macrodyne-grey);
    height: 1px;
    margin: 25px 0px;
`

const FormTitle = styled.p`
    font-size: 20px;
    color: var(--macrodyne-dark-blue);
    font-weight: bold;
    margin-bottom: 25px;
`

const SmallFormTitle = styled(FormTitle)`
    font-size: 17px;
`

const AddClientForm = styled.form`
    position: relative;
    top: 0;
    left: 0;
    padding: 25px;
`

// Template
const StyledButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    padding: "1rem 3rem",
    textTransform: "none",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    right: "25px",

    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    },
})

// Specific Names
const SubmitButton = muiStyle(StyledButton)({
    right: "25px",
})
const CancelButton = muiStyle(StyledButton)({
    right: "250px",
})

const StyledFormControl = muiStyle(FormControl)({
    marginBottom: "20px",
    backgroundColor: "var(--macrodyne-light-grey)",
    width: "100%",
})

export default AddClient;