// Core Imports
import styled from "styled-components";

// Components
import Error from "../components/Errors/Error";
import BreadcrumbsNav from "../components/BreadcrumbNav";
import LoadingSpinner from "../components/Loading/LoadingSpinner";

// Prime React
import { TabView, TabPanel } from 'primereact/tabview';

// Custom Hooks
import useRedirectToLogin from "../hooks/useRedirectToLogin";
import useValidateUserCookie from "../hooks/useValidateUserCookie";
import useVerifyUserPermissions from "../hooks/useVerifyUserPermissions";

// Pages
import ListClients from "./Client/ListClients";

const Dashboard = () => {

    // Verify user and cookie
    useRedirectToLogin();
    useValidateUserCookie();
    useVerifyUserPermissions();

    return (
        <Main>
            <>
                <TopContainer>
                    <BreadcrumbsNav links={["dashboard"]} />
                </TopContainer>
                <TabView>
                    <TabPanel header="Clients">
                        <ListClients />
                    </TabPanel>
                    {/* <TabPanel header="Machine Status">
                        <div>Machines not implemented yet</div>
                    </TabPanel>
                    <TabPanel header="Part Order Requests">
                        <div>Parts not implemented yet</div>
                    </TabPanel> */}
                </TabView>
            </>
        </Main >
    )
}

const Main = styled.main`
    position: relative;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

export default Dashboard