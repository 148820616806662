// Core Imports
import { pdfjs } from 'react-pdf';
import { useState, useEffect } from "react";

// Load the pdfjs library
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Custom hook that takes an existing PDF file and converts it into an array of images
// to be displayed in the OSD + Annotirious viewers
const useConvertExistingPdf = (obj) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(0);

    // Converts the pdf into an array of images
    const convertPdf = async (data) => {
        const imagesList = [];

        const canvas = document.createElement("canvas");

        const pdf = await pdfjs.getDocument({ data }).promise;

        setNumPages(pdf.numPages);

        for (let i = 1; i <= pdf.numPages; i++) {

            setCurrentPage(i);

            const page = await pdf.getPage(i);

            // controls the size of the image, allows for OSD to zoom in more + keeps the quality
            const viewport = page.getViewport({ scale: 3.0 });

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport,
            };

            await page.render(render_context).promise;

            const img = canvas.toDataURL("image/png");
            imagesList.push({ url: img, type: "image" });
        }

        setImageUrl(imagesList);
        setLoading(false);
    };

    // Converts the file into a blob object that can be ready by the PDF loader
    const urlUploader = (url) => {

        fetch(url).then((response) => {

            response.blob()
                .then((blob) => {
                    let reader = new FileReader();

                    reader.onload = (e) => {
                        const data = atob(e.target.result.replace(/.*base64,/, ""));
                        convertPdf(data);
                    };

                    reader.readAsDataURL(blob);
                });
        });
    };

    useEffect(() => {
        // Make sure the object containing the file has fully loaded (is fetched) and that the file exists

        // Check if the file hasn't been converted before
        if (obj && obj.file && !imageUrl) {
            setLoading(true);
            urlUploader(obj.file);
        }
        // Check if the file was removed (if it's been converted before, it's now been removed)
        else if (obj && !obj.file && imageUrl) {
            setImageUrl(null);
            setLoading(false);
        }
        // If an update is made to the obj, don't re-convert the file
        else if (imageUrl) {
            // do nothing
        }
        // If all else fails
        else {
            setImageUrl(null);
            setLoading(false);
        }

    }, [obj]);

    return [imageUrl, loading, currentPage, numPages];
}

export default useConvertExistingPdf;