// Core Imports
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";

// Utils
import showAdminFeatures from "../../utils/showAdminFeatures";

// MUI
import { Menu, Avatar, MenuItem, Tooltip, IconButton, ListItemIcon } from "@mui/material";
import { Person, PeopleAlt, LogoutOutlined, LoginOutlined, MenuBook } from '@mui/icons-material';

// Context
import { UserContext } from "../../context/UserContext";

// Assets
import logo from "../../assets/logo-final.png";
import userManual from "../../assets/User Manual.pdf";
import macrodyneManual from "../../assets/Macrodyne Manual.pdf";
import whiteNavInverted from "../../assets/white-nav-inverted.webp";

const Nav = () => {

    const { user, logout } = useContext(UserContext);

    // State for the dropdown menu
    const [anchorEl, setAnchorEl] = useState(null);

    // Handles weather the dropdown should be open or not
    const open = Boolean(anchorEl);

    // Opens the dropdown
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Closes the dropdown
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <header>
            <Navbar>
                <LeftNavContainer>
                    {/* Logo */}
                    <Link to="/">
                        <img src={whiteNavInverted} alt="Logo background" />
                        <Logo src={logo} alt="Macrodyne Logo" />
                    </Link>

                    {/* Website name */}
                    <span className="hide-when-small">Real-time Schematics and Monitoring</span>
                </LeftNavContainer>

                {/* Welcome message + avater + dropdown */}
                {!user
                    ? (
                        <StyledLink to="/login">
                            <Span className="mx-5">
                                <LoginOutlined />
                                Login
                            </Span>
                        </StyledLink>
                    )
                    : (
                        <>
                            {/* Tooltip popup */}
                            <AvatarContainer>
                                <span className="hide-when-small">Welcome {user.name.split(" ")[0]}!</span>
                                {/* Makes the avatar into a button */}
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar sx={{ bgcolor: "var(--macrodyne-yellow)" }} />
                                    </IconButton>
                                </Tooltip>
                            </AvatarContainer>

                            {/* Dropdown */}
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                onMouseLeave={handleClose}
                            >

                                {/* Profile link */}
                                <MenuItem sx={{ color: "black" }}>
                                    <StyledLink to="/profile">
                                        <CenterDropdownItems>
                                            <ListItemIcon sx={{ color: "black" }}>
                                                <Person />
                                            </ListItemIcon>
                                            Profile
                                        </CenterDropdownItems>
                                    </StyledLink>
                                </MenuItem>

                                {/* User Manual */}
                                {
                                    showAdminFeatures()
                                        ? (
                                            <div>
                                                <MenuItem>
                                                    <StyledAnchor href={userManual} target="_blank">
                                                        <CenterDropdownItems>
                                                            <ListItemIcon sx={{ color: "black" }}>
                                                                <MenuBook />
                                                            </ListItemIcon>
                                                            Client User Manual
                                                        </CenterDropdownItems>
                                                    </StyledAnchor>
                                                </MenuItem>
                                                <MenuItem>
                                                    <StyledAnchor href={macrodyneManual} target="_blank">
                                                        <CenterDropdownItems>
                                                            <ListItemIcon sx={{ color: "black" }}>
                                                                <MenuBook />
                                                            </ListItemIcon>
                                                            Macrodyne User Manual
                                                        </CenterDropdownItems>
                                                    </StyledAnchor>
                                                </MenuItem>
                                            </div>
                                        )
                                        : (
                                            <MenuItem>
                                                <StyledAnchor href={userManual} target="_blank">
                                                    <CenterDropdownItems>
                                                        <ListItemIcon sx={{ color: "black" }}>
                                                            <MenuBook />
                                                        </ListItemIcon>
                                                        Client User Manual
                                                    </CenterDropdownItems>
                                                </StyledAnchor>
                                            </MenuItem>
                                        )
                                }

                                {/* Macrodyne users dashboard */}
                                {showAdminFeatures() && (
                                    <MenuItem sx={{ color: "black" }}>
                                        <StyledLink to="/users">
                                            <CenterDropdownItems>
                                                <ListItemIcon sx={{ color: "black" }}>
                                                    <PeopleAlt />
                                                </ListItemIcon>
                                                All users
                                            </CenterDropdownItems>
                                        </StyledLink>
                                    </MenuItem>
                                )}

                                {/* Logout */}
                                <MenuItem sx={{ color: "black" }} onClick={logout}>
                                    <ListItemIcon sx={{ color: "black" }}>
                                        <LogoutOutlined />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            </Navbar>
        </header>
    )
}

const StyledAnchor = styled.a`
    color: black;
    text-decoration: none;
`

const LeftNavContainer = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -1px;
    margin-left: -1px;
`

const Logo = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 85%;
    max-width: 200px;
    height: auto;
    margin: 3rem 1.5rem;
`

const Span = styled.span`
    font-size: 20px;
    display: flex;
    align-items: center;
    color: white;
`

const AvatarContainer = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    color: white;
    margin-right: 1.5em;

    span {
        margin-right: 25px;
    }
`

const CenterDropdownItems = styled.div`
    display: flex;
    justify-content: center;
`

const StyledLink = styled(Link)`
    color: black;
    text-decoration: none;
`

const Navbar = styled.nav`
    background-color: var(--macrodyne-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default Nav;