// Core Imports
import axios from "axios";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";
import { useState, useContext, useEffect } from "react";

// Components
import BreadcrumbsNav from "../components/BreadcrumbNav";
import SubmitSuccess from "../components/Success/SubmitSuccess";
import LoadingMessage from "../components/Loading/LoadingMessage";

// Custom Hooks
import useRedirectToLogin from "../hooks/useRedirectToLogin";
import useValidateUserCookie from "../hooks/useValidateUserCookie";

// MUI
import { styled as muiStyle } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, Alert, InputLabel, OutlinedInput, Button, InputAdornment, IconButton, } from '@mui/material';

// Utils
import convertToFormData from "../utils/convertToFormData";
import getUserToken from "../utils/getUserToken";

const Profile = () => {

    useRedirectToLogin();
    useValidateUserCookie();

    const [error, setError] = useState("");
    const [status, setStatus] = useState("idle");
    const [formBody, setFormBody] = useState({});

    // Show password states
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const { user, login } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            setFormBody({
                name: user.name,
                first_name: user.first_name,
                last_name: user.last_name,
                username: user.email,
                position: user.position,
            })
        }
    }, [user])

    const handleChange = (event) => {

        if(event.target.name === "current_password" && event.target.value.length === 0) {
            const copy = {...formBody};

            delete copy.password;
            delete copy.confirm_password;
            delete copy.current_password;

            setFormBody({
                ...copy
            })
        }
        else {
            setFormBody({
                ...formBody,
                [event.target.name]: event.target.value
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset the error with a new submit
        setError("");

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `JWT ${getUserToken()}`,
            }
        }

        // Quick password check.
        if (formBody.current_password && formBody.password.length < 8) {
            setError("Password is too short. Please use a minimum of 8 characters.")
        }
        else if (formBody.password !== formBody.confirm_password) {
            setError("Passwords do not match!");
        }
        else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formBody.username)) {
            setError("Invalid email address format.");
        }
        else {
            setStatus("sending");
            
            const formData = convertToFormData(formBody);

            axios.put("/api/users/self/update/", formData, config)
                .then(res => {
                    login(res.data)
                    setStatus("success");

                    setTimeout(() => {
                        setStatus("idle");

                    }, 2000)
                })
                .catch(err => {
                    setStatus("idle");
                    setError(err.response.data.error);

                    // Remove the passwords from the form for safety
                    const formCopy = { ...formBody };
                    delete formCopy.password;
                    delete formCopy.confirm_password;
                    setFormBody({ ...formCopy });
                })
        }
    }

    return (
        <main>
            {user && <BreadcrumbsNav links={[user.name]} />}

            {
                status === "sending"
                    ? <LoadingMessage message={"Updating profile information."} />
                    : status === "success"
                        ? <SubmitSuccess message={"Request received. Your profile has been updated."} />
                        : (
                            <FormContainer>
                                <UpdateProfileForm
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    className="mx-3 mx-md-0 my-5 p-md-5"
                                >

                                    <h2>Update your information</h2>
                                    <StyledAlert severity="warning">
                                        Leave the password fields empty if you do not want to update your password.
                                    </StyledAlert>


                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="first_name">First Name</InputLabel>
                                        <OutlinedInput
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            type="text"
                                            value={formBody.first_name ? formBody.first_name : ""}
                                        />
                                    </FormControl>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="last_name">Last Name</InputLabel>
                                        <OutlinedInput
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            type="text"
                                            value={formBody.last_name ? formBody.last_name : ""}
                                        />
                                    </FormControl>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        {/* 
                                                        Django only accepts the username and password for validation. 
                                                        The username was changed to match the email in the DB.
                                                        The label shows `email` but the information must be sent under `username` or validation will fail.
                                                    */}
                                        <InputLabel htmlFor="username">Email</InputLabel>
                                        <OutlinedInput
                                            id="username"
                                            name="username"
                                            label="Email"
                                            type="email"
                                            value={formBody.username ? formBody.username : ""}
                                        />
                                    </FormControl>

                                    <FormControl required variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="position">Position</InputLabel>
                                        <OutlinedInput
                                            id="position"
                                            name="position"
                                            label="Position"
                                            type="text"
                                            value={formBody.position ? formBody.position : ""}
                                        />
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{ marginBottom: "20px" }}>
                                        <InputLabel htmlFor="current_password">Current Password</InputLabel>
                                        <OutlinedInput
                                            id="current_password"
                                            name="current_password"
                                            label="Current Password"
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            value={formBody.current_password ? formBody.current_password : ""}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowCurrentPassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControl
                                        variant="outlined"
                                        sx={{ marginBottom: "20px" }}
                                        required={formBody.current_password && formBody.current_password.length > 0}
                                    >
                                        <InputLabel htmlFor="password">New Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            name="password"
                                            label="New Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formBody.password ? formBody.password : ""}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControl
                                        variant="outlined"
                                        sx={{ marginBottom: "20px" }}
                                        required={formBody.current_password && formBody.current_password.length > 0}
                                    >
                                        <InputLabel htmlFor="confirm_password">Confirm New Password</InputLabel>
                                        <OutlinedInput
                                            id="confirm_password"
                                            name="confirm_password"
                                            label="Confirm New Password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={formBody.confirm_password ? formBody.confirm_password : ""}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowConfirmPassword((show) => !show)}
                                                        edge="start"
                                                    >
                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    {error && <Alert sx={{ marginBottom: "25px" }} severity="error">{error}</Alert>}

                                    <UpdateProfileButton variant="contained" type="submit">
                                        Update Information
                                    </UpdateProfileButton>

                                </UpdateProfileForm>
                            </FormContainer>
                        )}
        </main>
    )
}

const UpdateProfileForm = styled.form`
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    border: solid var(--macrodyne-dark-blue) 5px;
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const UpdateProfileButton = muiStyle(Button)({
    backgroundColor: "var(--macrodyne-yellow)",
    color: "black",
    margin: "0 auto",
    padding: "15px 85px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
        color: "white",
        backgroundColor: "var(--macrodyne-dark-blue)",
    }
})

const StyledAlert = muiStyle(Alert)({
    marginBottom: "25px",
})

export default Profile;